import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import Sidebar from '../../components/sidebar/Sidebar';
import UserOverview from './user-overview/UserOverview';
import './Users.scss'

const Users = ({ history, match }) => {
	const menu = [
		{
			id: 'user-management',
			label: 'User Management',
			icon: '',
			nested: [{
				id: `${match.url}--user-overview`,
				label: 'User Overview',
				href: `${match.url}/user-overview`,
				icon: '',
			}]
		},
	]

	useEffect(() => {
		history.push('/users/user-overview');
	}, []);

	return (
		<div className="page-wrapper users-page">
			<Sidebar menu={menu} initialOpen={'user-management'} />
			<div className="page-content custom-scroll">
				<Switch>
					<Route exact path={`${match.path}/user-overview`} component={UserOverview} />
				</Switch>
			</div>
		</div>
	);
}

export default Users;