export const setuptListRedcuer = (state = { tickets: [] }, action) => {
  switch (action.type) {
    case "SETUP_LIST_REQUEST":
      return { loading: true, tickets: [] };
    case "SETUP_LIST_SUCCESS":
      return { loading: false, success: true, ticketsSetup: action.payload };
    case "SETUP_LIST_FAIL":
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const setupUpdateRedcuer = (state = {}, action) => {
  switch (action.type) {
    case "SETUP_UPDATE_REQUEST":
      return { updateLoading: true, tickets: {} };
    case "SETUP_UPDATE_SUCCESS":
      return { updateLoading: false, updateSuccess: true, ticketSetupInfo: action.payload };
    case "SETUP_UPDATE_FAIL":
      return { updateLoading: false, error: action.payload };
    default:
      return state;
  }
};

export const setupDeleteReducer = (state = { Ticket: {} }, action) => {
  switch (action.type) {
    case "SETUP_DELETE_REQUEST":
      return { DeleteLoading: true, ticket: {} };
    case "SETUP_DELETE_SUCCESS":
      return { DeleteLoading: false, deleteSuccess: true, ticketInfo: action.payload };
    case "SETUP_DELETE_FAIL":
      return { DeleteLoading: false, error: action.payload };
    default:
      return state;
  }
};

export const setupCreateReducer = (state=[], action) => {
  switch (action.type) {
      case 'SETUP_CREATE_REQUEST': 
      return {
        ...state,
        createLoading: true,
        createSuccess: false
      };
      case "SETUP_CREATE_SUCCESS":
        return { 
          ...state,
          createLoading: false,
          createSuccess: true,
          setupInfo: action.payload };
      case "SETUP_CREATE_FAIL":
        return { 
          ...state,
          createLoading: false,
          createError: action.payload
        };
      default: 
          return state
  }
}
