import axios from "axios";

const IS_UAT = window.location.href.includes('-uat');
const IS_SAT = window.location.href.includes('localhost') || window.location.href.includes('-sat');

const DAPPX_ADMIN_API = `${IS_SAT ? "https://dappx-adminapi-sat.dappstore.me/" : (IS_UAT ? "https://dappx-adminapi-uat.dappstore.me/" : "https://dappx-adminapi.dappstore.me/")}`;

const adminToken = localStorage.getItem("token");

export const getAcePledgeSeasons = () => {
  return async (dispatch) => {
    dispatch({ type: "PLEDGE_SEASON_REQUEST" });
    
    const res = await axios.get(`${DAPPX_ADMIN_API}pledge-seasons?limit=99`, {
      headers: {
        'auth-token': adminToken,
      },
    })

    if (res.error) {
      dispatch({
        type: "PLEDGE_SEASON_FAIL",
        payload: res.data,
      });
    } else {
      dispatch({
        type: "PLEDGE_SEASON_SUCCESS",
        payload: res.data.seasons,
      });
    }
  };
};

export const addAcePledgeSeason = (data) => {
  return async (dispatch) => {
    dispatch({ type: "ADD_SEASON_REQUEST" });

    const res = await axios.post(`${DAPPX_ADMIN_API}/pledge-seasons`, data[0], { headers: { 'auth-token': adminToken } })
    if (res.error) {
      dispatch({
        type: "ADD_SEASON_FAIL",
        payload: res.data,
      });
    } else {
      dispatch({
        type: "ADD_SEASON_SUCCESS",
        payload: res.data,
      });
    }
  };
};

export const updateAcePledgeSeason = (data, id) => {
  return async (dispatch) => {
    dispatch({ type: "UPDATE_SEASON_REQUEST" });

    const res = await axios.put(`${DAPPX_ADMIN_API}pledge-seasons/${id}`, data[0], { headers: { 'auth-token': adminToken } })
    if (res.error) {
      dispatch({
        type: "UPDATE_SEASON_FAIL",
        payload: res.data,
      });
    } else {
      dispatch({
        type: "UPDATE_SEASON_SUCCESS",
        payload: res.data,
      });
    }
  };
};

export const deletePledgeSeason = (id) => {
  return async (dispatch) => {
    dispatch({ type: "DELETE_SEASON_REQUEST" });

    const res = await axios.delete(
      `${DAPPX_ADMIN_API}pledge-seasons/${id}`,
      {
        headers: {
          'auth-token': adminToken
        }
      }
    )

    if (res.error) {
      dispatch({
        type: "DELETE_SEASON_FAIL",
        payload: res.data,
      });
    } else {
      dispatch({
        type: "DELETE_SEASON_SUCCESS",
        payload: res.data,
      });
    }
  };
};