import Api from "../api";
import axios from "axios";
import { DAPPX_API_BASE_URL } from "../constant/config";

export const listTickets = () => {
  return async (dispatch) => {
    dispatch({ type: "TICKET_LIST_REQUEST" });
    const res = await Api.Tickets.getTickets();
    if (res.error) {
      dispatch({
        type: "TICKET_LIST_FAIL",
        payload: res.data,
      });
    } else {
      dispatch({
        type: "TICKET_LIST_SUCCESS",
        payload: res.data.rules,
      });
    }
  };
};

export const listRewardsStatus = () => {
  return async (dispatch) => {
    dispatch({ type: "REWARDS_STATUS_REQUEST" });
    const res = await Api.Tickets.getRewardsStatus();
    if (res.error) {
      dispatch({
        type: "REWARDS_STATUS_FAIL",
        payload: res.data,
      });
    } else {
      dispatch({
        type: "REWARDS_STATUS_SUCCESS",
        payload: res.data,
      });
    }
  };
};

export const updateRewards = (data) => {
  return async (dispatch) => {
    dispatch({ type: "REWARDS_UPDATE_REQUEST" });

    const res = await Api.Tickets.updateRewardsStatus(data);
    if (res.error) {
      dispatch({
        type: "REWARDS_UPDATE_FAIL",
        payload: res.data,
      });
    } else {
      dispatch({
        type: "REWARDS_UPDATE_SUCCESS",
        payload: res.data,
      });
    }
  };
};

export const updateTicket = (data, id) => {
  return async (dispatch) => {
    dispatch({ type: "TICKET_UPDATE_REQUEST" });

    const res = await Api.Tickets.updateTicket(data, id);
    if (res.error) {
      dispatch({
        type: "TICKET_UPDATE_FAIL",
        payload: res.data,
      });
    } else {
      dispatch({
        type: "TICKET_UPDATE_SUCCESS",
        payload: res.data,
      });
    }
  };
};

export const deleteTicket = (data) => {
  return async (dispatch) => {
    dispatch({ type: "TICKET_DELETE_REQUEST" });
    const res = await Api.Tickets.deleteTicket(data);
    if (res.error) {
      dispatch({
        type: "TICKET_DELETE_FAIL",
        payload: res.data,
      });
    } else {
      dispatch({
        type: "TICKET_DELETE_SUCCESS",
        payload: res.data.rules,
      });
    }
  };
};

export const createTicket = (data) => {
  return async (dispatch) => {
    dispatch({ type: "TICKET_CREATE_REQUEST" });
    const res = await Api.Tickets.createTicket(data);
    if (res.error) {
      dispatch({
        type: "TICKET_CREATE_FAIL",
        payload: res.data,
      });
    } else {
      dispatch({
        type: "TICKET_CREATE_SUCCESS",
        payload: res.data.rules,
      });
    }
  };
};
