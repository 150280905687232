import React, { useState } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../../constant/config';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Box, FormGroup, OutlinedInput, Button } from '@material-ui/core';
import { ReactComponent as Logo } from '../../assets/images/dappstore-logo.svg';
import Loader from '../../components/loader/Loader';
import { SuccessModal, ErrorModal } from '../../components/alerts/Alerts'
import './Login.scss';

const Forgot = () => {
  let history = useHistory();

  // ALERTS START =================================

  // Loading
  const [loading_alert, set_loading_alert] = useState(false);
  const onClose_loading_alert = () => set_loading_alert(false);
  const onOpen_loading_alert = () => set_loading_alert(true);

  // Success
  const [success_alert, set_success_alert] = useState(false);
  const onClose_success_alert = () => {
    set_success_alert(false);
    setTimeout(() => { history.push('/') }, 500);
  }
  const onOpen_success_alert = () => set_success_alert(true);

  // Error
  const [error_alert, set_error_alert] = useState(false);
  const [error_message, set_error_message] = useState();
  const onClose_error_alert = () => {
    set_error_alert(false);
    setTimeout(() => {
      set_error_message();
    }, 2000)
  };
  const onOpen_error_alert = () => set_error_alert(true);

  // ALERTS END =================================

  const [values, setValues] = useState({
    email: '',
  })

  const onChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    onOpen_loading_alert()
    if (values.email) {
      executeRequest()
    } else {
      setTimeout(() => {
        set_error_message('Unkown email')
        onClose_loading_alert()
        onOpen_error_alert();
      }, 1000)
    }
  }

  const executeRequest = () => {
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/forgot-password`,
      data: values
    }).then(res => {
      let payload = res.data.results;
      console.log(res)
      if(res){
        setTimeout(()=>{
          console.log(payload)
          onOpen_success_alert()
        }, 1000)
      }
    }).catch(error => {
      console.log(error);
      console.log(error.message);
      setTimeout(() => {
        if(error.response.data.message){
          set_error_message(error.response.data.message)
        }
        onClose_loading_alert()
        onOpen_error_alert();
      }, 1000)
    });
  }


  return (
    <div className="page-wrapper forgot-password-page">
      <Box className="form-box">
        <Logo />
        <h2 className="form-title">Forgot Password</h2>
        <form onSubmit={onSubmit}>
          <FormGroup className="mb-36">
            <OutlinedInput
              className="form-input"
              id="email-field"
              type="text"
              placeholder="Email"
              value={values.email}
              onChange={onChange('email')}
              labelWidth={0}
            />
          </FormGroup>
          <Button
            className="btn-submit"
            variant="contained"
            color="primary"
            type="submit"
            disableElevation
            disabled={loading_alert ? true : false}>
            <span className="text">Request New Password</span>
            <Loader
              appear={loading_alert}
              timeout={1000}
              thick
              width="22"
              coloredBg
              svgProps={{
                absolute: true,
                direction: "right",
                negative: true,
              }}
            />
          </Button>
        </form>
        <RouterLink
          className="btn-forgot"
          to="/login">
          Back to Login
        </RouterLink>
      </Box>

      <SuccessModal
        description={<>
          Reset link submitted to:
          <span className="mb-4 d-block text-purple">{JSON.stringify(values.email).replace(/"/g, "")}</span>
          Please check your email account.
        </>}
        open={success_alert}
        onClose={onClose_success_alert}
      />

      <ErrorModal
        description={`${error_message ? error_message : 'Something went wrong'}.`}
        open={error_alert}
        btnLabel="Go Back"
        onClose={onClose_error_alert}
      />
    </div>
  );
}

export default Forgot;