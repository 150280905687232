import React from 'react';
import { Select, FormControl } from '@material-ui/core';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import './style.scss';

const RecordsPerPage = ({ recordsPerPage, setRecordsPerPage, setPageNumber }) => {

	const handleChange = (e) => {
		e.preventDefault();
		setRecordsPerPage(e.target.value);
		setPageNumber(1);
	}

	return (
		<>
			<FormControl variant="outlined" className="records-per-page">
				<Select
					native
					value={recordsPerPage}
					onChange={handleChange}
					IconComponent = {ExpandMoreOutlinedIcon}
					inputProps={{
						name: 'recordsPerPage',
						id: 'records-per-page',
					}}
				>
					{/* <option value={1}>1</option>
					<option value={2}>2</option>
					<option value={3}>3</option>
					<option value={4}>4</option>
					<option value={5}>5</option> */}
					<option value={15}>15</option>
					<option value={30}>30</option>
					<option value={60}>60</option>
					<option value={120}>120</option>
				</Select>
				<label>Records per page</label>
			</FormControl>
		</>
	);
}

export default RecordsPerPage;