import Web3 from "web3";
import axios from "axios";

let web3 = new Web3(window.ethereum);
let result = {
  data: "",
  message: "",
  error: false,
};

export const saveLocalStorage = (key,value) => {
  try {
      const serializedState = JSON.stringify(value)
      localStorage.setItem(`${key}`, serializedState)
  } catch (err) {
      // return undefined
  }
}

export async function executeConnect() {
  // if there's wallet wallet detected return error else try catch getting the wallet accounts.
  if (!window.ethereum) {
    result.error = true;
    result.message = "Please install Metawallet first.";
    return result;
  } else {
    try {
      /*  saveLocalStorage("signincalled", "true"); */
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      saveLocalStorage('wallet_address', accounts[0])
      result.data = accounts[0];
      result.message = "Wallet Connected Successfully";
      return result;
    } catch (error) {
      result.error = true;
      result.message =
        "We have recorded an attempt to access your wallet. Please refresh the page and try again.";
      result.data = error;
      return result;
    }
  }
}