import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, TextField, FormControl, makeStyles} from '@material-ui/core';
import { updateTypes, createTypes } from "../../../../actions/TypeActions";
import Loader from '../../../loader/Loader';
import './Type.scss'

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '400px',
    minHeight: '400px',
    bgcolor: 'background.paper',
    border: '1px solid grey',
    borderRadius: '20px',
    boxShadow: 24,
    p: 4,
    backgroundColor: 'white'
  },
  textFieldContainer: {
    position: 'relative',
    display: 'inline-block'
  },
  TextFieldBorder: {
    [`& fieldset`]: {
      borderRadius: 20,
      width: 690,
      height: 40,
      paddingBottom: 7,
      marginTop: '7px',
      display: 'flex',
      alignItems: 'flex-start',
    },
  width: '300px',
  },
});

const TypeModal= (props) =>{
  const dispatch = useDispatch()
  const classes = useStyles()

  const [message, setMessage] = useState('')
  const [warning, setWarning] = useState(false)
  const [typeName, setTypeName] = useState('')
  
  const typeUpdate = useSelector((state) => state.typeUpdate);
  const { success, loading } = typeUpdate;

   const handleNameChange = (e) => {
     setTypeName(e.target.value)
   }

   const handleSubmit = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append('name', typeName)

    let id = props.typeData.id;

    if(props.addModal) {
      dispatch(createTypes(formData))
    } else {
      dispatch(
        updateTypes(formData, id)
      );
    }
      props.onSubmit();
  };

  return (
    <>
    <Modal
      open={props.openEditModal}
    >
        <div className={classes.root}>
        <div className="paper-wrapper" >
          <div className="paper">

            <div className="header">
              <h2 className="title">{props.modalTitle}</h2>
            </div>
            <form onSubmit={handleSubmit}>
            <div className="textfield-item-container">
                <FormControl className="edit-form-control edit-type-textfield">
                  <label className="form-label" htmlFor="package">Ticket Type</label>
                  <div className={classes.textFieldContainer}>
                    <TextField variant="outlined" className={classes.TextFieldBorder} value={typeName} onChange={(e) => handleNameChange(e)}/>
                  </div>
                </FormControl>
            </div>
            <div className="footer edit-type-footer">
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className="submit-btn"
                type="submit"
                disabled={loading ? true : false}
                >
                <span className="text">Save</span>
                <Loader
                  appear={loading}
                  timeout={1000}
                  thick
                  width="22"
                  coloredBg
                  svgProps={{
                    absolute: true,
                    direction: "right",
                    negative: true,
                  }}
                />
              </Button>
              <Button
                disableElevation
                variant="contained"
                className="exit-btn"
                onClick={props.onClose}
                disabled={loading ? true : false}
                >
                Exit
              </Button>

              {/* {err_message &&
                <Fade className="error-message" in={err_message ? true : false} timeout={500} >
                  <Alert severity="error" variant="outlined">{err_message}</Alert>
                </Fade>
              } */}
            </div>
              </form>
          </div>
        </div>
        </div>
    </Modal>
  </>
  );
}

export default TypeModal