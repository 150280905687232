export const getPledgesReducer = (state = { pledges: {} }, action) => {
  switch (action.type) {
    case "PLEDGE_LIST_REQUEST":
      return { loading: true, pledges: {} };
    case "PLEDGE_LIST_SUCCESS":
      return { loading: false, getPledgesSuccess: true, pledges: action.payload };
    case "PLEDGE_LIST_FAIL":
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updatePledgesReducer = (state = {}, action) => {
  switch (action.type) {
    case "PLEDGE_UPDATE_REQUEST":
      return { updateLoading: true, pledges: {} };
    case "PLEDGE_UPDATE_SUCCESS":
      return { updateLoading: false, updatePledgesSuccess: true, pledges: action.payload };
    case "PLEDGE_UPDATE_FAIL":
      return { updateLoading: false, error: action.payload };
    default:
      return state;
  }
};