import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, makeStyles } from "@material-ui/core";
import dApxImg from "../../../../assets/images/currency/dappx.png";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import DataTable from "react-data-table-component";
import Loader from "../../../loader/Loader";
import { ConditionalModal } from "../../../alerts/Alerts";
import { deleteTypes, listTypes } from "../../../../actions/TypeActions";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    "& .myIcon": {
      paddingLeft: "8px",
      order: 999,
    },
  },
  textFieldContainer: {
    position: "relative",
    display: "inline-block",
  },
  textFieldImg: {
    position: "absolute",
    right: 10,
    top: 10,
    width: 20,
    height: 20,
  },
  primaryGrid: {
    margin: "8px",
  },
  TextFieldBorder: {
    [`& fieldset`]: {
      borderRadius: 20,
    },
    width: "300px",
  },
  midSection: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  aTag: {
    textDecoration: "none",
    color: "blue",
    cursor: "pointer",
  },
  ticketSetup: {
    marginBottom: "50px",
  },
});

const TypeDetails = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [delete_alert, set_delete_alert] = useState(false);
  const onClose_delete_alert = () => set_delete_alert(false);
  const [delete_details, set_delete_details] = useState({
    id: "",
    name: "",
  });

  const ticketType = useSelector((state) => state.ticketType);
  const { loading, error, typeSuccess,  types } = ticketType;

  // useEffect(() => {
  // 	if (data.length >= 0) {
  // 		stop_loading()
  // 	}
  // }, []);

  //Edit and Delete Buttons
  const format_action = (row) => {
    return (
      <div className="action-cell">
        <Button
          variant="contained"
          disableElevation
          color="primary"
          className="edit-btn"
          onClick={() => props.onEdit(row)}
        >
          Edit
        </Button>
        <Button
          variant="contained"
          disableElevation
          color="secondary"
          className="delete-btn"
          onClick={() => onOpen_delete_alert(row)}
          disabled
        >
          Delete
        </Button>
      </div>
    );
  };

  const onOpen_delete_alert = (row) => {
    set_delete_alert(true);
    set_delete_details({
      // id: row._id,
      name: row.TicketName,
    });
  };
  const onAccept_delete = (row) => {
    set_delete_alert(false);
    dispatch(deleteTypes(types[0]._id))
  };

  const onDenied_delete = () => {
    set_delete_alert(false);
    set_delete_details({
      id: "",
      title: "",
    });
  };

  const primaryColumns = [
    {
      name: "Type",
      selector: (row) => row.Type,
    },
    {
      width: "202px",
      cell: (row) => format_action(row),
      button: true,
    },
  ];

  const primaryData = [
    {
      id: types[0] ? types[0]._id : null,
      Type: types[0] ? types[0].name : null,
    },
  ];

  return (
    <>
      <div className="content">
        <div className={classes.midSection}>
          <p> Ticket Type</p>
          {!props.dataLimit ?
          <a className={classes.aTag} onClick={() => props.onAdd()}>
            <b>Add Entry</b>
          </a>
          :
          ""
          }   
        </div>
        {/* Table Section */}
        <div className={classes.ticketSetup}>
          <DataTable
            columns={primaryColumns}
            data={primaryData}
            data={types[0] ? primaryData : []}
            sortServer
            progressComponent={<Loader appear={loading} width="100" />}
            persistTableHead
          />
        </div>

        <ConditionalModal
          title={delete_details.name}
          description="Are you sure you want to delete this Type?"
          open={delete_alert}
          onClose={onClose_delete_alert}
          onAccept={onAccept_delete}
          onDenied={onDenied_delete}
          acceptBtn_label="Delete"
        />
      </div>
    </>
  );
};

export default TypeDetails;
