import Api from "../api";

export const listTicketsSetup = () => {
  return async (dispatch) => {
    dispatch({ type: "SETUP_LIST_REQUEST" });
    const res = await Api.ticketsSetup.getTicketsSetup();
    if (res.error) {
      dispatch({
        type: "SETUP_LIST_FAIL",
        payload: res.data,
      });
    } else {
      dispatch({
        type: "SETUP_LIST_SUCCESS",
        payload: res.data,
      });
    }
  };
};

export const updateTicketSetup = (data, id) => {
  return async (dispatch) => {
    dispatch({ type: "SETUP_UPDATE_REQUEST" });

    const res = await Api.ticketsSetup.updateTicketSetup(data, id);

    if (res.error) {
      dispatch({
        type: "SETUP_UPDATE_FAIL",
        payload: res.data,
      });
    } else {
      dispatch({
        type: "SETUP_UPDATE_SUCCESS",
        payload: res.data,
      });
    }
  };
};

export const deleteTicketSetup = (data) => {
  return async (dispatch) => {
    dispatch({ type: "SETUP_DELETE_REQUEST" });
    const res = await Api.ticketsSetup.deleteTicketSetup(data);
    if (res.error) {
      dispatch({
        type: "SETUP_DELETE_FAIL",
        payload: res.data,
      });
    } else {
      dispatch({
        type: "SETUP_DELETE_SUCCESS",
        payload: res.data.rules,
      });
    }
  };
};

export const createTicketSetup = (data) => {
  return async (dispatch) => {
    dispatch({ type: "SETUP_CREATE_REQUEST" });
    const res = await Api.ticketsSetup.createTicketSetup(data);
    if (res.error) {
      dispatch({
        type: "SETUP_CREATE_FAIL",
        payload: res.data,
      });
    } else {
      dispatch({
        type: "SETUP_CREATE_SUCCESS",
        payload: res.data,
      });
    }
  };
};