import React from 'react';

const Dapps = () => {
  return (
    <div className="page-wrapper Dapps-page">
      <div className="page-sidebar">Page Sidebar</div>
      <div className="page-content custom-scroll">
        <div className="page-title">Page Title</div>
        <div className="content">Page Content</div>
      </div>
    </div>
  );
}
 
export default Dapps;