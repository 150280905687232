import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableWrapper from "../../../table-elements/TableWrapper";
import PageTitle from "../../../page-title/PageTitle";
import { Grid, Switch, Typography } from "@material-ui/core";
import Tickets from "./Ticket-Details";
import TicketsModal from "./TicketsModal";
import Loader from "../../../loader/Loader";
import { listTickets, updateRewards, listRewardsStatus } from "../../../../actions/TicketsActions";
import { listTicketsSetup } from "../../../../actions/TicketsSeupActions";
import { ConditionalModal } from "../../../alerts/Alerts";
import "./Tickets.scss";

const TicketsPage = () => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [title, setTitle] = useState("");
  const [showContent, setShowContent] = useState(true);
  const [ warningModal, setWarningModal ] = useState(false)
  const [edit_data, set_edit_data] = useState({});
  const [addModal, setAddModal] = useState(false)

  const ticketList = useSelector((state) => state.ticketList);
  const ticketUpdate = useSelector((state) => state.ticketUpdate)
  const ticketDelete = useSelector((state) => state.ticketDelete)
  const ticketCreate = useSelector((state) => state.ticketCreate)
  const rewardsStatus = useSelector((state) => state.rewardsStatus);
  const setupList = useSelector((state) => state.setupList);


  const { loading, tickets } = ticketList;
  const { ticketsSetup } = setupList;
  const { rewards } = rewardsStatus;
  const { updateSuccess } = ticketUpdate
  const { deleteSuccess } = ticketDelete
  const { createSuccess } = ticketCreate

  useEffect(() => {
    dispatch(listTickets());
    dispatch(listTicketsSetup());
    dispatch(listRewardsStatus())
    setShowContent(rewards.status)
  }, [updateSuccess, deleteSuccess, createSuccess]);

  const editModal = (row) => {
    setTitle("Edit Ticket");
    set_edit_data(row);
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
    setAddModal(false)
  };

  const addTicket = () => {
    setOpenModal(true);
    setTitle("Add New Ticket");
    setAddModal(true)
  };

  const onModalSubmit = () => {
    setOpenModal(false);
    dispatch(listTickets());
    setAddModal(false)
  };

  const checkRewardsStatus = () => {
    if(rewards.status === true) {
      setWarningModal(true)
    } else {
      setShowContent(!showContent)
      dispatch(updateRewards({rewards_status: !showContent}))
    }
  }

  const rewardsSwitch = () => {
    setShowContent(!showContent)
    dispatch(updateRewards({rewards_status: !showContent}))
    setWarningModal(false)
  }

  return (
    <>
      <PageTitle title="Ticket Reward" hideHistoryBtn hideAddBtn />
      <div className="content">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TableWrapper
              header={
                <>
                  <div className="main-switch">
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      component="div"
                    >
                      Pledging Ticket Reward
                    </Typography>
                    <Switch
                      checked={showContent}
                      color="primary"
                      onChange={checkRewardsStatus}
                    />
                  </div>
                </>
              }
              body={
                showContent ? (
                  loading === false ? (
                    <Tickets
                      onEdit={editModal}
                      onAdd={addTicket}
                      dataLimit={tickets.length > 0 ? true : false}
                    />
                  ) : (
                    <Loader apear width={50} />
                  )
                ) : (
                  ""
                )
              }
            />
          </Grid>
        </Grid>
      </div>
      {loading === false ? (
        <TicketsModal
          openEditModal={openModal}
          onClose={closeModal}
          modalTitle={title}
          onSubmit={onModalSubmit}
          ticketData={edit_data}
          addModal={addModal}
          setupData={ticketsSetup}
        />
      ) : (
        <Loader appear width={50} />
      )}
      <ConditionalModal
          description="Disabling Ticket Reward will affect the users that have on going qualification for the ticket reward, Do you want to proceed?"
          open={warningModal}
          onClose={() => setWarningModal(false)}
          onAccept={rewardsSwitch}
          onDenied={() => setWarningModal(false)}
          acceptBtn_label="Disable"
        />
    </>
  );
};

export default TicketsPage;
