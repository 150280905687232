import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableWrapper from "../../../table-elements/TableWrapper";
import PageTitle from "../../../page-title/PageTitle";
import { Grid } from "@material-ui/core";
import TypeDetails from "./TypeDetails";
import TypeModal from "./TypeModal";
import { listTypes } from "../../../../actions/TypeActions";
import Loader from "../../../loader/Loader";
import "./Type.scss";

const TypePage = () => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [edit_data, set_edit_data] = useState({});
  const [title, setTitle] = useState("");
  const [addModal, setAddModal] = useState(false)

  const ticketType = useSelector((state) => state.ticketType);
  const typeUpdate = useSelector((state) => state.typeUpdate)
  const typeDelete = useSelector((state) => state.typeDelete)
  const typeCreate = useSelector((state) => state.typeCreate)

  const { loading, types } = ticketType;
  const { updateLoading, updateSuccess } = typeUpdate
  const { deleteLoading, deleteSuccess } = typeDelete
  const { createLoading, createSuccess } = typeCreate

  useEffect(() => {
    dispatch(listTypes());
  }, [ updateSuccess, deleteSuccess, createSuccess]);

  const editModal = (row) => {
    setTitle("Edit Ticket Type");
    set_edit_data(row);
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const addTicket = () => {
    setOpenModal(true);
    setTitle("Add Ticket Type");
    setAddModal(true)
  };

  const onModalSubmit = () => {
    setOpenModal(false);
    setAddModal(false)
  };

  return (
    <>
    {loading || updateLoading || deleteLoading || createLoading ?
      <Loader appear width={52}/>
      :
      <>
      <PageTitle title="Ticket Type" hideHistoryBtn hideAddBtn />
      <div className="content">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TableWrapper
              body={
                loading === false ? (
                <TypeDetails
                  onEdit={editModal}
                  onAdd={addTicket}
                  dataLimit={types.length > 0 ? true : false}
                />
                ) : (
                  <Loader apear width={50} />
                )
              }
              
            />
          </Grid>
        </Grid>
      </div>
      <TypeModal
        openEditModal={openModal}
        onClose={closeModal}
        modalTitle={title}
        onSubmit={onModalSubmit}
        typeData={edit_data}
        addModal={addModal}
      />
      </>
    }
    </>
  );
};

export default TypePage;
