import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux'
import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import reportWebVitals from './reportWebVitals';
import theme from './layout/theme';
import App from './layout/App';
import store from './store'

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <ScopedCssBaseline className="baseline">
      <Router>
      <Provider store={store}>
        <App />
      </Provider>
      </Router>
    </ScopedCssBaseline>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
