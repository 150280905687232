import React, {useState, useEffect} from 'react'
import PageTitle from "../../page-title/PageTitle";
import TableWrapper from "../../table-elements/TableWrapper";
import { Switch, Grid, Typography } from "@material-ui/core"
import { ConditionalModal } from "../../alerts/Alerts";
import Loader from "../../loader/Loader";
import axios from 'axios'

const IS_UAT = window.location.href.includes('-uat');
const IS_SAT = window.location.href.includes('localhost') || window.location.href.includes('-sat');

const DAPPX_ADMIN_API = `${IS_SAT ? "https://dappx-api-sat.dappstore.me/" : (IS_UAT ? "https://dappx-api-uat.dappstore.me/" : "https://dappx-api.dappstore.me/")}`;

const Maintenance = () => {
  const [maintenanceOn, setMaintenanceOn] = useState(false)
  const [loading, setLoading] = useState(true)

  const fetchSettings = () => {
    axios.get(DAPPX_ADMIN_API + 'settings/getMaintenanceMode')
    .then((settings) => {
      if (settings.status == 200) {
        setMaintenanceOn(settings.data.maintenance_mode)
      }
  
      setLoading(false)
    })
    .catch((error) => {
      console.log(error)
      setLoading(false)
    })
  }

  useEffect(() => {
    fetchSettings()
    console.log(loading)
  },[])

  const switchMaintenanceMode = () => {
    let data ={
      maintenance_mode: !maintenanceOn
    }

    axios.post(
      DAPPX_ADMIN_API + 'settings/updateMaintenanceMode',
      data,
      {
        headers:
        {
          'custom-token-id': 'H00R7LPQSY'
        }
      }
    ).then((settings) => {
      if (settings.data.message == 'success') {
        setMaintenanceOn(!maintenanceOn)
        setLoading(false)
      }
    }).catch((error) => {
      console.log(error)
      setMaintenanceOn(maintenanceOn)
      setLoading(false)
    })
  }

  return (
    <>
    {loading ? 
    <Loader appear width={50} 
    />
    :
    <>
    <PageTitle title="Site Maintenance" hideHistoryBtn hideAddBtn />
      <div className="content">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TableWrapper
              header={
                <>
                  <div className="main-switch">
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      component="div"
                    >
                      Site Maintenance
                    </Typography>
                    <Switch
                      checked={maintenanceOn}
                      color="primary"
                      onChange={switchMaintenanceMode}
                    />
                  </div>
                </>
              }
              body={
                <Typography
                  variant="body2"
                  gutterBottom
                  component="div"
                  style={{marginLeft: '3.5%'}}
                  >
                  <b>Note:</b> This confirguration will affect both dappstore.mre and dappstore.io site together with the dappstore API functionality.
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </div>
      </>
  }
      
      <ConditionalModal
          description="Disabling Ticket Reward will affect the users that have on going qualification for the ticket reward, Do you want to proceed?"
          // open={warningModal}
          // onClose={() => setWarningModal(false)}
          // onAccept={rewardsSwitch}
          // onDenied={() => setWarningModal(false)}
          acceptBtn_label="Disable"
        />
    </>
  )
}

export default Maintenance