import React, {useState, useEffect} from 'react'
import { useDispatch } from 'react-redux'
import { Button, makeStyles, Tooltip } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import DataTable from 'react-data-table-component';
import Loader from '../../loader/Loader';
import moment from 'moment';
import axios from 'axios'

const useStyles = makeStyles({
  root: {
      display: "flex",
      alignItems: "center",
      "& .myIcon": {
        paddingLeft: "8px",
        order: 999
    }
  },
  textFieldContainer: {
    position: 'relative',
    display: 'inline-block'
  },
  textFieldImg: {
    position: 'absolute',
    right: 10,
    top: 17,
    width: 20,
    height: 20
  },
  primaryGrid: {
    margin: '8px'
  },
  TextFieldBorder: {
    [`& fieldset`]: {
      borderRadius: 20,
  },
  width: '300px',
  },
  midSection: {
    width: '100%',
    display: 'flex',
    justifyContent:'space-between',
  },
  aTag: {
    textDecoration: 'none',
    color: 'blue',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
  },
  buttonsHolder : {
    marginTop: '50px',
    display: 'flex',
    justifyContent: 'center'
  },
  nestedTextFieldsContainer:{
    marginLeft: '155px',
    marginTop: '10px'
  },
  pStyle: {
    marginTop: '10px',
    fontWeight: 500
  }
});

const TxHistory = ({data}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [txHistory, setTxHistory] = useState([])

  const CutomToolTip = withStyles({
    tooltip: {
      color: "#111111",
      backgroundColor: "#ffffff",
      fontSize: '13px',
      border: '0.5px solid #898989',
      textAlign: 'center'
    }
  })(Tooltip);

  const IS_UAT = window.location.href.includes('-uat');
  const IS_SAT = window.location.href.includes('localhost') || window.location.href.includes('-sat');
  const token = localStorage.getItem('token')
  const DAPPX_ADMIN_API = `${IS_SAT ? "https://dappx-adminapi-sat.dappstore.me/" : (IS_UAT ? "https://dappx-adminapi-uat.dappstore.me/" : "https://dappx-adminapi.dappstore.me/")}`;

  useEffect(() => {
    axios.get(`${DAPPX_ADMIN_API}ace-withdrawal/history`, {headers: { 'auth-token': token}})
    .then((res) => {
      setTxHistory(res.data.txs)
      setLoading(false)
    })
    .catch((err) => {
      console.log(err)
    })
  },[])

  const format_date = (row) => {
    let date = row.date_time
		let formated_date = moment(date).format('MMM DD, YYYY, HH:MM:SS');
		return <>{formated_date}</>
	}

  const format_username = (row) => {
    let frontChars = Math.ceil(12/2)
    let backChars = Math.floor(8/2)
    const final = row.wallet.substr(0, frontChars) + '...' + row.wallet.substr(row.wallet.length - backChars);
		let username = row.username;
		return <CutomToolTip placement="top" title={final}><p>{username}</p></CutomToolTip>
	}

  const format_status = (row) => {
    return <p>{row.status == 1 ? 'Completed' : 'Pending'}</p>
  }
  
  const format_url = (row) => {
    let frontChars = Math.ceil(12/2)
    let backChars = Math.floor(8/2)
    const final = row.txHash.substr(0, frontChars) + '...' + row.txHash.substr(row.txHash.length - backChars);
		let tx = row.txHash;
    let url = `https://etherscan.io/tx/${tx}`
		return row.txHash ? <a href={url} target="_blank" className="image-url ellipsis">{final ? final : ''}</a> : '';
	}

  const columns = [
    {
        name: 'Date and Time',
        selector: row => format_date(row),
        minWidth: '190px',
    },
    {
        name: 'Wallet Address',
        // selector: row => format_username(row),
        selector: row => row.walletAddress,
    },
    {
        name: 'ACE Amount',
        selector: row => row.aceAmount,
    },
    {
        name: 'Total ACE Fee',
        selector: row => row.aceFee,
    },
    {
        name: 'Tx Hash',
        selector: row => format_url(row),
    },
    {
        name: 'Status',
        selector: row => format_status(row),
    },
  ];

  const newData = txHistory?.map(item => (
    {
      date_time: item.timestamp,
      walletAddress: item.walletAddress ? item.username : '',
      aceAmount: item.amount,
      aceFee: Number(item.withdrawal_fee_core.toFixed(6)) + Number(item.withdrawal_fee_nitro.toFixed(6)) + Number(item.withdrawal_fee_ubi.toFixed(6)),
      txHash: item.tx_hash,
      status: item.status
    }
  ));

  useEffect(() => {
    setLoading(true);
  }, []);

  return (
    <>
    <div className="content">
    <form>

      <div className={classes.midSection}>
        <p><b>Withdrawal Record</b></p>
      </div>

      {/* Table Section */}
      <DataTable
				columns={columns}
				data={newData}
				sortServer
				progressPending={loading}
				progressComponent={
					<Loader
						appear={loading}
						width="100"
					/>
				}
				persistTableHead
			/>
      </form>
    </div>
    </>
  )
}

export default TxHistory