import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableWrapper from "../../../table-elements/TableWrapper";
import PageTitle from "../../../page-title/PageTitle";
import { Grid } from "@material-ui/core";
import SetupDetails from "./SetupDetails";
import SetupModal from "./SetupModal";
import { listTicketsSetup } from "../../../../actions/TicketsSeupActions";
import Loader from "../../../loader/Loader";
import "./Setup.scss";

const SetupPage = () => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [edit_data, set_edit_data] = useState({});
  const [title, setTitle] = useState("");
  const [addModal, setAddModal] = useState(false)

  const setupList = useSelector((state) => state.setupList);
  const setupUpdate = useSelector((state) => state.setupUpdate)
  const setupDelete = useSelector((state) => state.setupDelete)
  const setupCreate = useSelector((state) => state.setupCreate)

  const { loading, ticketsSetup } = setupList;
  const { updateLoading, updateSuccess } = setupUpdate
  const { deleteLoading, deleteSuccess } = setupDelete
  const { createLoading, createSuccess } = setupCreate

  useEffect(() => {
    dispatch(listTicketsSetup());
  }, [updateSuccess, deleteSuccess, createSuccess]);

  const editModal = (row) => {
    setTitle("Edit Ticket Setup");
    set_edit_data(row);
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const addTicket = () => {
    setOpenModal(true);
    setTitle("Add Ticket Setup");
    setAddModal(true)
  };

  const onModalSubmit = () => {
    setOpenModal(false);
    dispatch(listTicketsSetup())
    setAddModal(false)
  };

  return (
    <>
      <PageTitle title="Ticket Setup" hideHistoryBtn hideAddBtn />
      <div className="content">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TableWrapper
              body={
                loading === false ? (
                <SetupDetails
                  onEdit={editModal}
                  onAdd={addTicket}
                  dataLimit={ticketsSetup.tickets.length > 0 ? true : false}
                />
                ) : (
                  <Loader apear width={50} />
                )
              }
              
            />
          </Grid>
        </Grid>
      </div>
      <SetupModal
        openEditModal={openModal}
        onClose={closeModal}
        modalTitle={title}
        onSubmit={onModalSubmit}
        ticketData={edit_data}
        addModal={addModal}
      />
    </>
  );
};

export default SetupPage;
