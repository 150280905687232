import React from 'react';
import PageTitle from '../../../components/page-title/PageTitle';

const MainSlider = () => {
  return (
    <>
      <PageTitle title="Main | Rolling Banner"/>
      <div className="content">

      </div>
    </>
  );
}

export default MainSlider;