export const ticketListRedcuer = (state = { tickets: [] }, action) => {
  switch (action.type) {
    case "TICKET_LIST_REQUEST":
      return { loading: true, tickets: [] };
    case "TICKET_LIST_SUCCESS":
      return { loading: false, tickets: action.payload };
    case "TICKET_LIST_FAIL":
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const rewardStatusReducer = (state = { rewards: {} }, action) => {
  switch (action.type) {
    case "REWARDS_STATUS_REQUEST":
      return { loading: true, rewards: {} };
    case "REWARDS_STATUS_SUCCESS":
      return { loading: false, rewardsSuccess: true, rewards: action.payload };
    case "REWARDS_STATUS_FAIL":
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const rewardsUpdateRedcuer = (state = {}, action) => {
  switch (action.type) {
    case "REWARDS_UPDATE_REQUEST":
      return { updateLoading: true, rewards: {} };
    case "REWARDS_UPDATE_SUCCESS":
      return { updateLoading: false, updateSuccess: true, rewardsInfo: action.payload };
    case "REWARDS_UPDATE_FAIL":
      return { updateLoading: false, error: action.payload };
    default:
      return state;
  }
};

export const ticketUpdateRedcuer = (state = {}, action) => {
  switch (action.type) {
    case "TICKET_UPDATE_REQUEST":
      return { updateLoading: true, tickets: [] };
    case "TICKET_UPDATE_SUCCESS":
      return { updateLoading: false, updateSuccess: true, ticketInfo: action.payload };
    case "TICKET_UPDATE_FAIL":
      return { updateLoading: false, error: action.payload };
    default:
      return state;
  }
};

export const ticketDeleteReducer = (state = { Ticket: {} }, action) => {
  switch (action.type) {
    case "TICKET_DELETE_REQUEST":
      return { deleteLoading: true, ticket: {} };
    case "TICKET_DELETE_SUCCESS":
      return { deleteLoading: false, deleteSuccess: true, ticketInfo: action.payload };
    case "TICKET_DELETE_FAIL":
      return { deleteLoading: false, error: action.payload };
    default:
      return state;
  }
};


export const ticketCreateReducer = (state=[], action) => {
  switch (action.type) {
      case 'TICKET_CREATE_REQUEST': 
      return {
        ...state,
        createLoading: true,
        createSuccess: false
      };
      case "TICKET_CREATE_SUCCESS":
        return { 
          ...state,
          createLoading: false,
          createSuccess: true,
          typeInfo: action.payload };
      case "TICKET_CREATE_FAIL":
        return { 
          ...state,
          createLoading: false,
          createError: action.payload
        };
      default: 
          return state
  }
}