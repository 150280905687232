import React, {useState, useEffect} from 'react'
import Web3 from 'web3';
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@material-ui/core';
import { Button, Typography, TextField, makeStyles } from '@material-ui/core';
import dApxImg from '../../../assets/images/currency/dappx.png'
import DataTable from 'react-data-table-component';
import Loader from '../../loader/Loader';
import { ConditionalModal } from '../../alerts/Alerts';
import { postPledgesMining, getPledgeMining } from '../../../actions/PledgeMining'
import { postPledgesConfig } from '../../../actions/PledgesActions'

const useStyles = makeStyles({
  root: {
      display: "flex",
      alignItems: "center",
      "& .myIcon": {
        paddingLeft: "8px",
        order: 999
    }
  },
  textFieldContainer: {
    position: 'relative',
    display: 'inline-block'
  },
  textFieldImg: {
    position: 'absolute',
    right: 10,
    top: 17,
    width: 20,
    height: 20
  },
  primaryGrid: {
    margin: '8px'
  },
  TextFieldBorder: {
    [`& fieldset`]: {
      borderRadius: 20,
  },
  width: '300px',
  },
  midSection: {
    width: '100%',
    display: 'flex',
    justifyContent:'space-between',
    marginTop: '20px',
  },
  aTag: {
    textDecoration: 'none',
    color: 'blue',
    cursor: 'pointer'
  },
  buttonsHolder : {
    marginTop: '50px',
    display: 'flex',
    justifyContent: 'center'
  }
});

const Pledging = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const getMinings = useSelector( state => state.getMinings)
  const updateMinings = useSelector(state => state.updateMinings)

  const { minings } = getMinings
  const { updateLoading } = updateMinings

  const pledgesList = useSelector(state => state.pledgesList)
  const { getPledgesSuccess, pledges} = pledgesList

  const [loading, setLoading] = useState(true);
  const [delete_alert, set_delete_alert] = useState(false);
	const [delete_details, set_delete_details] = useState({
		id: '',
		title: ''
	})
  const [minPledgeNumber, setMinPledgeNumber] = useState(Web3.utils.fromWei(pledges.data?.minimum_pledge_limit_per_transaction.toString()))
  const [maxPledgeNumber, setMaxPledgeNumber] = useState(Web3.utils.fromWei(pledges.data?.maximum_pledge_limit_per_transaction.toString()))
  const [maxAccountPledge, setMaxAccountPledge] = useState(Web3.utils.fromWei(pledges.data?.maximum_pledge_per_account.toString()))
  const [activationFee, setActivationFee] = useState(Web3.utils.fromWei(pledges.data?.activation_fee.toString()))
  const [holdingPeriod, setHoldingPeriod] = useState(pledges.data?.holding_period)
  const [penaltyFee, setPenaltyFee] = useState(pledges.data?.penalty_fee)
  const [showButtons, setShowButtons] = useState(false)

  //Edit and Delete Buttons
  const format_action = (row) => {
		return (
			<div className="action-cell">
				<Button 
					variant="contained" 
					disableElevation 
					color="primary" 
					className="edit-btn" 
					onClick={() => props.onEdit(row)}
          >
						Edit
					</Button>
				<Button 
					variant="contained" 
					disableElevation color="secondary" 
					className="delete-btn" 
          // onClick={() => props.onDelete(row)}
					onClick={() => onOpen_delete_alert(row)}
          >
						Delete
				</Button>
			</div>
		)
	}

  const onClose_delete_alert = () => set_delete_alert(false);
	const onOpen_delete_alert = (row) => {
		set_delete_alert(true)
		set_delete_details({
			id: row.id,
			title: row.startRange
		})
	};
	const onAccept_delete = () => {
		set_delete_alert(false)
    let newMinings = [...minings]
    const data = newMinings.filter(item => item.id !== delete_details.id)

    dispatch(
      postPledgesMining(data)
    );
	}

  const onDenied_delete = () => {
		set_delete_alert(false)
		set_delete_details({
			id: '',
			title: ''
		})
	}

  const minPledgeChange = (e) => {
    setMinPledgeNumber(e.target.value)
    setShowButtons(true)
  }

  const maxPledgeChange = (e) => {
    setMaxPledgeNumber(e.target.value)
    setShowButtons(true)
  }

  const maxAccChange = (e) => {
    setMaxAccountPledge(e.target.value)
    setShowButtons(true)
  }

  const actFeeChange = (e) => {
    setActivationFee(e.target.value)
    setShowButtons(true)
  }

  const holdingPeriodChange = (e) => {
    setHoldingPeriod(e.target.value)
    setShowButtons(true)
  }

  const penaltyFeeChange = (e) => {
    setPenaltyFee(e.target.value)
    setShowButtons(true)
  }

  const cancelChanged =() =>{
    setShowButtons(false)
    setMinPledgeNumber(Web3.utils.fromWei(pledges.data?.minimum_pledge_limit_per_transaction.toString()))
    setMaxPledgeNumber(Web3.utils.fromWei(pledges.data?.maximum_pledge_limit_per_transaction.toString()))
    setMaxAccountPledge(Web3.utils.fromWei(pledges.data?.maximum_pledge_per_account.toString()))
    setActivationFee(Web3.utils.fromWei(pledges.data?.activation_fee.toString()))
    setHoldingPeriod(pledges.data?.holding_period)
    setPenaltyFee(pledges.data?.penalty_fee)
  }

  const updatePledgeConfig = (e) => {
    e.preventDefault();
    setShowButtons(false)
    let data ={
      minimum_pledge_limit_per_transaction: minPledgeNumber,
      maximum_pledge_limit_per_transaction: maxPledgeNumber,
      maximum_pledge_per_account: maxAccountPledge,
      activation_fee: activationFee,
      holding_period: holdingPeriod,
      penalty_fee: penaltyFee
    }

    dispatch(
      postPledgesConfig(data)
    );
  }

  const columns = [
    {
        name: 'Start Range',
        selector: row => row.startRange,
    },
    {
        name: 'End Range',
        selector: row => row.endRange,
    },
    {
        name: 'Mining Rate per min',
        selector: row => row.miningRate,
    },
    {
			width: '202px',
			cell: row => format_action(row),
			button: true
		},
];

const data = minings.map(item => (
  {
    id: item.id,
    startRange:item.start_range,
    endRange:item.end_range,
    miningRate:item.mining_rate
  } 
));

  return (
    <>
    <div className="content">
    <form>
      <Grid container spacing={3}>
        {/* Inputs Section */}
              <Grid container spacing={12} className={classes.primaryGrid}>
                <Grid item xs={3}>
                  <Typography>Minimum Pledge Limit per Transaction</Typography>
                </Grid>
                <div className={classes.textFieldContainer}>
                  <img src={dApxImg} alt="" className={classes.textFieldImg}/>
                  <TextField
                    variant="outlined"
                    className={classes.TextFieldBorder}
                    value={minPledgeNumber}
                    placeholder="0.0"
                    onChange={(e) => minPledgeChange(e)}
                  />
                </div>
              </Grid>
      </Grid>

      <Grid container spacing={3}>
        {/* Inputs Section */}
              <Grid container spacing={12} className={classes.primaryGrid}>
                <Grid item xs={3}>
                  <Typography>Maximum Pledge Limit per Transaction</Typography>
                </Grid>
                <div className={classes.textFieldContainer}>
                  <img src={dApxImg} alt="" className={classes.textFieldImg}/>
                  <TextField
                    variant="outlined"
                    className={classes.TextFieldBorder}
                    value={maxPledgeNumber}
                    onChange={(e) => maxPledgeChange(e)}
                  />
                </div>
              </Grid>
      </Grid>

      <Grid container spacing={3}>
        {/* Inputs Section */}
              <Grid container spacing={12} className={classes.primaryGrid}>
                <Grid item xs={3}>
                  <Typography>Maximum Pledge per Account</Typography>
                </Grid>
                <div className={classes.textFieldContainer}>
                  <img src={dApxImg} alt="" className={classes.textFieldImg}/>
                  <TextField
                    variant="outlined"
                    className={classes.TextFieldBorder}
                    value={maxAccountPledge}
                    onChange={(e) => maxAccChange(e)}
                  />
                </div>
              </Grid>
      </Grid>

      <Grid container spacing={3}>
        {/* Inputs Section */}
              <Grid container spacing={12} className={classes.primaryGrid}>
                <Grid item xs={3}>
                  <Typography>Activation Fee</Typography>
                </Grid>
                <div className={classes.textFieldContainer}>
                  <img src={dApxImg} alt="" className={classes.textFieldImg}/>
                  <TextField
                    variant="outlined"
                    className={classes.TextFieldBorder}
                    value={activationFee}
                    onChange={(e) => actFeeChange(e)}
                  />
                </div>
              </Grid>
      </Grid>

      <Grid container spacing={3}>
        {/* Inputs Section */}
              <Grid container spacing={12} className={classes.primaryGrid}>
                <Grid item xs={3}>
                  <Typography>Holding Period (Hours)</Typography>
                </Grid>
                <div className={classes.textFieldContainer}>
                  <img src={dApxImg} alt="" className={classes.textFieldImg}/>
                  <TextField
                    variant="outlined"
                    className={classes.TextFieldBorder}
                    value={holdingPeriod}
                    onChange={(e) => holdingPeriodChange(e)}
                  />
                </div>
              </Grid>
      </Grid>

      <Grid container spacing={3}>
        {/* Inputs Section */}
              <Grid container spacing={12} className={classes.primaryGrid}>
                <Grid item xs={3}>
                  <Typography>Penalty Fee (%)</Typography>
                </Grid>
                <div className={classes.textFieldContainer}>
                  <img src={dApxImg} alt="" className={classes.textFieldImg}/>
                  <TextField
                    variant="outlined"
                    className={classes.TextFieldBorder}
                    value={penaltyFee}
                    onChange={(e) => penaltyFeeChange(e)}
                  />
                </div>
              </Grid>
      </Grid>

      <div className={classes.midSection}>
        <p> Mining Rate per Minute</p>
        <a className={classes.aTag} onClick={() => props.onAdd()}><b>Add New Transaction Range</b></a>
      </div>

      {/* Table Section */}
      <DataTable
				columns={columns}
				data={data}
				sortServer
				progressPending={updateLoading}
				progressComponent={
					<Loader
						appear={updateLoading}
						width="100"
					/>
				}
				persistTableHead
			/>
      {showButtons ? 
      <div className="footer">
        <Button
          disableElevation
          color="primary"
          variant="contained"
          className="submit-btn"
          type="submit"
          onClick={updatePledgeConfig}
          >
          <span className="text">Save</span>
        </Button>
        <Button
          disableElevation
          variant="contained"
          className="exit-btn"
          onClick={cancelChanged}
          >
          Cancel
        </Button>
      </div>
        : ''}
      <ConditionalModal
      title={delete_details.title}
      description="Are you sure you want to delete this Pledge Option?"
      open={delete_alert}
      onClose={onClose_delete_alert}
      onAccept={onAccept_delete}
      // onAccept={(row) => onAccept_delete(row)}
      onDenied={onDenied_delete}
      acceptBtn_label="Delete"
      />
      </form>
    </div>
    </>
  )
}

export default Pledging
