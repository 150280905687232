import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, makeStyles, Tooltip } from "@material-ui/core";
import dApxImg from "../../../../assets/images/currency/dappx.png";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import DataTable from "react-data-table-component";
import Loader from "../../../loader/Loader";
import { ConditionalModal } from "../../../alerts/Alerts";
import { deleteTicketSetup, listTicketsSetup } from "../../../../actions/TicketsSeupActions";
import { listTypes } from '../../../../actions/TypeActions'
import { DAPPX_API_BASE_URL } from "../../../../constant/config";
import './Setup.scss'

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    "& .myIcon": {
      paddingLeft: "8px",
      order: 999,
    },
  },
  textFieldContainer: {
    position: "relative",
    display: "inline-block",
  },
  textFieldImg: {
    position: "absolute",
    right: 10,
    top: 10,
    width: 20,
    height: 20,
  },
  primaryGrid: {
    margin: "8px",
  },
  TextFieldBorder: {
    [`& fieldset`]: {
      borderRadius: 20,
    },
    width: "300px",
  },
  midSection: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  aTag: {
    textDecoration: "none",
    color: "blue",
    cursor: "pointer",
  },
  ticketSetup: {
    marginBottom: "50px",
  },
  imageStyle: {
    width: '70px',
    height: '50px'
  }
})

const SetupDetails = (props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [delete_alert, set_delete_alert] = useState(false)
  const onClose_delete_alert = () => set_delete_alert(false)
  const [descriptionText, setDescriptionText] = useState('')
  const [delete_details, set_delete_details] = useState({
    id: "",
    name: "",
  })

  const setupList = useSelector( state => state.setupList)
  const ticketType = useSelector( state => state.ticketType)

  const { loading, types } = ticketType
  const { error, success, ticketsSetup } = setupList

  useEffect(() => {
    dispatch(listTypes())

    if(success) {
      if(ticketsSetup.tickets.length !== 0) {
        setDescriptionText(ticketsSetup.tickets[0].description)
      }
    }

  },[success])

  //Edit and Delete Buttons
  const format_action = (row) => {
    return (
      <div className="action-cell">
        <Button
          variant="contained"
          disableElevation
          color="primary"
          className="edit-btn"
          onClick={() => props.onEdit(row)}
        >
          Edit
        </Button>
        <Button
          variant="contained"
          disableElevation
          color="secondary"
          className="delete-btn"
          onClick={() => onOpen_delete_alert(row)}
          disabled={!ticketsSetup.tickets[0].can_be_deleted}
        >
          Delete
        </Button>
      </div>
    );
  };

  // table image
  const format_image = (row) => {
    let id = row._id;
    // const url = `https://dappx-adminapi-sat.dappstore.me/${ticketsSetup?.tickets[0].image}`
    const url = `${DAPPX_API_BASE_URL}/${ticketsSetup?.tickets[0].image}`
    return (
      <>
        <img key={id} src={url} alt="" className={classes.imageStyle}/>
      </>
    );
  };

  const format_description = (row) => {
    return (
      <>
        <Tooltip title={
          <h1 className="tooltip-text-style">{descriptionText}</h1>
          }
        >
        <CheckCircleOutlineIcon
            style={{ color: "green", marginLeft: "25px" }}
          />
        </Tooltip>
      </>
    )
  }

  const onOpen_delete_alert = (row) => {
    set_delete_alert(true);
    set_delete_details({
      // id: row._id,
      name: row.TicketName,
    })
  }
  const onAccept_delete = (row) => {
    set_delete_alert(false);
    dispatch(deleteTicketSetup(ticketsSetup.tickets[0]._id))
    dispatch(listTicketsSetup())
  }

  const onDenied_delete = () => {
    set_delete_alert(false);
    set_delete_details({
      id: "",
      title: "",
    })
  }

  const primaryColumns = [
    {
      name: "Ticket Name",
      selector: (row) => row.TicketName,
    },
    {
      name: "Type",
      selector: (row) => row.Type,
    },
    {
      name: "Description",
      cell: (row) => format_description(row),
    },
    {
      name: "Image",
      cell: (row) => format_image(row),
    },
    {
      width: "202px",
      cell: (row) => format_action(row),
      button: true,
    },
  ]

  const primaryData = [
    {
      id: ticketsSetup.tickets[0] ? ticketsSetup.tickets[0]._id : null,
      TicketName: ticketsSetup.tickets[0] ? ticketsSetup.tickets[0].name : null,
      Type: types[0]?.name,
      Description: ticketsSetup.tickets[0] ? ticketsSetup.tickets[0].description : null,
      image: ticketsSetup.tickets[0] ? ticketsSetup.tickets[0].image : null,
    },
  ]

  return (
    <>
      <div className="content">
        <div className={classes.midSection}>
          <p> Ticket Setup</p>
          {!props.dataLimit ?
          <a className={classes.aTag} onClick={() => props.onAdd()}>
            <b>Add Entry</b>
          </a>
          :
          ""
          }   
        </div>
        {/* Table Section */}
        <div className={classes.ticketSetup}>
          {/* <Loader appear width={50}/> */}
          {!loading && success ?
          <DataTable
            columns={primaryColumns}
            data={ticketsSetup.tickets[0] ? primaryData : []}
            sortServer
            progressComponent={<Loader appear={loading} width="100" />}
            persistTableHead
          />
          :
          <Loader  appear width={50}/>
          }
        </div>

        <ConditionalModal
          title={delete_details.name}
          description="Are you sure you want to delete this Ticket?"
          open={delete_alert}
          onClose={onClose_delete_alert}
          onAccept={onAccept_delete}
          onDenied={onDenied_delete}
          acceptBtn_label="Delete"
        />
      </div>
    </>
  );
};

export default SetupDetails
