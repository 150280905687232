import React, {useState, useEffect} from 'react';
import {Button, Modal, FormControl, TextField, makeStyles} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TableWrapper, Search, Pagination, RecordsPerPage, Filter } from '../../../components/table-elements';
import Loader from '../../loader/Loader';
import DataTable from 'react-data-table-component';
import { DAPPX_API_BASE_URL } from '../../../constant/config'
import moment from 'moment';
import axios from 'axios'
import '../Settings.scss'

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '400px',
    bgcolor: 'background.paper',
    border: '1px solid grey',
    borderRadius: '20px',
    boxShadow: 24,
    p: 4,
    backgroundColor: 'white',
    height: '500px',
    overflowY: 'scroll',
    [`& .paper`]: {
      minHeight: 'unset'
    },
    [`& .paper-wrapper`]: {
      minHeight: 'unset'
    },
    [`& .table-wrapper`]: {
      marginTop: '0',
      [`& .table-container`]: {
        paddingTop: '0'
      },
      [`& .header`]: {
        marginBottom: 0,
        padding: '16px',
        justifyContent: 'space-between'
      },
    },
  },
  textFieldContainer: {
    position: 'relative',
    display: 'inline-block'
  },
  textFieldImg: {
    position: 'absolute',
    right: 10,
    top: 17,
    width: 20,
    height: 20
  },
  TextFieldBorder: {
    [`& fieldset`]: {
      borderRadius: 20,
      width: 690,
      height: 40,
      paddingBottom: 7,
      marginTop: '7px',
      display: 'flex',
      alignItems: 'flex-start',
    },
  width: '700px',
  }
});

const HotWalletHistory= (props) =>{
  const classes = useStyles()
  const token = localStorage.getItem('token')

  // table 
	const [totalRows, setTotalRows] = useState(0);
	const [pageNumber, setPageNumber] = useState(1);
	const [recordsPerPage, setRecordsPerPage] = useState(15); // change to actual default value
  const [aceLogs, setAceLogs] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {

    if(props.withdrawAce || props.withdrawDappx) {
      const apiParams = props.withdrawAce ? '/ace-withdrawal' : '/dappx-withdrawal'
      setLoading(true)
      axios.get(`${DAPPX_API_BASE_URL + apiParams}/hot-wallet-history`, {headers: { 'auth-token': token}})
      .then((res) => {
        setAceLogs(res.data.logs)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
    }
  },[props.modal])

  const onClose = () => {
    props.onClose()
  }

  const format_date = (row) => {
    let date = row.date_time
		let formated_date = moment(date).format('MMM DD, YYYY, HH:MM:SS');
		return <>{formated_date}</>
	}

  const format_url = (row) => {
    const walletObject = JSON.parse(row.action)
    const walletList = walletObject.wallet

    return walletList.map((wallet) => {
      
      let frontChars = Math.ceil(12/2)
      let backChars = Math.floor(8/2)
      const final = wallet.substr(0, frontChars) + '...' + wallet.substr(wallet.length - backChars);
      return <p>{final}</p>
    })
	}

  const format_action = (row) => {
    return <p>{row.action.includes('add') ? 'Add' : 'Remove'}</p>
	}

  const columns = [
    {
      name: 'Date',
      selector: row => format_date(row),
  },
  {
      name: 'Action',
      selector: row => row.action,
  },
  {
      name: 'Change from',
      selector: row => row.from_address,
  },
  {
      name: 'Change to',
      selector: row => row.to_address,
  },
  ];

  const withdrawColumns = [
    {
        name: 'Date',
        selector: row => format_date(row),
    },
    {
        name: 'Action',
        selector: row => format_action(row),
    },
    {
        name: 'Wallet',
        selector:  row => format_url(row),
        width: '30%',
    },
];

const minRates = [
  {
    _id: 1,
    date_created: props.withdrawAce ? aceLogs.createdAt : '',
    action: props.withdrawAce ? aceLogs.createdAt : '',
    from_address: props.withdrawAce ? aceLogs.createdAt : '',
    to_address: 'New Address 2'
  },
]

const data = aceLogs.map(item => (
  {
    id: item._id,
    date_created:item.createdAt,
    action:item.log_details,
    wallet:item.from_address,
  } 
));

  //End

  return (
    <>
      <Modal
        open={props.modal}
      >
          <div className={classes.root}>
            <div className="paper-wrapper">
              <div className="paper">

              <TableWrapper
                header={
                  <>
                    Hot Wallet History
                    <Button
                      disableElevation
                      variant="text"
                      className="exit-btn"
                      onClick={onClose}
                      >
                      <CloseIcon />
                    </Button>
                  </>
                }
                body={
                  <DataTable
                    columns={props.withdrawAce || props.withdrawDappx ? withdrawColumns : columns}
                    data={data}
                    sortServer
                    progressPending={loading}
                    progressComponent={
                    	<Loader
                    		appear={loading}
                    		width="100"
                    	/>
                    }
                    persistTableHead
                  />
                }
                footer={
                  totalRows > 5 && (
                    <Pagination
                      pageNumber={pageNumber}
                      recordsPerPage={recordsPerPage}
                      totalRows={totalRows}
                      setPageNumber={setPageNumber}
                      numberedPagesCount={5}
                    />
                  )
                }
              />
              </div>
            </div>
          </div>
      </Modal>
    </>
  );
}

export default HotWalletHistory