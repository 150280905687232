export const PRODUCTION_API = 'https://apiadmin.dappstore.me';
export const UAT_API = 'https://apiadmin-uat.dappstore.me';
export const SAT_API = 'https://apiadmin-sat.dappstore.me';

export const DAPPX_PRODUCTION_API = 'https://dappx-adminapi.dappstore.me';
export const DAPPX_UAT_API = 'https://dappx-adminapi-uat.dappstore.me';
export const DAPPX_SAT_API = 'https://dappx-adminapi-sat.dappstore.me';

export const EXCHANGE_CONTRACT_ADDRESS = '0x43f58019eef6ba98dc1f6f5970ac30d494ada09f'
export const ACE_WITHDRAW_CONTRACT_SAT = '0x0Cc84772D14096285c36A3ac183C2a5CF87190fD'
export const ACE_TOKEN_CONTRACT_SAT = '0x9c77393974819662a9d8A698f2d5f6d969B9f638'
export const DAPPX_TOKEN_CONTRACT_SAT = '0x1221947903ece9191e1ecf5c694d09444a495568'
export const DAPPXـTOKEN_CONTRACT_MAINNET = '0x00d8318E44780EdEEFcF3020A5448F636788883C';
export const ACE_WITHDRAW_CONTRACT_UAT = '0x313d516a370bf0240913a370b94a8640ce47500e'
export const ACE_TOKEN_CONTRACT_MAINNET = '0xeC5483804e637D45cDe22Fa0869656B64b5AB1ab'
export const ACE_WITHDRAW_CONTRACT_PROD = '0xf3f6450D751DEa9bE16E7079F587D30803f72F44'

export const IS_UAT = window.location.href.includes('-uat');
export const IS_SAT = window.location.href.includes('localhost') || window.location.href.includes('-sat');

export const API_BASE_URL = `${IS_SAT ? SAT_API : (IS_UAT ? UAT_API : PRODUCTION_API)}`;
export const DAPPX_API_BASE_URL = `${IS_SAT ? DAPPX_SAT_API : (IS_UAT ? DAPPX_UAT_API : DAPPX_PRODUCTION_API)}`;
export const WITHDRAW_ACE_CONTRACT_ADDRESS = `${IS_SAT ? ACE_WITHDRAW_CONTRACT_SAT : (IS_UAT ? ACE_WITHDRAW_CONTRACT_UAT : ACE_WITHDRAW_CONTRACT_PROD)}`;
export const ACENT_TOKEN_CONTRACT_ADDRESS = `${IS_SAT ? ACE_TOKEN_CONTRACT_SAT : (IS_UAT ? ACE_TOKEN_CONTRACT_MAINNET : ACE_TOKEN_CONTRACT_MAINNET)}`;
export const DAPPX_TOKEN_CONTRACT_ADDRESS = `${IS_SAT ? DAPPX_TOKEN_CONTRACT_SAT : (IS_UAT ? DAPPXـTOKEN_CONTRACT_MAINNET : DAPPXـTOKEN_CONTRACT_MAINNET)}`;
