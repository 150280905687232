import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TableWrapper from '../../table-elements/TableWrapper'
import PageTitle from '../../page-title/PageTitle';
import { Grid } from '@material-ui/core';
import AcePledging from './Ace-Pledging'
import { getPledgesConfig } from '../../../actions/PledgesActions'
import SettingsModal from './SettingsModal';
import Loader from '../../loader/Loader';
import { getAcePledgeSeasons } from '../../../actions/PledgeSeason'

const ACEPledgingPage = () => {
  const dispatch = useDispatch()
  const [ openModal, setOpenModal] = useState(false)
  const [modalData, setModalData] = useState({})
  const [title, setTitle] = useState('')
  const [addModal, setAddModal] = useState(false)

  const getSeasons = useSelector( state => state.getSeasons)
  const addSeason = useSelector( state => state.addSeason)
  const updateSeason = useSelector( state => state.updateSeason)
  const deleteSeason = useSelector( state => state.deleteSeason)

  const { loading, getSeasonsSuccess } = getSeasons
  const { addLoading, addSeasonSuccess } = addSeason
  const { updateLoading, updateSeasonSuccess } = updateSeason
  const { deleteLoading, deleteSeasonSuccess } = deleteSeason

  useEffect(() => {
    dispatch(getAcePledgeSeasons())
  }, [updateLoading, addLoading, deleteLoading])

  const deleteModal = (row) => {
    console.log('Delete Button',row)
  }

  const editModal = (row) => {
    setOpenModal(true)
    setModalData(row)
    setTitle('Edit Season')
  }

  const closeModal =() => {
    setOpenModal(false)
  }

  const addPledge = () => {
    setOpenModal(true)
    setTitle('Add New Season')
    setAddModal(!addModal)
  }

  const onModalSubmit = () => {
    // dispatch(getAcePledgeSeasons())
    setOpenModal(false)
  }

  return (
    <>
      <PageTitle title="Pledging" hideHistoryBtn hideAddBtn />
      <div className="content">
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<TableWrapper
          body={
            loading || updateLoading || addLoading || deleteLoading || !getSeasonsSuccess ?
            <Loader appear width={50}/>
            :
            <AcePledging
             onDelete={deleteModal}
             onEdit={editModal}
             onAdd={addPledge}
             />
          }
						/>
					</Grid>
				</Grid>
			</div>

      <SettingsModal
        openEditModal={openModal}
        onClose={closeModal}
        modalTitle={title}
        onSubmit={onModalSubmit}
        data={modalData}
        addModal={addModal}
      />
    </>
  )
}

export default ACEPledgingPage
