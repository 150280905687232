import axios from "axios";
import Web3 from "web3";

// const DAPPX_ADMIN_API = 'http://51.222.12.10:4000/'
// We conver Data from Wei

const IS_UAT = window.location.href.includes('-uat');
const IS_SAT = window.location.href.includes('localhost') || window.location.href.includes('-sat');
const token = localStorage.getItem('token')

const DAPPX_ADMIN_API = `${IS_SAT ? "https://dappx-adminapi-sat.dappstore.me/" : (IS_UAT ? "https://dappx-adminapi-uat.dappstore.me/" : "https://dappx-adminapi.dappstore.me/")}`;

export const fetchEdaoMining = () => {
  return async (dispatch) => {
    dispatch({ type: "EDAO_MINING_LIST_REQUEST" });
    
    const res = await axios.get(`${DAPPX_ADMIN_API}/edao-gov-pledging/mining-rates`, { headers: { 'auth-token': localStorage.getItem('token')} })

    if (res.error) {
      dispatch({
        type: "EDAO_MINING_FAIL",
        payload: res.data,
      });
    } else {
      dispatch({
        type: "EDAO_MINING_SUCCESS",
        payload: res.data,
      });
    }
  };
};

export const addEdaoMining = (data) => {
  return async (dispatch) => {
    dispatch({ type: "EDAO_MINING_ADD_REQUEST" });

    const res = await axios.post(`${DAPPX_ADMIN_API}edao-gov-pledging/mining-rates`, data, { headers: { 'auth-token': token } })
    if (res.error) {
      dispatch({
        type: "EDAO_MINING_ADD_FAIL",
        payload: res.data,
      });
    } else {
      dispatch({
        type: "EDAO_MINING_ADD_SUCCESS",
        payload: res.data,
      });
    }
  };
};


export const updateEdaoMiningRate = (data, id) => {
  console.log('SUBMIT ACRIONS', data)
  console.log('SUBMIT ACRIONS id', id)
  return async (dispatch) => {
    dispatch({ type: "EDAO_MINING_UPDATE_REQUEST" });

    const res = await axios.put(`${DAPPX_ADMIN_API}edao-gov-pledging/mining-rates/${id}`, data, { headers: { 'auth-token': token } })
    if (res.error) {
      dispatch({
        type: "EDAO_MINING_UPDATE_FAIL",
        payload: res.data,
      });
    } else {
      dispatch({
        type: "EDAO_MINING_UPDATE_SUCCESS",
        payload: res.data,
      });
    }
  };
};

export const deleteEdaoMiningRate = (id) => {
  return async (dispatch) => {
    dispatch({ type: "EDAO_MINING_DELETE_REQUEST" });

    const res = await axios.delete(
      `${DAPPX_ADMIN_API}edao-gov-pledging/mining-rates/${id}`,
      {
        headers: {
          'auth-token': token
        }
      }
    )

    if (res.error) {
      dispatch({
        type: "EDAO_MINING_DELETE_FAIL",
        payload: res.data,
      });
    } else {
      dispatch({
        type: "EDAO_MINING_DELETE_SUCCESS",
        payload: res.data,
      });
    }
  };
};