import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Button, Switch, FormGroup, FormControlLabel, Popover } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import Loader from '../../../components/loader/Loader';
import { ConditionalModal, SuccessModal, ErrorModal } from '../../../components/alerts/Alerts';
import { DAPPX_API_BASE_URL } from '../../../constant/config';
import { AuthContext } from '../../../context/AuthContext';
import ethereum from '../../../assets/images/currency/ethereum.png';


import moment from 'moment';

const Table = ({ data, onEdit, refetch, onPreview, onSort }) => {
	const { authState } = useContext(AuthContext);

	const [success_alert, set_success_alert] = useState(false);
	const onOpen_success_alert = () => {
		set_success_alert(true)
	}
	const onClose_success_alert = () => {
		set_success_alert(false)
	}

	const [error_alert, set_error_alert] = useState(false);
	const onOpen_error_alert = () => {
		set_error_alert(true)
	}
	const onClose_error_alert = () => {
		set_error_alert(false)
	}

	const [loading, set_loading] = useState(true);
	const stop_loading = () => {
		setTimeout(() => {
			set_loading(false)
		}, 1500)
	}

	useEffect(() => {
		if (data.length >= 0) {
			stop_loading()
		}
	}, [data]);

	// Delete
	const [delete_alert, set_delete_alert] = useState(false);
	const [delete_details, set_delete_setails] = useState({
		id: '',
		title: ''
	})
	const onClose_delete_alert = () => set_delete_alert(false);
	const onOpen_delete_alert = (row) => {
		set_delete_alert(true)
		set_delete_setails({
			id: row._id,
			title: row.name
		})
	};
	const onAccept_delete = () => {
		set_delete_alert(false)

		axios
			.delete(`${DAPPX_API_BASE_URL}/package/${delete_details.id}`, { headers: { 'auth-token': authState.token } })
			.then((res) => {
				console.log('success delete: ', res)
				onOpen_success_alert()
				refetch()
			})
			.catch((res) => {
				console.log('error delete: ', res)
				onOpen_error_alert()
			});
	}

	const onDenied_delete = () => {
		set_delete_alert(false)
		set_delete_setails({
			id: '',
			title: ''
		})
	}

	const format_action = (row) => {
		let isDisabled = row.isPublished || row.isBought;
		// console.log('isPublished? ', row.isPublished, ', isBought? ', row.isBought, row.package)
		return (
			<div className="action-cell">
				<Button 
					variant="contained" 
					disableElevation 
					color="primary" 
					className="edit-btn" 
					onClick={() => onEdit(row)}>
						Edit
					</Button>
				<Button 
					variant="contained" 
					disableElevation color="secondary" 
					className="delete-btn" 
					disabled={isDisabled ? true : false}
					onClick={() => onOpen_delete_alert(row)}>
						Delete
				</Button>
			</div>
		)
	}

	const [anchorEl, setAnchorEl] = useState(null);
	const [ongoing_publish, set_ongoing] = useState();
	const [disableSwitch, setDisableSwitch] = useState(false);
	const on_change_publish = (id, condition, event) => {
		popoverOpen(event, id)
		let formData = new FormData();
    formData.append('isPublished', !condition)
    formData.append('id', id)

		axios
			.put(`${DAPPX_API_BASE_URL}/package/publish`, formData,
				{ headers: {
					'auth-token': authState.token,
					'Content-Type': 'multipart/form-data'
				},
			}).then(async (res) => {
				console.log('success publish: ', res)
				await refetch()
				setDisableSwitch(false)
				popoverClose()
			})
			.catch((res) => {
				console.log('error publish: ', res)
				popoverClose()
				onOpen_error_alert()
			});
	};
	const popoverOpen = (event, id) => {
		set_ongoing(id)
		setDisableSwitch(true)
		setAnchorEl(event.currentTarget);
	};
	const popoverClose = () => {
		set_ongoing()
		setDisableSwitch(false)
		setAnchorEl(null);
	};
	const open = Boolean(anchorEl);
	const format_publish = (row) => {
		return (
			<div className="publish-cell">
				<FormGroup>
					<FormControlLabel
						control={
							<Switch
								focusVisibleClassName="focus-visible"
								disableRipple
								classes={{
									root: "root-switch",
									switchBase: "switch-base",
									thumb: "thumb",
									track: "track",
									checked: "checked",
								}}
								disabled={disableSwitch}
								checked={row.isPublished}
								onChange={(e) => on_change_publish(row._id, row.isPublished, e)}
							/>
						}
					/>
				</FormGroup>
			</div>
		)
	}

	const format_url = (row) => {
		let image = row.image;
		let url = image.length > 0 ? row.image[0].path : ''
		return <div className="image-url ellipsis" onClick={() => onPreview(row)}>{url ? url : ''}</div>;
	}

	const format_decimal = (row) => {
		let data = row.amount;
		// console.log('row.amount: ', row.amount, row._id)
		return <>
			{data.length > 0 && data.map((i) => {
			// console.log('amount: ', typeof i.amount )
				return (
					parseFloat(i.amount).toFixed(6) + ' ' + i.currency
				)
			})}
		</>
	}

	const format_date = (date) => {
		let formated_date = moment(date).format('MMM DD, YYYY');
		return <>{formated_date}</>
	}

	const format_currency = (row) => {
		let id = row._id;
		let data = row.amount;
		return <>
			{data.length > 0 && data.map((i) => (
				i.currency && <img key={id} src={i.currency === 'ETH' && ethereum} alt={i.currency}/>
			))}
		</>
	}

	// const customColumnSort = (col) => {
	// 	onSort(col)
	// 	console.log(`You click ${col}`);
	// };

	const columns = [
		{
			name: 'Application',
			selector: row => row.name,
			cell: row => row.name,
			minWidth: '160px',
			sortable: true,
      sortField: 'name'
		},
		{
			name: 'Package',
			selector: row => row.package,
			cell: row => row.package,
			minWidth: '160px',
			sortable: true,
      sortField: 'package'
		},
		{
			name: 'Image',
			cell: row => format_url(row),
			maxWidth: '250px'
		},
		{
			name: 'Currency',
			cell: row => format_currency(row),
			width: '111px',
		},
		{
			name: 'Price',
			selector: row => row.amount.amount,
			cell: row => format_decimal(row),
			sortable: true,
      sortField: 'price'
		},
		{
			name: 'Date Created',
			selector: row => row.createdAt,
			cell: row => format_date(row.createdAt),
			sortable: true,
      sortField: 'createdAt'
		},
		{
			name: 'Publish',
			center: true,
			cell: row => format_publish(row),
			button: true
		},
		{
			width: '202px',
			cell: row => format_action(row),
			button: true
		},
	];

	return (
		<>
			<DataTable
				columns={columns}
				data={data}
				sortServer
				onSort={onSort}
				progressPending={loading}
				progressComponent={
					<Loader
						appear={loading}
						width="100"
					/>
				}
				persistTableHead
			/>

			<ConditionalModal
				title={delete_details.title}
				description="Are you sure you want to delete this?"
				open={delete_alert}
				onClose={onClose_delete_alert}
				onAccept={onAccept_delete}
				onDenied={onDenied_delete}
			/>

			<SuccessModal
				title="Delete Successful"
				open={success_alert}
				onClose={onClose_success_alert}
			/>

			<ErrorModal
				description="Something went wrong."
				open={error_alert}
				btnLabel={'OK'}
				onClose={onClose_error_alert}
			/>

			<Popover
				className="pop-loader"
				id={ongoing_publish}
				open={open}
				anchorEl={anchorEl}
				onClose={popoverClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<Loader
					appear={true}
					width="20"
					thick
				/>
			</Popover>
		</>
	);
}

export default Table;