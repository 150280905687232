import React, { createContext, useReducer } from 'react';
import AuthReducer from './reducers/AuthReducer';

export const AuthContext = createContext();

const initialState = {
    // isAuthenticated: true,
    isAuthenticated: localStorage.getItem('token') ? true : false,
    token: localStorage.getItem('token'),
    expires_at: localStorage.getItem('expires_at'),
    user: {
      // full_name: localStorage.getItem('full_name'),
      email: localStorage.getItem('email'),
      role: localStorage.getItem('role')
    }
}

const AuthProvider = ({children}) => {
  const [state, dispatch] = useReducer(AuthReducer, initialState)

  return(
    <AuthContext.Provider value={{
        authState: state,
        dispatch
    }}>
    {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;