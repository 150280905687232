import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import Loader from '../../../components/loader/Loader';

const Table = ({ data }) => {
	const [loading, set_loading] = useState(true);
	const stop_loading = () => {
		setTimeout(()=>  {
			set_loading(false)
		}, 1500)
	}

	useEffect(() => {
    if(data.length >= 0){
			stop_loading()
		}
  }, [data]);

	const walletAddressFormatter = (walletAddress) => {
		return <div className="wallet-address">{walletAddress ? `${walletAddress.substring(0,6)}....${walletAddress.substring(walletAddress.length-4)}` : ''}</div>;
	}

	const columns = [
		{
			name: 'User',
			cell: row => walletAddressFormatter(row.public_address)
		},
		{
			name: 'Email',
			cell: row => row.email_id
		},
		{
			name: 'Referrer',
			cell: row => walletAddressFormatter(row.referral_by_public_address)
		},
	];

	return (
		<>
			<DataTable
				columns={columns}
				data={data}

				progressPending={loading}
      	progressComponent={
					<Loader 
						appear={loading} 
						width="100" 
					/>
				}
				persistTableHead
			/>
		</>
	);
}

export default Table;