export const getMiningssReducer = (state = { minings: {} }, action) => {
  switch (action.type) {
    case "PLEDGE_MINING_LIST_REQUEST":
      return { loading: true, minings: {} };
    case "PLEDGE_MINING_SUCCESS":
      return { loading: false, getMiningsSuccess: true, minings: action.payload };
    case "PLEDGE_MINING_FAIL":
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateMiningssReducer = (state = {}, action) => {
  switch (action.type) {
    case "PLEDGE_MINING_UPDATE_REQUEST":
      return { updateLoading: true, minings: {} };
    case "PLEDGE_MINING_UPDATE_SUCCESS":
      return { updateLoading: false, updateMiningsSuccess: true, minings: action.payload };
    case "PLEDGE_MINING_UPDATE_FAIL":
      return { updateLoading: false, error: action.payload };
    default:
      return state;
  }
};