import Api from "../api";

export const listTypes = () => {
  return async (dispatch) => {
    dispatch({ type: "TYPE_LIST_REQUEST" });
    const res = await Api.typeStup.getTypeSetup();
    if (res.error) {
      dispatch({
        type: "TYPE_LIST_FAIL",
        payload: res.data.error,
      });
    } else {
      dispatch({
        type: "TYPE_LIST_SUCCESS",
        payload: res.data.types,
      });
    }
  };
};

export const updateTypes = (data, id) => {
  return async (dispatch) => {
    dispatch({ type: "TYPE_UPDATE_REQUEST" });

    const res = await Api.typeStup.updateTypeSetup(data, id);
    if (res.error) {
      dispatch({
        type: "TYPE_UPDATE_FAIL",
        payload: res.data,
      });
    } else {
      dispatch({
        type: "TYPE_UPDATE_SUCCESS",
        payload: res.data,
      });
    }
  };
};

export const deleteTypes = (data) => {
  return async (dispatch) => {
    dispatch({ type: "TYPE_DELETE_REQUEST" });
    const res = await Api.typeStup.deleteTypeSetup(data);
    
    if (res.error) {
      dispatch({
        type: "TYPE_DELETE_FAIL",
        payload: res.data,
      });
    } else {
      dispatch({
        type: "TYPE_DELETE_SUCCESS",
        payload: res.data,
      });
    }
  };
};

export const createTypes = (data) => {
  return async (dispatch) => {
    dispatch({ type: "TYPE_CREATE_REQUEST" });
    const res = await Api.typeStup.createTypeSetup(data);
    if (res.error) {
      dispatch({
        type: "TYPE_CREATE_FAIL",
        payload: res.data,
      });
    } else {
      dispatch({
        type: "TYPE_CREATE_SUCCESS",
        payload: res.data,
      });
    }
  };
};
