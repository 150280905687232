export const getSeasonsReducer = (state = { seasons: {} }, action) => {
  switch (action.type) {
    case "PLEDGE_SEASON_REQUEST":
      return { loading: true, seasons: {} };
    case "PLEDGE_SEASON_SUCCESS":
      return { loading: false, getSeasonsSuccess: true, seasons: action.payload };
    case "PLEDGE_SEASON_FAIL":
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const addSeasonReducer = (state = {}, action) => {
  switch (action.type) {
    case "ADD_SEASON_REQUEST":
      return { addLoading: true, seasons: {} };
    case "ADD_SEASON_SUCCESS":
      return { addLoading: false, addSeasonSuccess: true, seasons: action.payload };
    case "ADD_SEASON_FAIL":
      return { addLoading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateSeasonReducer = (state = {}, action) => {
  switch (action.type) {
    case "UPDATE_SEASON_REQUEST":
      return { updateLoading: true, season: {} };
    case "UPDATE_SEASON_SUCCESS":
      return { updateLoading: false, updateSeasonSuccess: true, season: action.payload };
    case "UPDATE_SEASON_FAIL":
      return { updateLoading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteSeasonReducer = (state = {}, action) => {
  switch (action.type) {
    case "DELETE_SEASON_REQUEST":
      return { deleteLoading: true };
    case "DELETE_SEASON_SUCCESS":
      return { deleteLoading: false, deleteSeasonSuccess: true };
    case "DELETE_SEASON_FAIL":
      return { deleteLoading: false, error: action.payload };
    default:
      return state;
  }
};