import React, {useState} from 'react'
import { Button, makeStyles } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import Loader from '../../loader/Loader';
import { formatDate, formatDateYearly } from '../../../utils';
import './DappxPaypal.scss'

const useStyles = makeStyles({
  midSection: {
    width: '100%',
    display: 'flex',
    justifyContent:'space-between',
    marginTop: '20px',
  },
  aTag: {
    textDecoration: 'none',
    color: 'blue',
    cursor: 'pointer'
  },
  completedSn: {
    fontWeight: 'bold',
    color: 'green',
    cursor: 'default'
  },
  onGoingSn: {
    fontWeight: 'bold',
    color: 'orange',
    cursor: 'default'
  },
  tooltipContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  pledgeCollectedStyle:{
    display: 'flex',
    alignItems: 'center'
  },
  currencyIconStyle: {
    width: '20px',
    height: '20px',
    marginLeft: '5px'
  }
});


const DappxTable = (props, {loader}) => {
  const classes = useStyles()
  const [delete_alert, set_delete_alert] = useState(false);
	const [delete_details, set_delete_details] = useState({
		id: '',
		title: ''
	})
  const [showButtons, setShowButtons] = useState(false)

  //Edit and Delete Buttons
  const format_action = (row) => {
    return (
      <div className="action-cell">
        <Button
          variant="contained"
          disableElevation
          color="primary"
          className="edit-btn"
          onClick={() => props.onEdit(row)}
          // disabled={!disabledButton}
        >
          Edit
        </Button>
        <Button
          variant="contained"
          disableElevation
          color="secondary"
          className="delete-btn"
          onClick={() => props.onDelete(row)}
        >
          Delete
        </Button>
      </div>
    );
  };

  const format_url = (row) => {
		let image = row.image;
		let url = image?.length > 0 ? row.image[0].path : ''
    const removeslashes = url.split('/')
    const removeNumbers = removeslashes[2].replace(/[0-9]/g, '');
    const str = removeNumbers.slice(0, -4);
		return <div className="image-url ellipsis" onClick={() => props.onPreview(row)}>{url ? removeNumbers : ''}</div>;
	}
 

  const onClose_delete_alert = () => set_delete_alert(false);
	const onOpen_delete_alert = (row) => {
		set_delete_alert(true)
		set_delete_details({
			id: row.id,
			title: row.name
		})
	};

  const onDenied_delete = () => {
		set_delete_alert(false)
		set_delete_details({
			id: '',
			title: ''
		})
	}

  const cancelChanged =() =>{
    setShowButtons(false)
  }

  const columns = [
    {
      name: 'Name',
      selector: row => row.package_name,
      width: "9%",
    },
    {
      name: 'Value',
      selector: row => row.token_count,
      width: "6.5%",
    },
    {
      name: 'Image URL',
      cell: row => format_url(row),
      width: "9%",
    },
    {
      name: 'Price',
      selector: row => `$ ${row.price.toFixed(2)}`,
      width: "9%",
      id: 'fees'
    },
    {
      name: 'Fees ($)',
      selector: row => `${row.is_fees_percent === 'True' ? `-` : `$${row.fees}` }`,
      width: "9%",
      id: 'fees'
    },
    {
      name: 'Fees (%)',
      cell: row => `${row.is_fees_percent === 'True' ? `%${row.fees}` : `-` }`,
      width: '9%',
      id: 'fees'
      // button: true
    },
    {
      name: 'Markup ($)',
      cell: row => `${row.is_markup_percent === 'True' ? `-` : `$ ${row.markup}` }`,
      width: "9%",
      id: 'fees'
    },
    {
      width: '9%',
			name: 'Markup (%)',
      cell: row => `${row.is_markup_percent === 'True' ? `%${row.markup}` : `-` }`,
      id: 'fees'
			// button: true
		},
    {
      id: 'rate',
      name: 'Rate (USD)',
      cell: row => `$ ${row.rate}`,
      width: '9%',
		},
    {
      name: 'Timestamp',
      selector: row => formatDateYearly(row.start_date),
      width: "9%",
      id: 'timestamp',
    },
    {
      width: "202px",
      cell: (row) => format_action(row),
    },
];

const data = props.payPalPackages.map(item => (
  {
    id: item._id,
    package_name: item.package_name,
    start_date: item.createdAt,
    price: item.price,
    image: item.image,
    rate: item.rate,
    token_count: item.token_count,
    fees: item.fees,
    markup: item.markup,
    is_markup_percent: item.is_markup_percent ? 'True' : 'False',
    is_fees_percent: item.is_fees_percent ? 'True' : 'False',
    notes: item.notes
  }
));

  return (
    <>
    <div className="content">
    <form>
      <div className={classes.midSection}>
        <p>DAPPX</p>
        <a className={classes.aTag} onClick={() => props.onAdd()}><b>Add New Product</b></a>
      </div>

      {/* Table Section */}
      <DataTable
				columns={columns}
				data={data}
				sortServer
				progressPending={loader}
				progressComponent={
					<Loader
						appear={loader}
						width="100"
					/>
				}
				persistTableHead
			/>
      {showButtons ? 
      <div className="footer">
        <Button
          disableElevation
          color="primary"
          variant="contained"
          className="submit-btn"
          type="submit"
          // onClick={updatePledgeConfig}
          >
          <span className="text">Save</span>
        </Button>
        <Button
          disableElevation
          variant="contained"
          className="exit-btn"
          onClick={cancelChanged}
          >
          Cancel
        </Button>
      </div>
        : ''}
      </form>
    </div>
    </>
  )
}

export default DappxTable
