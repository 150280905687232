import React, { useState, useEffect, useContext, useRef } from 'react';
import { Grid } from '@material-ui/core';
import PageTitle from '../../../components/page-title/PageTitle';
import { TableWrapper, Search, Pagination, RecordsPerPage, Filter } from '../../../components/table-elements';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { DAPPX_API_BASE_URL } from '../../../constant/config';
import { AuthContext } from '../../../context/AuthContext';
import PackageSettingsTable from './Table';
import ModalAddPackage from './ModalPackage';
import ModalPreviewImage from './ModalPreviewImage';

const PackageSettings = () => {
	const { authState, dispatch } = useContext(AuthContext);
  let history = useHistory();

	const [filterData, setFilterData] = useState([])
	const [packageData, setPackageData] = useState([]);
	const [totalRows, setTotalRows] = useState(0);
	const [pageNumber, setPageNumber] = useState(1);
	const [recordsPerPage, setRecordsPerPage] = useState(15); // change to actual default value
	// SEARCH
	const [searchSt, setSearchSt] = useState('');
	// FILTER 
	const [check_val, set_check_val] = useState([]);
	const [fromDate, setFromDate] = useState('');
	const [toDate, setToDate] = useState('');
	let filter_value_applied = [{
		check_val: check_val,
		fromDate: fromDate,
		toDate: toDate,
	}]
	let isFilterEmpty= fromDate || toDate || check_val.length > 0;
	// SORT
	const [sort, setSort] = useState('createdAt');
	const [sortOrder, set_sortOrder] = useState(false);

	const check_if_bought = () => {
		let checker_api = `${DAPPX_API_BASE_URL}/package/order`;

		axios.get(checker_api, { headers: { 'auth-token': authState.token }})
			.then(result => {
				// console.log('check_if_bought ---> ', result);
				console.log('checking if bought...')
			})
			.catch(error => {
				console.log('check_if_bought ---> ', error)
			});
	}
	

	const fetchPackage = () => {
		check_if_bought()
		let getPackage_ApiUrl = `${DAPPX_API_BASE_URL}/package/list?offset=${((pageNumber - 1) * recordsPerPage)}&limit=${recordsPerPage}&sort=${sort}&orderBy=${sortOrder}`;

		axios.get(getPackage_ApiUrl, { headers: { 'auth-token': authState.token }})
			.then(result => {
				setPackageData(result.data.results.data.package);
				setFilterData(result.data.results.data.list)
				setTotalRows(result.data.results.data.total_rows);
			})
			.catch(error => {
				console.log(error);
				if(error.response.data.error === "Unauthorized Request"){
					dispatch({
						type: "LOGOUT"
					});
					history.push('/login');
				}
			});
	}

	const searchFetchPackage = () => {
		check_if_bought()
		let getPackage_ApiUrl = `${DAPPX_API_BASE_URL}/package/list?offset=${((pageNumber - 1) * recordsPerPage)}&limit=${recordsPerPage}&search=${searchSt}`;

		axios.get(getPackage_ApiUrl, { headers: { 'auth-token': authState.token } })
			.then(result => {
				setPackageData(result.data.results.data.package);
				setTotalRows(result.data.results.data.total_rows);
			})
			.catch(error => {
				console.log(error);
			});
	}

	const filterFetchPackage = () => {
		let base_url = `${DAPPX_API_BASE_URL}/package/list?offset=${((pageNumber - 1) * recordsPerPage)}&limit=${recordsPerPage}`;
		let search_params = searchSt ? `&search=${searchSt}` : '';
		let filter_params = `${check_val.length > 0 ? `&filter=${check_val}` : ''}`;
		let date_params = `${fromDate ? `&from=${fromDate}` : ''}${toDate ? `&to=${toDate}` : ''}`;
		let getPackage_ApiUrl = base_url + search_params + filter_params + date_params;

		axios.get(getPackage_ApiUrl, { headers: { 'auth-token': authState.token } })
			.then(result => {
				setPackageData(result.data.results.data.package);
				setTotalRows(result.data.results.data.total_rows);
			})
			.catch(error => {
				console.log(error);
			});
	}

	// Apply FILTER 
	const [fireSearch, setFireSearch] = useState(false);
	const onApply_Filter = (checkedList, fistDate, secondDate) => {
		setFireSearch(false)
		// remove search value
		searchRef.current.value = '';
		setSearchSt('') 

		set_check_val(checkedList)
		setFromDate(fistDate)
		setToDate(secondDate)
		setPageNumber(1)
	}

	const clearFilter = () => {
		setFireSearch(true)
		set_check_val([]);
		setFromDate(null);
		setToDate(null);
	}
	
	// Apply Search 
	const searchRef = useRef();
	const searchPackage = (e) => {
		e.preventDefault();
		// remove filter values
		clearFilter()

		let value = searchRef.current.value;
		setSearchSt(value)
		setPageNumber(1)
	}

	useEffect(() => {
		if (searchSt) {
			searchFetchPackage();
		} else if(isFilterEmpty){
			filterFetchPackage()
		} else {
			fetchPackage();
		} 
	}, [searchSt, fromDate, toDate, check_val, pageNumber, recordsPerPage]);


	// MODAL ================================================ 
	const [mode, setMode] = useState();
	const [edit_data, set_edit_data] = useState();
	const [openModal, setOpenModal] = useState(false);
	const onOpenModal = () => {
		setOpenModal(true)
	}
	const onCloseModal = () => {
		setOpenModal(false);
		set_edit_data()
		setMode();
	}

	// Add Package
	const onAddPackage = () => {
		setMode('add');
	}

	// Edit Package
	const onEditPackage = (row) => {
		set_edit_data(row)
		setMode('edit');
	}

	useEffect(() => {
		if(mode){
			onOpenModal()
		}
	}, [mode])

	// Preview Image 
	const [preview_data, set_preview_data] = useState(null);
	const [open_preview, set_open_preview] = useState(false);
	const on_open_preview = (row) => {
		set_open_preview(true)
		set_preview_data(row)
	}
	const on_close_preview = () => {
		set_open_preview(false);
		setTimeout(() => {
			set_preview_data(null)
		}, 400)
	}

	// Apply SORT
	const onSort = (column, sortDirection) => {
		setSort(column.sortField);
		set_sortOrder(sortDirection);
		// remove search value
		searchRef.current.value = '';
		setSearchSt('') 
	}
	const afterSort = () => {
		setPageNumber(1)
		fetchPackage()
	}

	useEffect(() => {
		afterSort()
	}, [sortOrder, sort])

	return (
		<>
			<PageTitle title="Packages" addBtn_title="Add Package" onAddClick={onAddPackage} />
			<div className="content">
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<TableWrapper
							header={
								<>
									<RecordsPerPage
										recordsPerPage={recordsPerPage}
										setRecordsPerPage={setRecordsPerPage}
										setPageNumber={setPageNumber}
									/>
									<div className="right-container">
										{/* <Filter 
											data={filterData} 
											onApply={onApply_Filter}
											fireSearch={fireSearch} // clear filter data when search fires
											refetchDataTable={fetchPackage}
											valueApplied={filter_value_applied}
										/> */}
										<Search
											searchRef={searchRef}
											searchExecute={searchPackage}
											placeholder="Search by Application or Package Name"
										/>
									</div>
								</>
							}
							body={
								<PackageSettingsTable
									data={packageData}
									onEdit={onEditPackage}
									refetch={searchSt ? searchFetchPackage : fetchPackage}
									onPreview={on_open_preview}
									onSort={onSort}
								/>
							}
							footer={
								<Pagination
									pageNumber={pageNumber}
									recordsPerPage={recordsPerPage}
									totalRows={totalRows}
									setPageNumber={setPageNumber}
									numberedPagesCount={5}
								/>
							}
						/>
					</Grid>
				</Grid>
			</div>
		
			<ModalAddPackage
				open={openModal}
				onClose={onCloseModal}
				title={mode === 'edit' ? 'Edit Package' : 'Add Package'}
				mode={mode}
				data={mode === 'edit' ? edit_data : null}
				refetch={() => { 
					clearFilter(); 
					if(searchSt){
						searchFetchPackage();
					} else {
						fetchPackage();
					}
				}}
			/>

			<ModalPreviewImage
				open={open_preview}
				onClose={on_close_preview}
				data={preview_data}
			/>
		</>
	);
}

export default PackageSettings;