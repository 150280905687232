import React from 'react';
import { Button } from '@material-ui/core';
import { ReactComponent as Add } from '../../assets/icons/add.svg';
import { ReactComponent as File } from '../../assets/icons/file.svg';

const PageTitle = ({
  title,
  onAddClick,
  onHistoryClick,
  hideAddBtn,
  hideHistoryBtn,
  addBtn_title,
  historyBtn_title,
  customBtn,
}) => {
  return (
    <div className="page-title">
      <h2>{title}</h2>
      <div className="right">

        {!hideAddBtn &&
        <Button
          variant="contained"
          color="primary"
          className="add-btn"
          disableElevation
          onClick={onAddClick ? onAddClick : null}
          >
          <Add className="icon" />
          {addBtn_title ? addBtn_title : "Add Project"}
        </Button>}

        {!hideHistoryBtn &&
        <Button
          variant="outlined"
          color="primary"
          className="history-btn"
          onClick={onHistoryClick ? onHistoryClick : null}
          >
          <File className="icon" />
          {historyBtn_title ? historyBtn_title : "Show History"}
        </Button>}

        {customBtn}
      </div>
    </div>
  );
}

export default PageTitle;