import React from 'react';
import { Modal, Backdrop, Zoom, Button } from '@material-ui/core';
import { ReactComponent as ConditionalIcon } from '../../assets/icons/alert-delete.svg'

import './Conditional.scss'

const Conditional = ({
  className,
  title,
  description,
  open,
  onClose,
  acceptBtn_label,
  deniedBtn_label,
  onAccept,
  onDenied,
  loadingBtn
}) => {

  return (
    <div>
      <Modal
        aria-labelledby="conditional-title"
        aria-describedby="conditional-desc"
        className={`conditional-modal custom-scroll alert-modals ${className ? className : ''}`}
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        disableBackdropClick
        disableEscapeKeyDown
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Zoom in={open}>
          <div className="paper-wrapper">

            <div className="paper">
              <ConditionalIcon className="icon" />
              <h2 id="conditional-title" className="title">
                {title ? title : 'Warning'}
              </h2>
              {description &&
                <p id="conditional-desc" className="desc">
                  {description}
                </p>
              }
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  disabled={loadingBtn}
                  onClick={onAccept}>
                  {acceptBtn_label ? acceptBtn_label : 'OK'}
                </Button>
                <Button
                  className="denied-btn"
                  variant="contained"
                  disableElevation
                  disabled={loadingBtn}
                  onClick={onDenied}>
                  {deniedBtn_label ? deniedBtn_label : 'Cancel'}
                </Button>
            </div>

          </div>
        </Zoom>
      </Modal>
    </div>
  );
}

export default Conditional;