import axios from "axios";
import Web3 from "web3";

// const DAPPX_ADMIN_API = 'http://51.222.12.10:4000/'
// We conver Data from Wei

const IS_UAT = window.location.href.includes('-uat');
const IS_SAT = window.location.href.includes('localhost') || window.location.href.includes('-sat');

const DAPPX_ADMIN_API = `${IS_SAT ? "https://dappx-api-sat.dappstore.me/" : (IS_UAT ? "https://dappx-api-uat.dappstore.me/" : "https://dappx-api.dappstore.me/")}`;

export const getPledgeMining = () => {
  return async (dispatch) => {
    dispatch({ type: "PLEDGE_MINING_LIST_REQUEST" });
    
    const res = await axios.get(`${DAPPX_ADMIN_API}users/getPledgingMiningRateConfig`, { headers: { 'custom-token-id': 'H00R7LPQSY' } })

    const convertedValues = res.data.data.map((item) => (
      {
        id: item.id,
        start_range: Web3.utils.fromWei(item.start_range.toLocaleString('fullwide', {useGrouping:false}).toString()),
        end_range: Web3.utils.fromWei(item.end_range.toLocaleString('fullwide', {useGrouping:false}).toString()),
        mining_rate: Web3.utils.fromWei(item.mining_rate.toLocaleString('fullwide', {useGrouping:false}).toString())
      } 
    ))

    if (res.error) {
      dispatch({
        type: "PLEDGE_MINING_FAIL",
        payload: res.data,
      });
    } else {
      dispatch({
        type: "PLEDGE_MINING_SUCCESS",
        payload: convertedValues,
      });
    }
  };
};

export const postPledgesMining = (data) => {
  return async (dispatch) => {
    dispatch({ type: "PLEDGE_MINING_UPDATE_REQUEST" });

    const res = await axios.post(`${DAPPX_ADMIN_API}users/updatePledgingMiningConfig`, data, { headers: { 'custom-token-id': 'H00R7LPQSY' } })
    if (res.error) {
      dispatch({
        type: "PLEDGE_MINING_UPDATE_FAIL",
        payload: res.data,
      });
    } else {
      dispatch({
        type: "PLEDGE_MINING_UPDATE_SUCCESS",
        payload: res.data,
      });
    }
  };
};
