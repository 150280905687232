import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Switch, Route } from 'react-router-dom';
import Sidebar from '../../components/sidebar/Sidebar';
import Maintenance from './maintenance/Maintenance'
import ACEPedgingPage from './ace-pledging/ACEPLedgingPage'
import GovPledgingPage from './ace-governance/GovPledgingPage'
import BuyAcePage from './buy-ace/BuyAceMain'
import EdaoPledgingPage from './edao-pledging/EdaoPledgingPage'
import PledgingPage from './dappx-pledging/PledgingPage'
import TicketsPage from './tickets/tickets/TicketsPage'
import SetupPage from './tickets/ticket-setup/SetupPage';
import TypePage from './tickets/setup-type/TypePage'
import DappxPaypal from './paypal/DappxPaypal';
import AceWithdrawMain from './withdraw-ace/AceWithdrawMain';
import DappxWithdrawMain from './withdraw-dappx/DappxWithdrawMain';
import { listRewardsStatus, listTickets } from "../../actions/TicketsActions";
import { listTicketsSetup } from "../../actions/TicketsSeupActions";
import { listTypes } from "../../actions/TypeActions";
import { getGovenanceSettings } from '../../actions/GovernanceSettings'
import './Settings.scss'

const Settings = ({ history, match }) => {
  const dispatch = (useDispatch())

  const rewardsUpdate = useSelector((state) => state.rewardsUpdate);
  const { updateSuccess } = rewardsUpdate

  useEffect(() => {
    dispatch(listRewardsStatus());
    dispatch(listTickets());
    dispatch(listTicketsSetup());
    dispatch(listTypes());
		dispatch(getGovenanceSettings())
  }, [updateSuccess])

	const menu = [
		{
			id: 'maintenance',
			label: 'Maintenance',
			icon: '',
			nested: [{
				id: `${match.url}--maintenance`,
				label: 'Site Maintenance',
				href: `${match.url}/maintenance`,
				icon: '',
			}]
		},
		{
			id: 'deposit',
			label: 'Deposit',
			icon: '',
			nested: [{
				id: `${match.url}--deposit`,
				label: 'Deposit',
				href: `${match.url}/deposit`,
				icon: '',
			}]
		},
		{
			id: 'pledging',
			label: 'Pledging',
			icon: '',
			nested: [{
				id: `${match.url}--ace-season`,
				label: 'ACE Season',
				href: `${match.url}/ace-season`,
				icon: '',
			},{
				id: `${match.url}--ace-governance`,
				label: 'ACE Governance',
				href: `${match.url}/ace-governance`,
				icon: '',
			},{
				id: `${match.url}--edao-pledging`,
				label: 'EDAO',
				href: `${match.url}/edao-pledging`,
				icon: '',
			},{
				id: `${match.url}--dappx-pledging`,
				label: 'DAPPX',
				href: `${match.url}/dappx-pledging`,
				icon: '',
			}]
		},
		{
			id: 'swap',
			label: 'Swap',
			icon: '',
			nested: [{
				id: `${match.url}--swap`,
				label: 'Swap',
				href: `${match.url}/swap`,
				icon: '',
			}]
		},
		{
			id: 'buy with eth',
			label: 'Buy with ETH',
			icon: '',
			nested: [{
				id: `${match.url}--buy-ace`,
				label: 'ACE',
				href: `${match.url}/buy-ace`,
				icon: '',
			}]
		},
		{
			id: 'withdrawal',
			label: 'Withdrawal',
			icon: '',
			nested: [{
				id: `${match.url}--ace-withdrawal`,
				label: 'ACE',
				href: `${match.url}/ace-withdrawal`,
				icon: '',
			},{
				id: `${match.url}--dappx-withdrawal`,
				label: 'DAPPX',
				href: `${match.url}/dappx-withdrawal`,
				icon: '',
			}]
		},
		// {
		// 	id: 'Product Management',
		// 	label: 'Product Management',
		// 	icon: '',
		// 	nested: [{
		// 		id: `${match.url}--paypal-dapp`,
		// 		label: 'DAPPX',
		// 		href: `${match.url}/paypal-dappx`,
		// 		icon: '',
		// 	}]
		// },
		// {
		// 	id: 'tickets',
		// 	label: 'Tickets',
		// 	icon: '',
		// 	nested: [{
		// 		id: `${match.url}--tickets`,
		// 		label: 'Tickets',
		// 		href: `${match.url}/tickets`,
		// 		icon: '',
		// 	},
    //   {
    //     id:`${match.url}--ticket-setup`,
    //     label: 'Ticket Setup',
    //     href: `${match.url}/ticket-setup`,
    //     icon: '',
    //   },
    //   {
    //     id: `${match.url}--ticket-type`,
    //     label: 'Ticket Type Setup',
    //     href: `${match.url}/ticket-type`,
    //     icon: '',
    //   }]
		// },
	]

	useEffect(() => {
		history.push('/settings/deposit');
	}, []);

	return (
		<div className="page-wrapper packages-page">
			<Sidebar menu={menu} initialOpen={'package-management'} />
			<div className="page-content custom-scroll">
				<Switch>
          <Route exact path={`${match.path}/maintenance`} component={Maintenance} />
					<Route exact path={`${match.path}/ace-season`} component={ACEPedgingPage} />
					<Route exact path={`${match.path}/ace-governance`} component={GovPledgingPage} />
					<Route exact path={`${match.path}/edao-pledging`} component={EdaoPledgingPage} />
					<Route exact path={`${match.path}/dappx-pledging`} component={PledgingPage} />
					<Route exact path={`${match.path}/buy-ace`} component={BuyAcePage} />
					<Route exact path={`${match.path}/tickets`} component={SetupPage} />
					<Route exact path={`${match.path}/ticket-setup`} component={TicketsPage}/>
					<Route exact path={`${match.path}/ticket-type`} component={TypePage}/>
					<Route exact path={`${match.path}/ace-withdrawal`} component={AceWithdrawMain}/>
					<Route exact path={`${match.path}/dappx-withdrawal`} component={DappxWithdrawMain}/>
					<Route exact path={`${match.path}/paypal-dappx`} component={DappxPaypal}/>
				</Switch>
			</div>
		</div>
	);
}

export default Settings;