import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Topnav from '../../components/top-nav/Topnav';
import Banners from '../../domain/banners/Banners';
import Dapps from '../../domain/dapps/Dapps';
import Users from '../../domain/users/Users';
import Packages from '../../domain/packages/Packages';
import Settings from '../settings/Settings'
import './Dashboard.scss'

const Dashboard = () => {
  return (
    <div className="wrapper">
			<Topnav/>
			<main className="main">
        <Switch>
          <Route path="/banners" component={Banners} />
          <Route path="/dApps" component={Dapps} />
          <Route path="/users" component={Users} />
          <Route path="/packages" component={Packages} />
          <Route path="/settings" component={Settings} />
          <Route path="*" component={() => "404 NOT FOUND"} />
        </Switch>
			</main>
		</div>
  );
}
 
export default Dashboard;