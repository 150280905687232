import React, { useState, useContext } from 'react';
import axios from 'axios';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { InputLabel, Box, FormGroup, OutlinedInput, FormControlLabel, Checkbox, Button } from '@material-ui/core';
import { ReactComponent as Logo } from '../../assets/images/dappstore-logo.svg';
import { AuthContext } from '../../context/AuthContext';
import { API_BASE_URL } from '../../constant/config';
import Loader from '../../components/loader/Loader';
import { ErrorModal } from '../../components/alerts/Alerts'

import './Login.scss';


const Login = () => {
  const history = useHistory();
  const { dispatch } = useContext(AuthContext);

  // ALERTS START =================================

  // Loading
  const [loading_alert, set_loading_alert] = useState(false);
  const onClose_loading_alert = () => set_loading_alert(false);
  const onOpen_loading_alert = () => set_loading_alert(true);

  // Error
  const [error_alert, set_error_alert] = useState(false);
  const onClose_error_alert = () => set_error_alert(false);
  const onOpen_error_alert = () => set_error_alert(true);

  // ALERTS END =================================

  const [values, setValues] = useState({
    email: '',
    password: '',
    remember_me: false
  })

  const onChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const onRemember = (event) => {
    setValues({ ...values, [event.target.name]: event.target.checked });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    onOpen_loading_alert()
    if (values.email && values.password) {
      executeLogin()
    } else {
      setTimeout(()=>{
        onClose_loading_alert()
        onOpen_error_alert();
      }, 1000)
    }
  }

  const executeLogin = () => {
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/login`,
      data: values
    }).then(res => {
      let payload = res.data.results;
      if(res){
        setTimeout(()=>{
          onClose_loading_alert()
          dispatch({
            type: "LOGIN",
            payload: payload
          })
          history.push("/users/user-overview");
        }, 1000)
      }
    }).catch(error => {
      console.log(error);
      setTimeout(()=>{
        onClose_loading_alert()
        onOpen_error_alert();
      }, 1000)
    });
  }

  return (
    <div className="page-wrapper login-page">
      <Box className="form-box">
        <Logo />
        <form onSubmit={onSubmit}>
          <FormGroup >
            <InputLabel htmlFor="email-field">Email</InputLabel>
            <OutlinedInput
              className="form-input"
              id="email-field"
              type="text"
              placeholder="Enter your email"
              value={values.email}
              onChange={onChange('email')}
              labelWidth={0}
            />
          </FormGroup>
          <FormGroup >
            <InputLabel htmlFor="password-field">Password</InputLabel>
            <OutlinedInput
              className="form-input"
              id="password-field"
              type="password"
              placeholder="Enter your password"
              value={values.password}
              onChange={onChange('password')}
              labelWidth={0}
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.remember_me}
                  onChange={onRemember}
                  name="remember_me"
                  color="primary" />
              }
              label="Remember me"
              title="if check, you will stay logged in for 24hrs"
            />
          </FormGroup>
          <Button
            className="btn-submit"
            variant="contained"
            color="primary"
            type="submit"
            disableElevation
            disabled={loading_alert ? true : false}>
            <span className="text">Login</span>
            <Loader 
              appear={loading_alert} 
              timeout={1000} 
              thick 
              width="22" 
              coloredBg 
              svgProps={{
                absolute: true,
                direction: "right",
                negative: true,
              }} 
            />
          </Button>
        </form>
        <RouterLink
          className="btn-forgot"
          to="/forgot-password">
          Forgot your password?
        </RouterLink>
      </Box>

      <ErrorModal 
        description="Email or Password is incorrect"
        open={error_alert}
        onClose={onClose_error_alert}
      />
    </div>
  );
}

export default Login;