import React from 'react';
import { Paper } from '@material-ui/core';
import './style.scss';

const TableWrapper = ({
  className,
  header,
  body,
  footer,
}) => {
  return (
    <Paper elevation={0} className={`table-wrapper ${className ? className : ''}`}>
      <div className="table-container">
        {
          header &&
          (
            <div className="header">
              {header}
            </div>
          )
        }

        <div className="body">
          {body}
        </div>

        {
          footer &&
          (
            <div className="footer">
              {footer}
            </div>
          )
        }
      </div>
    </Paper>
  );
}

export default TableWrapper;