import React, { useState, useEffect, useContext, useRef } from 'react';
import { Grid, Paper } from '@material-ui/core';
import PageTitle from '../../../components/page-title/PageTitle';
import { TableWrapper, Search, Pagination, RecordsPerPage } from '../../../components/table-elements';
import { ReactComponent as MultipleUsers } from '../../../assets/icons/multiple-users.svg';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { DAPPX_API_BASE_URL } from '../../../constant/config';
import { AuthContext } from '../../../context/AuthContext';
import UserOverviewTable from './Table';

const UserOverview = () => {
	const { authState, dispatch } = useContext(AuthContext);
  let history = useHistory();

	const [totalUsers, setTotalUsers] = useState(0); // for total registered users card

	const fetchTotalUsers = () => {
		axios.get(`${DAPPX_API_BASE_URL}/users/total-users`, { headers: { 'auth-token': authState.token } })
			.then(result => {
				setTotalUsers(result.data.total_users);
			})
			.catch(error => {
				console.log(error);
			})
	}

	const [users, setUsers] = useState([]);
	const [totalRows, setTotalRows] = useState(0);
	const [pageNumber, setPageNumber] = useState(1);
	const [recordsPerPage, setRecordsPerPage] = useState(15); // change to actual default value
	const [searchEmailSt, setSearchEmailSt] = useState('');

	const fetchUsers = () => {
		let getUsers_ApiUrl = `${DAPPX_API_BASE_URL}/users?offset=${((pageNumber - 1) * recordsPerPage)}&limit=${recordsPerPage}`;

		axios.get(getUsers_ApiUrl, { headers: { 'auth-token': authState.token } })
			.then(result => {
				setUsers(result.data.users);
				setTotalRows(result.data.total_rows);
			})
			.catch(error => {
				console.log(error);
				if(error.response.data.error === "Unauthorized Request"){
					dispatch({
						type: "LOGOUT"
					});
					history.push('/login');
				}
			});
	}

	const searchFetchUsers = () => {
		console.log(pageNumber)
		console.log(searchEmailSt)
		let getUsers_ApiUrl = `${DAPPX_API_BASE_URL}/users?offset=${((pageNumber - 1) * recordsPerPage)}&limit=${recordsPerPage}&email=${searchEmailSt}`;

		axios.get(getUsers_ApiUrl, { headers: { 'auth-token': authState.token } })
			.then(result => {
				setUsers(result.data.users);
				setTotalRows(result.data.total_rows);
			})
			.catch(error => {
				console.log(error);
			});
	}

	const searchRef = useRef();
	const searchUsers = (e) => {
		e.preventDefault();
		let value = searchRef.current.value;
		let remove_space = value.replace(/ /g, "");
		setPageNumber(1)
		setSearchEmailSt(remove_space)
	}

	useEffect(() => {
		if (searchEmailSt) {
			searchFetchUsers();
		} else {
			fetchUsers();
			fetchTotalUsers();
		}
	}, [searchEmailSt, pageNumber, recordsPerPage]);

	return (
		<>
			<PageTitle title="User Overview" hideAddBtn={true} hideHistoryBtn={true} />
			<div className="content">

				<Grid container spacing={3}>
					<Grid item lg={4} md={6} sm={12}>
						<Paper elevation={0} className="card-paper">

							<div className="title">
								<span className="icon"><MultipleUsers /></span>
								Total Registered Account
							</div>
							<div className="value">
								{totalUsers}
							</div>

						</Paper>
					</Grid>
				</Grid>

				<Grid container spacing={3}>
					<Grid item xs={12}>
						<TableWrapper
							header={
								<>
									<RecordsPerPage
										recordsPerPage={recordsPerPage}
										setRecordsPerPage={setRecordsPerPage}
										setPageNumber={setPageNumber}
									/>
									<div className="right-container">
										<Search
											searchRef={searchRef}
											searchExecute={searchUsers}
											placeholder="Search by Email"
										/>
									</div>
								</>
							}
							body={
								<UserOverviewTable data={users} />
							}
							footer={
								<Pagination
									pageNumber={pageNumber}
									recordsPerPage={recordsPerPage}
									totalRows={totalRows}
									setPageNumber={setPageNumber}
									numberedPagesCount={5}
								/>
							}
						/>
					</Grid>
				</Grid>
			</div>
		</>
	);
}

export default UserOverview;