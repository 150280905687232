import React from 'react';
import PageTitle from '../../../components/page-title/PageTitle';
// import { Button } from '@material-ui/core'
// import Loader from '../../../components/loader/Loader'

const LouiesPick = () => {
  return (
    <>
      <PageTitle title="Main | Louie's Pick"/>
      {/* <div className="content">
        <Button color="primary" variant="contained" >Loader in button <Loader appear={true} thick width="16" className="ml-8" coloredBg/></Button>
        
        <Loader appear={true} width="100"/> 
      </div> */}
    </>
  );
}

export default LouiesPick;