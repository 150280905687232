import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, FormControl, TextField, makeStyles } from '@material-ui/core';
import currencyIcon from '../../../assets/images/currency/acent-logo.svg'
import { addAcePledgeSeason, updateAcePledgeSeason } from '../../../actions/PledgeSeason'
import '../Settings.scss'

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '400px',
    minHeight: '400px',
    bgcolor: 'background.paper',
    border: '1px solid grey',
    borderRadius: '20px',
    boxShadow: 24,
    p: 4,
    backgroundColor: 'white'
  },
  textFieldContainer: {
    position: 'relative',
    display: 'inline-block'
  },
  textFieldImg: {
    position: 'absolute',
    right: -390,
    top: 17,
    width: 20,
    height: 20
  },
  TextFieldBorder: {
    [`& fieldset`]: {
      borderRadius: 20,
      width: 690,
      height: 40,
      paddingBottom: 7,
      marginTop: '7px',
      display: 'flex',
      alignItems: 'flex-start',
    },
    width: '300px',
  }
});

const SettingsModal = (props) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const getMinings = useSelector(state => state.getMinings)
  const { minings } = getMinings

  const [id, setId] = useState('')
  const [seasonName, setSeasonName] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [duration, setDuration] = useState('')
  const [minPledge, setMinPledge] = useState('')
  const [maxPledge, setMaxPledge] = useState('')

  useEffect(() => {
    setId(props.data?.id)
    setStartDate(new Date().toISOString().split('T')[0])

  }, [props.data])

  const seasonNameChange = (e) => {
    setSeasonName(e.target.value)
  }

  const startDateChange = (e) => {
    setStartDate(e.target.value)
  }

  const endDateChange = (e) => {
    setEndDate(e.target.value)
  }

  const durationChange = (e) => {
    setDuration(e.target.value)
  }

  const minPledgeChange = (e) => {
    setMinPledge(e.target.value)
  }

  const maxPledgeChange = (e) => {
    setMaxPledge(e.target.value)
  }

  const submitModal = (e) => {
    e.preventDefault();
    let data= []

    data.push({
      id:id,
      name: seasonName ? seasonName : props?.data?.name,
      start_date: startDate ? startDate : props?.data.start_date,
      end_date: endDate ? endDate : props?.data?.end_date,
      duration: duration ? duration : props?.data?.duration,
      min_amount: minPledge ? minPledge : props?.data?.min_amount,
      max_amount: maxPledge ? maxPledge : props?.data?.maxPledge
    })

    if (props.modalTitle.includes('Add')) {
      dispatch(addAcePledgeSeason(data))
    } else {
      dispatch(updateAcePledgeSeason(data, id))
    }
    props.onSubmit()
  }

  return (
    <>
      <Modal
        open={props.openEditModal}
      >
        <div className={classes.root}>
          <div className="paper-wrapper">
            <div className="paper">

              <div className="header">
                <h2 className="title">{props.modalTitle}</h2>
              </div>
              <form>
                <div className="textfield-item-container">
                  <FormControl className="edit-form-control">
                    <label className="form-label" htmlFor="package">Season Name</label>
                    <div className={classes.textFieldContainer}>
                      <img src={currencyIcon} alt="" className={classes.textFieldImg} />
                      <TextField variant="outlined" className={classes.TextFieldBorder} value={seasonName} onChange={seasonNameChange} />
                    </div>
                  </FormControl>

                  <FormControl className="edit-form-control ace-date-range">
                    <TextField
                      id="date"
                      variant="outlined"
                      label="Start Date"
                      type="date"
                      defaultValue={new Date().toISOString().split('T')[0]}
                      // className={classes.textField}
                      onChange={startDateChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      id="date"
                      variant="outlined"
                      label="End Date"
                      type="date"
                      defaultValue={new Date().toISOString().split('T')[0]}
                      onChange={endDateChange}
                      // className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>

                  <FormControl className="edit-form-control">
                    <label className="form-label" htmlFor="package">Locked up Duration</label>
                    <div className={classes.textFieldContainer}>
                      <img src={currencyIcon} alt="" className={classes.textFieldImg} />
                      <TextField variant="outlined" className={classes.TextFieldBorder} value={duration} onChange={durationChange}/>
                    </div>
                  </FormControl>

                  <FormControl className="edit-form-control">
                    <label className="form-label" htmlFor="package">Minimum Pledge</label>
                    <div className={classes.textFieldContainer}>
                      <img src={currencyIcon} alt="" className={classes.textFieldImg} />
                      <TextField variant="outlined" className={classes.TextFieldBorder} value={minPledge} onChange={minPledgeChange} />
                    </div>
                  </FormControl>

                  <FormControl className="edit-form-control">
                    <label className="form-label" htmlFor="package">Maximum Pledge</label>
                    <div className={classes.textFieldContainer}>
                      <img src={currencyIcon} alt="" className={classes.textFieldImg} />
                      <TextField variant="outlined" className={classes.TextFieldBorder} value={maxPledge} onChange={maxPledgeChange}/>
                    </div>
                  </FormControl>
                </div>
              </form>
              <div className="footer">
                <Button
                  disableElevation
                  color="primary"
                  variant="contained"
                  className="submit-btn"
                  type="submit"
                  onClick={submitModal}
                >
                  <span className="text">Save</span>
                </Button>
                <Button
                  disableElevation
                  variant="contained"
                  className="exit-btn"
                  onClick={props.onClose}
                >
                  Exit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default SettingsModal