import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@material-ui/core';
import { Button, Typography, TextField, makeStyles } from '@material-ui/core';
import currencyIcon from '../../../assets/images/currency/edao.png'
import aceIcon from '../../../assets/images/currency/acent-logo.svg'
import DataTable from 'react-data-table-component';
import Loader from '../../loader/Loader';
import { updateEdaoSettings } from '../../../actions/EdaoSettings'
import Big from 'big.js';

const useStyles = makeStyles({
  root: {
      display: "flex",
      alignItems: "center",
      "& .myIcon": {
        paddingLeft: "8px",
        order: 999
    }
  },
  textFieldContainer: {
    position: 'relative',
    display: 'inline-block'
  },
  textFieldImg: {
    position: 'absolute',
    right: 10,
    top: 17,
    width: 20,
    height: 20
  },
  primaryGrid: {
    margin: '8px'
  },
  TextFieldBorder: {
    [`& fieldset`]: {
      borderRadius: 20,
  },
  width: '300px',
  },
  midSection: {
    width: '100%',
    display: 'flex',
    justifyContent:'space-between',
    marginTop: '20px',
  },
  aTag: {
    textDecoration: 'none',
    color: 'blue',
    cursor: 'pointer'
  },
  buttonsHolder : {
    marginTop: '50px',
    display: 'flex',
    justifyContent: 'center'
  },
  nestedTextFieldsContainer:{
    marginLeft: '155px',
    marginTop: '10px'
  },
  pStyle: {
    marginTop: '10px',
    fontWeight: 500
  }
});

const Pledging = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const getEdao = useSelector(state => state.getEdao)
  const updateEdaoMining = useSelector(state => state.updateEdaoMining)
  const getEdaoMining = useSelector(state => state.getEdaoMining)

  const {pledges} = getEdao
  const { miningRates } = getEdaoMining
  const { updateLoading } = updateEdaoMining

  const [minPledgeNumber, setMinPledgeNumber] = useState(pledges?.min_pledge_per_tx)
  const [maxPledgeNumber, setMaxPledgeNumber] = useState(pledges?.max_pledge_per_tx)
  const [maxAccountPledge, setMaxAccountPledge] = useState(pledges?.max_pledge_per_user)
  const [activationFee, setActivationFee] = useState(pledges?.activation_fee)
  const [pledgeFeeCore, setPledgeFeeCore] = useState(pledges?.pledge_fee_core) 
  const [pledgeFeeNitro, setPledgeFeeNitro] = useState(pledges?.pledge_fee_nitro) 
  const [pledgeFeeUbi, setPledgeFeeUbi] = useState(pledges?.pledge_fee_ubi) 
  const [holdingPeriod, setHoldingPeriod] = useState(pledges?.penalty_duration)
  const [unpledgeFeeCore, setUnpledgeFeeCore] = useState(pledges?.unpledge_fee_core)
  const [unpledgeFeeNitro, setUnpledgeFeeNitro] = useState(pledges?.unpledge_fee_nitro)
  const [unpledgeFeeUbi, setUnpledgeFeeUbi] = useState(pledges?.unpledge_fee_ubi)
  const [showButtons, setShowButtons] = useState(false)
  const [sumPledging, setSumPledging] = useState(0)
  const [sumUnpledging, setSumUnpledging] = useState(0)

  useEffect(() => {
    setSumPledging( (pledgeFeeCore + pledgeFeeNitro + pledgeFeeUbi).toFixed(13).replace(/\.?0+$/, ''))
    setSumUnpledging( (unpledgeFeeCore + unpledgeFeeNitro + unpledgeFeeUbi).toFixed(13).replace(/\.?0+$/, ''))
  },[])

  //Edit and Delete Buttons
  const format_action = (row) => {
		return (
			<div className="action-cell">
				<Button 
					variant="contained" 
					disableElevation 
					color="primary" 
					className="edit-btn" 
					onClick={() => props.onEdit(row)}
          >
						Edit
					</Button>
				<Button 
					variant="contained" 
					disableElevation color="secondary" 
					className="delete-btn" 
          onClick={() => props.onDelete(row)}
          >
						Delete
				</Button>
			</div>
		)
	}

  const minPledgeChange = (e) => {
    setMinPledgeNumber(e.target.value)
    setShowButtons(true)
  }

  const maxPledgeChange = (e) => {
    setMaxPledgeNumber(e.target.value)
    setShowButtons(true)
  }

  const maxAccChange = (e) => {
    setMaxAccountPledge(e.target.value)
    setShowButtons(true)
  }

  const actFeeChange = (e) => {
    setActivationFee(e.target.value)
    setShowButtons(true)
  }

  const pledgeFeeNitroChange = (e) => {
    setPledgeFeeNitro(e.target.value)
    setShowButtons(true)
  }

  const pledgeFeeCoreChange = (e) => {
    setPledgeFeeCore(e.target.value)
    setShowButtons(true)
  }

  const pledgeFeeUbiChange = (e) => {
    setPledgeFeeUbi(e.target.value)
    setShowButtons(true)
  }

  const holdingPeriodChange = (e) => {
    setHoldingPeriod(e.target.value)
    setShowButtons(true)
  }

  const unpledgeFeeCoreChange = (e) => {
    setUnpledgeFeeCore(e.target.value)
    setShowButtons(true)
  }

  const unpledgeFeeNitroChange = (e) => {
    setUnpledgeFeeNitro(e.target.value)
    setShowButtons(true)
  }

  const unpledgeFeeUbiChange = (e) => {
    setUnpledgeFeeUbi(e.target.value)
    setShowButtons(true)
  }

  const cancelChanged =() =>{
    setShowButtons(false)
    setMinPledgeNumber(pledges?.min_pledge_per_tx)
    setMaxPledgeNumber(pledges?.max_pledge_per_tx)
    setMaxAccountPledge(pledges?.max_pledge_per_user)
    setActivationFee(pledges?.activation_fee)
    setPledgeFeeCore(pledges?.pledge_fee_core)
    setPledgeFeeNitro(pledges?.pledge_fee_nitro)
    setPledgeFeeUbi(pledges?.pledge_fee_ubi)
    setHoldingPeriod(pledges?.penalty_duration)
    setUnpledgeFeeCore(pledges?.unpledge_fee_core)
    setUnpledgeFeeNitro(pledges?.unpledge_fee_nitro)
    setUnpledgeFeeUbi(pledges?.unpledge_fee_ubi)
  }

  const updatePledgeConfig = (e) => {
    e.preventDefault();
    setShowButtons(false)
    let data ={
      min_pledge_per_tx: minPledgeNumber,
      max_pledge_per_tx: maxPledgeNumber,
      max_pledge_per_user: maxAccountPledge,
      activation_fee: activationFee,
      pledge_fee_core: pledgeFeeCore,
      pledge_fee_nitro: pledgeFeeNitro,
      pledge_fee_ubi: pledgeFeeUbi,
      penalty_duration: holdingPeriod,
      unpledge_fee_core: unpledgeFeeCore,
      unpledge_fee_nitro: unpledgeFeeNitro,
      unpledge_fee_ubi: unpledgeFeeUbi
    }

    dispatch(
      updateEdaoSettings(data)
    );
  }

  const columns = [
    {
        name: 'Start Range',
        selector: row => row.startRange,
    },
    {
        name: 'End Range',
        selector: row => row.endRange,
    },
    {
        name: 'Mining Rate per min',
        selector: row => new Big(row.miningRate).toFixed(18).replace(/\.?0+$/, ''),
    },
    {
			width: '202px',
			cell: row => format_action(row),
			button: true
		},
];

const data = miningRates.map(item => (
  {
    id: item._id,
    startRange:item.start,
    endRange:item.end,
    miningRate:item.rate
  } 
));

  return (
    <>
    <div className="content">
    <form>
      <Grid container spacing={3}>
        {/* Inputs Section */}
              <Grid container spacing={12} className={classes.primaryGrid}>
                <Grid item xs={3}>
                  <Typography>Minimum Pledge Limit per Transaction</Typography>
                </Grid>
                <div className={classes.textFieldContainer}>
                  <img src={currencyIcon} alt="" className={classes.textFieldImg}/>
                  <TextField
                    variant="outlined"
                    className={classes.TextFieldBorder}
                    value={minPledgeNumber}
                    placeholder="0.0"
                    onChange={(e) => minPledgeChange(e)}
                  />
                </div>
              </Grid>
      </Grid>

      <Grid container spacing={3}>
        {/* Inputs Section */}
              <Grid container spacing={12} className={classes.primaryGrid}>
                <Grid item xs={3}>
                  <Typography>Maximum Pledge Limit per Transaction</Typography>
                </Grid>
                <div className={classes.textFieldContainer}>
                  <img src={currencyIcon} alt="" className={classes.textFieldImg}/>
                  <TextField
                    variant="outlined"
                    className={classes.TextFieldBorder}
                    value={maxPledgeNumber}
                    onChange={(e) => maxPledgeChange(e)}
                  />
                </div>
              </Grid>
      </Grid>

      <Grid container spacing={3}>
        {/* Inputs Section */}
              <Grid container spacing={12} className={classes.primaryGrid}>
                <Grid item xs={3}>
                  <Typography>Maximum Pledge per Account</Typography>
                </Grid>
                <div className={classes.textFieldContainer}>
                  <img src={currencyIcon} alt="" className={classes.textFieldImg}/>
                  <TextField
                    variant="outlined"
                    className={classes.TextFieldBorder}
                    value={maxAccountPledge}
                    onChange={(e) => maxAccChange(e)}
                  />
                </div>
              </Grid>
      </Grid>

      <Grid container spacing={3}>
        {/* Inputs Section */}
              <Grid container spacing={12} className={classes.primaryGrid}>
                <Grid item xs={3}>
                  <Typography>Activation Fee</Typography>
                </Grid>
                <div className={classes.textFieldContainer}>
                  <img src={aceIcon} alt="" className={classes.textFieldImg}/>
                  <TextField
                    variant="outlined"
                    className={classes.TextFieldBorder}
                    value={activationFee}
                    onChange={(e) => actFeeChange(e)}
                  />
                </div>
              </Grid>
      </Grid>

      <Grid container spacing={3} style={{marginBottom: '40px'}}>
              <Grid container spacing={12} className={classes.primaryGrid}>
                <Grid item xs={3}>
                  <Typography>Pledging Fee - <b>{sumPledging} ACE</b></Typography>
                </Grid>
              </Grid>

              <Grid container spacing={3} className={classes.nestedTextFieldsContainer}>
                <Grid container spacing={12} className={classes.primaryGrid}>
                  <Grid item xs={3} lg={2}>
                    <Typography className={classes.pStyle}>Nitro</Typography>
                  </Grid>
                  <div className={classes.textFieldContainer} style={{marginLeft: '30px'}}>
                    <img src={aceIcon} alt="" className={classes.textFieldImg}/>
                    <TextField
                      variant="outlined"
                      className={classes.TextFieldBorder}
                      value={pledgeFeeNitro}
                      onChange={(e) => pledgeFeeNitroChange(e)}
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid container spacing={3} className={classes.nestedTextFieldsContainer}>
                <Grid container spacing={12} className={classes.primaryGrid}>
                  <Grid item xs={3} lg={2}>
                    <Typography className={classes.pStyle}>UBI Protocol</Typography>
                  </Grid>
                  <div className={classes.textFieldContainer} style={{marginLeft: '30px'}}>
                    <img src={aceIcon} alt="" className={classes.textFieldImg}/>
                    <TextField
                      variant="outlined"
                      className={classes.TextFieldBorder}
                      value={pledgeFeeUbi}
                      onChange={(e) => pledgeFeeUbiChange(e)}
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid container spacing={3} className={classes.nestedTextFieldsContainer}>
                <Grid container spacing={12} className={classes.primaryGrid}>
                  <Grid item xs={3} lg={2}>
                    <Typography className={classes.pStyle}>Core Ecosystem Dev</Typography>
                  </Grid>
                  <div className={classes.textFieldContainer} style={{marginLeft: '30px'}}>
                    <img src={aceIcon} alt="" className={classes.textFieldImg}/>
                    <TextField
                      variant="outlined"
                      className={classes.TextFieldBorder}
                      value={pledgeFeeCore}
                      onChange={(e) => pledgeFeeCoreChange(e)}
                    />
                  </div>
                </Grid>
              </Grid>
      </Grid>

      <Grid container spacing={3}>
        {/* Inputs Section */}
              <Grid container spacing={12} className={classes.primaryGrid}>
                <Grid item xs={3}>
                  <Typography>Holding Period (Hours)</Typography>
                </Grid>
                <div className={classes.textFieldContainer}>
                  <TextField
                    variant="outlined"
                    className={classes.TextFieldBorder}
                    value={holdingPeriod}
                    onChange={(e) => holdingPeriodChange(e)}
                  />
                </div>
              </Grid>
      </Grid>

      <Grid container spacing={3}>
              <Grid container spacing={12} className={classes.primaryGrid}>
                <Grid item xs={3}>
                  <Typography>Unpledging Fee - <b>{sumUnpledging} ACE</b></Typography>
                </Grid>
              </Grid>

              <Grid container spacing={3} className={classes.nestedTextFieldsContainer}>
                <Grid container spacing={12} className={classes.primaryGrid}>
                  <Grid item xs={3} lg={2}>
                    <Typography className={classes.pStyle}>Nitro</Typography>
                  </Grid>
                  <div className={classes.textFieldContainer} style={{marginLeft: '30px'}}>
                    <img src={aceIcon} alt="" className={classes.textFieldImg}/>
                    <TextField
                      variant="outlined"
                      className={classes.TextFieldBorder}
                      value={unpledgeFeeNitro}
                      onChange={(e) => unpledgeFeeNitroChange(e)}
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid container spacing={3} className={classes.nestedTextFieldsContainer}>
                <Grid container spacing={12} className={classes.primaryGrid}>
                  <Grid item xs={3} lg={2}>
                    <Typography className={classes.pStyle}>UBI Protocol</Typography>
                  </Grid>
                  <div className={classes.textFieldContainer} style={{marginLeft: '30px'}}>
                    <img src={aceIcon} alt="" className={classes.textFieldImg}/>
                    <TextField
                      variant="outlined"
                      className={classes.TextFieldBorder}
                      value={unpledgeFeeUbi}
                      onChange={(e) => unpledgeFeeUbiChange(e)}
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid container spacing={3} className={classes.nestedTextFieldsContainer}>
                <Grid container spacing={12} className={classes.primaryGrid}>
                  <Grid item xs={3} lg={2}>
                    <Typography className={classes.pStyle}>Core Ecosystem Dev</Typography>
                  </Grid>
                  <div className={classes.textFieldContainer} style={{marginLeft: '30px'}}>
                    <img src={aceIcon} alt="" className={classes.textFieldImg}/>
                    <TextField
                      variant="outlined"
                      className={classes.TextFieldBorder}
                      value={unpledgeFeeCore}
                      onChange={(e) => unpledgeFeeCoreChange(e)}
                    />
                  </div>
                </Grid>
              </Grid>
      </Grid>

      <div className={classes.midSection}>
        <p> Mining Rate per Minute</p>
        <a className={classes.aTag} onClick={() => props.onAdd()}><b>Add New Transaction Range</b></a>
      </div>

      {/* Table Section */}
      <DataTable
				columns={columns}
				data={data}
				sortServer
				progressPending={updateLoading}
				progressComponent={
					<Loader
						appear={updateLoading}
						width="100"
					/>
				}
				persistTableHead
			/>
      {showButtons ? 
      <div className="footer">
        <Button
          disableElevation
          color="primary"
          variant="contained"
          className="submit-btn"
          type="submit"
          onClick={updatePledgeConfig}
          >
          <span className="text">Save</span>
        </Button>
        <Button
          disableElevation
          variant="contained"
          className="exit-btn"
          onClick={cancelChanged}
          >
          Cancel
        </Button>
      </div>
        : ''}
      </form>
    </div>
    </>
  )
}

export default Pledging
