import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, makeStyles, Tooltip } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import DataTable from 'react-data-table-component';
import Loader from '../../loader/Loader';
import { ConditionalModal } from '../../alerts/Alerts';
import { formatDate } from '../../../utils';
import currencyIcon from '../../../assets/images/currency/acent-logo.svg'
import { deletePledgeSeason } from '../../../actions/PledgeSeason';
import axios from 'axios'

const useStyles = makeStyles({
  midSection: {
    width: '100%',
    display: 'flex',
    justifyContent:'space-between',
    marginTop: '20px',
  },
  aTag: {
    textDecoration: 'none',
    color: 'blue',
    cursor: 'pointer'
  },
  completedSn: {
    fontWeight: 'bold',
    color: 'green',
    cursor: 'default'
  },
  onGoingSn: {
    fontWeight: 'bold',
    color: 'orange',
    cursor: 'default'
  },
  tooltipContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  pledgeCollectedStyle:{
    display: 'flex',
    alignItems: 'center'
  },
  currencyIconStyle: {
    width: '20px',
    height: '20px',
    marginLeft: '5px'
  }
});

const BlueOnGreenTooltip = withStyles({
  tooltip: {
    color: "#111111",
    backgroundColor: "#ffffff",
    fontSize: '13px',
    width: '500px',
    border: '0.5px solid #898989'
  }
})(Tooltip);

const AcePledging = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const getSeasons = useSelector( state => state.getSeasons)

  const { loading, seasons } = getSeasons

  const [delete_alert, set_delete_alert] = useState(false);
	const [delete_details, set_delete_details] = useState({
		id: '',
		title: ''
	})
  const [showButtons, setShowButtons] = useState(false)
  const [participantsNum, setParticipantsNum] = useState(0)
  const [pledgedStat, setPledgedStat] = useState(0)

  const IS_UAT = window.location.href.includes('-uat');
  const IS_SAT = window.location.href.includes('localhost') || window.location.href.includes('-sat');

  const BASEURL = `${IS_SAT ? "https://dappx-adminapi-sat.dappstore.me/" : (IS_UAT ? "https://dappx-adminapi-uat.dappstore.me/" : "https://dappx-adminapi.dappstore.me/")}`;
  const adminToken = localStorage.getItem("token");
  
  const fetchSeasonStats = async (id) => {
    await axios.get(`${BASEURL}/pledge-seasons/${id}/statistics`,{ headers: {
      'auth-token': adminToken,
    }})
    .then((res) => {
      setParticipantsNum(res.data.participants)
      setPledgedStat(res.data.total_pledge)
    })
    .catch((err) => {
      console.log('ERROR',err)
    })
  }

  const tooltipData = 
    <div>
        <div className={classes.tooltipContainer}>
          <p># of Participants</p>
          <p>{participantsNum}</p>
        </div>
      <div className={classes.tooltipContainer}>
        <p>Total Pledge Collected</p>
        <div className={classes.pledgeCollectedStyle}>
          <p>{pledgedStat}</p>
          <img src={currencyIcon} className={classes.currencyIconStyle}/>
        </div>
      </div>
    </div>

  //Edit and Delete Buttons
  const formatAction = (row) => {
    const { status } = row

    switch (status) {
      case 'completed':
        return( 
          <BlueOnGreenTooltip title={tooltipData} onMouseEnter={() =>fetchSeasonStats(row.id)}>
            <p className={classes.completedSn}>Completed!</p>
          </BlueOnGreenTooltip>
        )
        break

      case 'ongoing':
        return( 
          <BlueOnGreenTooltip title={tooltipData} onMouseEnter={() =>fetchSeasonStats(row.id)}>
            <p className={classes.onGoingSn}>Ongoing</p>
          </BlueOnGreenTooltip>
        )
        break

      default:
        return (
          <div className="action-cell">
            <Button 
              variant="contained" 
              disableElevation 
              color="primary" 
              className="edit-btn" 
              onClick={() => props.onEdit(row)}
            >
              Edit
            </Button>
            <Button 
              variant="contained" 
              disableElevation color="secondary" 
              className="delete-btn" 
              // onClick={() => props.onDelete(row)}
              onClick={() => onOpen_delete_alert(row)}
              >
                Delete
            </Button>
          </div>
        )
        break
    }
	}

  const formatAmount = (amount) => {
    return (
      <>
        {amount}
        <img src={currencyIcon} alt="" style={{ width: '20px', height: '20px', marginLeft: '1rem' }} />
      </>
    )
  }

  const onClose_delete_alert = () => set_delete_alert(false);
	const onOpen_delete_alert = (row) => {
		set_delete_alert(true)
		set_delete_details({
			id: row.id,
			title: row.name
		})
	};

	const onAccept_delete = () => {
		set_delete_alert(false)

    dispatch(
      deletePledgeSeason(delete_details.id)
    );
	}

  const onDenied_delete = () => {
		set_delete_alert(false)
		set_delete_details({
			id: '',
			title: ''
		})
	}

  const cancelChanged =() =>{
    setShowButtons(false)
  }

  const columns = [
    {
        name: 'Season Name',
        selector: row => row.name,
    },
    {
        name: 'Start Date',
        selector: row => formatDate(row.start_date),
    },
    {
        name: 'End Date',
        selector: row => formatDate(row.end_date),
    },
    {
      name: 'Duration',
      selector: row => `${row.duration} ${row.duration === 1 ? 'day' : 'days'}`,
    },
    {
      name: 'Min Pledge',
			cell: row => formatAmount(row.min_amount),
    },
    {
      name: 'Max Pledge',
			cell: row => formatAmount(row.max_amount),
    },
    {
			width: '202px',
			cell: row => formatAction(row),
			button: true
		},
];

const data = seasons.map(item => (
  {
    id: item._id,
    name: item.name,
    start_date: item.start_date,
    end_date: item.end_date,
    duration: item.duration,
    min_amount: item.min_amount,
    max_amount: item.max_amount,
    status: item.status,
  }
));

  return (
    <>
    <div className="content">
    <form>
      <div className={classes.midSection}>
        <p> ACE Pledging Season</p>
        <a className={classes.aTag} onClick={() => props.onAdd()}><b>Add New Season</b></a>
      </div>

      {/* Table Section */}
      <DataTable
				columns={columns}
				data={data}
				sortServer
				progressPending={loading}
				progressComponent={
					<Loader
						appear={loading}
						width="100"
					/>
				}
				persistTableHead
			/>
      {showButtons ? 
      <div className="footer">
        <Button
          disableElevation
          color="primary"
          variant="contained"
          className="submit-btn"
          type="submit"
          // onClick={updatePledgeConfig}
          >
          <span className="text">Save</span>
        </Button>
        <Button
          disableElevation
          variant="contained"
          className="exit-btn"
          onClick={cancelChanged}
          >
          Cancel
        </Button>
      </div>
        : ''}
      <ConditionalModal
      title={delete_details.title}
      description="Are you sure you want to delete this Pledging Season?"
      open={delete_alert}
      onClose={onClose_delete_alert}
      onAccept={onAccept_delete}
      // onAccept={(row) => onAccept_delete(row)}
      onDenied={onDenied_delete}
      acceptBtn_label="Delete"
      />
      </form>
    </div>
    </>
  )
}

export default AcePledging
