import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { ReactComponent as ArrowRight1 } from '../../assets/icons/chevron-right.svg';
import { ReactComponent as ArrowRight2 } from '../../assets/icons/chevron-double-right.svg';
import { ReactComponent as ArrowLeft1 } from '../../assets/icons/chevron-left.svg';
import { ReactComponent as ArrowLeft2 } from '../../assets/icons/chevron-double-left.svg';
import './style.scss';

const Pagination = ({ pageNumber, recordsPerPage, totalRows, setPageNumber, numberedPagesCount }) => {
	const [enableNextPage, setEnableNextpage] = useState(false);
	const [enablePrevPage, setEnablePrevPage] = useState(false);

	const [numberedPages, setNumberedPages] = useState([1]); // for numbered pages

	const nextPage = () => {
		let totalPage = parseInt(totalRows / recordsPerPage) + 1;
		if ((totalRows % recordsPerPage) === 0) totalPage = totalPage - 1;
		if (pageNumber < totalPage) setPageNumber(pageNumber + 1);
	}

	const previousPage = () => {
		if (pageNumber > 1) setPageNumber(pageNumber - 1);
	}

	const gotoPage = (page) => {
		let totalPage = parseInt(totalRows / recordsPerPage) + 1;
		if ((totalRows % recordsPerPage) === 0) totalPage = totalPage - 1;
		if (page <= totalPage && page > 0) setPageNumber(page);
	}

	const lastPage = () => {
		let totalPage = parseInt(totalRows / recordsPerPage) + 1;
		if ((totalRows % recordsPerPage) === 0) totalPage = totalPage - 1;
		setPageNumber(totalPage);
	}

	const firstPage = () => {
		setPageNumber(1);
	}

	useEffect(() => {

		let totalPage = parseInt(totalRows / recordsPerPage) + 1;
		if ((totalRows % recordsPerPage) === 0) totalPage = totalPage - 1;

		if (pageNumber > 1) {
			setEnablePrevPage(true);
		} else {
			setEnablePrevPage(false);
		}

		if (pageNumber < totalPage) {
			setEnableNextpage(true);
		} else {
			setEnableNextpage(false);
		}

		let tmpArr = [];
		let startPageNumber = 1;

		if(pageNumber === totalPage) {
			startPageNumber = pageNumber - (numberedPagesCount-1);
		} else if(pageNumber > 1) {
			let half = parseInt(numberedPagesCount / 2);
			startPageNumber = pageNumber - half;
		}

		if(startPageNumber <= 0) startPageNumber = 1;

		for (let c = 1; c <= numberedPagesCount; c++) {
			let nPage = startPageNumber + (c-1);
			if (nPage <= totalPage) tmpArr.push(nPage);
		}

		setNumberedPages(tmpArr);

	}, [pageNumber, recordsPerPage, totalRows]);

	return (
		<div className="pagination">
			<Button
				className="first-btn"
				disableElevation
				onClick={firstPage}
				disabled={!enablePrevPage}>
				<ArrowLeft2 />
			</Button>
			<Button
				className="prev-btn"
				disableElevation
				onClick={previousPage}
				disabled={!enablePrevPage}>
				<ArrowLeft1 />
			</Button>

			<>
				{numberedPages.map((n) => (
					<Button
						key={n}
						disableElevation
						value={n}
						onClick={() => { setPageNumber(n) }}
						className={pageNumber === n ? 'active' : ''}>
						{n}
					</Button>
				))}
			</>

			<Button
				className="next-btn"
				disableElevation
				onClick={nextPage}
				disabled={!enableNextPage}>
				<ArrowRight1 />
			</Button>
			<Button
				className="last-btn"
				disableElevation
				onClick={lastPage}
				disabled={!enableNextPage}>
				<ArrowRight2 />
			</Button>
			<br />
		</div>
	);
}

export default Pagination;