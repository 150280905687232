import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TableWrapper from '../../table-elements/TableWrapper'
import PageTitle from '../../page-title/PageTitle';
import { Grid, Paper } from '@material-ui/core';
import Pledging from './Edao-Pledging'
import SettingsModal from './SettingsModal';
import Loader from '../../loader/Loader';
import { fetchEdaoMining, deleteEdaoMiningRate} from '../../../actions/EdaoMining'
import { getEdaoSettings } from '../../../actions/EdaoSettings'
import { ConditionalModal } from '../../alerts/Alerts';
import { ReactComponent as MultipleUsers } from '../../../assets/icons/multiple-users.svg';
import axios from 'axios'
import './Widget.scss'

const EdaoPledgingPage = () => {
  const dispatch = useDispatch()
  const [ openModal, setOpenModal] = useState(false)
  const [modalData, setModalData] = useState({})
  const [title, setTitle] = useState('')
  const [addModal, setAddModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [id, setId] = useState('')
  const [totalNitro, setTotalNito] = useState(0)
  const [totalUbi, setTotalUbi] = useState(0)
  const [totalCore, setTotalCore] = useState(0)
  const [totalAce, setTotalAce] = useState(0)


  const getEdao = useSelector(state => state.getEdao)
  const updateEdao = useSelector(state => state.updateEdao)
  const getEdaoMining = useSelector(state => state.getEdaoMining)
  const addEdaoMining = useSelector(state => state.addEdaoMining)
  const updateEdaoMining = useSelector(state => state.updateEdaoMining)
  const deleteEdaoMining = useSelector(state => state.deleteEdaoMining)

  const { loading } = getEdao
  const { updateLoading } = updateEdao
  const { getMiningRateSuccess } = getEdaoMining
  const { addMiningRateSuccess} = addEdaoMining
  const { updateMiningRateSuccess } = updateEdaoMining
  const { deleteSuccess } = deleteEdaoMining

  useEffect(() => {
    dispatch(getEdaoSettings())
    dispatch(fetchEdaoMining())
  },[updateLoading, addMiningRateSuccess, updateMiningRateSuccess, deleteSuccess])

  useEffect(() => {
  const IS_UAT = window.location.href.includes('-uat');
  const IS_SAT = window.location.href.includes('localhost') || window.location.href.includes('-sat');
  const token = localStorage.getItem('token')
  const DAPPX_ADMIN_API = `${IS_SAT ? "https://dappx-adminapi-sat.dappstore.me/" : (IS_UAT ? "https://dappx-adminapi-uat.dappstore.me/" : "https://dappx-adminapi.dappstore.me/")}`;
    axios.get(`${DAPPX_ADMIN_API}edao-gov-pledging/stats`, {headers: { 'auth-token': token}})
    .then((res) => {
      setTotalNito(res.data.total_nitro)
      setTotalUbi(res.data.total_ubi)
      setTotalCore(res.data.total_core)
      setTotalAce(res.data.total_ace)
    })
    .catch((err) => {
      console.log(err)
    })
  },[])
  
  const openDeleteModal = (row) => {
    setDeleteModal(true)
    setTitle(row.startRange)
    setId(row.id)
  }

  const deletePledge = () => {
    dispatch(deleteEdaoMiningRate(id))
    setId('')
    setDeleteModal(false)
    setTitle('')
  }

  const editModal = (row) => {
    setOpenModal(true)
    setModalData(row)
    setTitle('Edit Mining Rate Range')
  }

  const closeModal =() => {
    setOpenModal(false)
  }

  const addPledge = () => {
    setOpenModal(true)
    setTitle('Add New Mining Rate Range')
    setAddModal(!addModal)
  }

  const onModalSubmit = () => {
    setOpenModal(false)
  }

  return (
    <>
      <PageTitle title="EDAO Pledging" hideHistoryBtn hideAddBtn />
      <div className="content">
        <div className='widget-container'>

        <Grid container spacing={1}>
					<Grid item lg={11} md={6} sm={12}>
						<Paper elevation={0} className="card-paper">

							<div className="title">
								<span className="icon"><MultipleUsers /></span>
								Total Collected Nitro
							</div>
							<div className="widget-numbers">
                {totalNitro.toFixed(6).replace(/\.?0+$/, '')}
							</div>

						</Paper>
					</Grid>
				</Grid>

        <Grid container spacing={1}>
					<Grid item lg={11} md={6} sm={12}>
						<Paper elevation={0} className="card-paper">

							<div className="title">
								<span className="icon"><MultipleUsers /></span>
								Total Collected UBI Protocol
							</div>
							<div className="widget-numbers">
                {totalUbi.toFixed(6).replace(/\.?0+$/, '')}
							</div>

						</Paper>
					</Grid>
				</Grid>

        <Grid container spacing={1}>
					<Grid item lg={11} md={6} sm={12}>
						<Paper elevation={0} className="card-paper">

							<div className="title">
								<span className="icon"><MultipleUsers /></span>
								Total Collected Core Ecosystem Dev
							</div>
							<div className="widget-numbers">
                {totalCore.toFixed(6).replace(/\.?0+$/, '')}
							</div>

						</Paper>
					</Grid>
				</Grid>

        <Grid container spacing={1}>
					<Grid item lg={11} md={6} sm={12}>
						<Paper elevation={0} className="card-paper">

							<div className="title">
								<span className="icon"><MultipleUsers /></span>
								Total Accumulated ACE
							</div>
							<div className="widget-numbers">
                {totalAce.toFixed(6).replace(/\.?0+$/, '')}
							</div>

						</Paper>
					</Grid>
				</Grid>
        </div>
      
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<TableWrapper
          body={
            loading || !getMiningRateSuccess ?
            <Loader appear width={50}/>
            :
            <Pledging
             onDelete={openDeleteModal}
             onEdit={editModal}
             onAdd={addPledge}
             />
          }
						/>
					</Grid>
				</Grid>
			</div>
      <SettingsModal
      openEditModal={openModal}
      onClose={closeModal}
      modalTitle={title}
      onSubmit={onModalSubmit}
      data={modalData}
      addModal={addModal}
      />
      <ConditionalModal
        title={title}
        description="Are you sure you want to delete this Pledge Option?"
        open={deleteModal}
        onAccept={deletePledge}
        onDenied={() => setDeleteModal(false)}
        acceptBtn_label="Delete"
      />
    </>
  )
}

export default EdaoPledgingPage
