import React from 'react';
import { TextField, Button } from '@material-ui/core';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import './style.scss';

const Search = ({ placeholder, searchRef, searchExecute }) => {
	return (
		<div className="table-search">
			<form onSubmit={searchExecute}>
				{/* <SearchIcon className="icon"/> */}
				<TextField
					variant='outlined'
					placeholder={placeholder}
					inputRef={searchRef}
				/>
				{/* <Button
					disableElevation
					variant='contained'
					color='primary'
					type="submit"
					>Search</Button> */}
			</form>
		</div>
	);
}

export default Search;