import React, { useContext, useEffect } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import Dashboard from '../components/dashboard/Dashboard';
import Login from '../domain/login/Login';
import Forgot from '../domain/login/Forgot';
import Reset from '../domain/login/Reset';

import AuthProvider from '../context/AuthContext';
import { AuthContext } from '../context/AuthContext';
import moment from 'moment';

import axios from 'axios';
import { API_BASE_URL } from '../constant/config';

import './global.scss';


const PrivateRoute = ({ component: Component, ...rest }) => {
  const { authState, dispatch } = useContext(AuthContext);
  const getBearer = authState.token === null ? null : authState.token;
  const history = useHistory();

  let expires_at = moment(localStorage.getItem('expires_at')).utc().format("YYYY-MM-DD HH:mm:ss");
  let date_now = moment(Date.now()).utc().format("YYYY-MM-DD HH:mm:ss");

  let isExpired = moment.duration(moment(expires_at).diff(moment(date_now))).asHours() <= 0;
 
  // console.log('expires_at', expires_at)
  // console.log('date_now', date_now)
  // console.log('isExpired', isExpired)

  useEffect(() => {
    // (async () => {
    //   if (authState.isAuthenticated && getBearer) {
    //     await axios.post(`${API_BASE_URL}/adminUsers/verifyToken`, {}, {
    //       headers: {
    //         'session-id': getBearer,
    //         'Content-Type': 'application/json',
    //       }
    //     }).then(result => {
    //     }).catch(error => {
    //       dispatch({
    //         type: "LOGOUT"
    //       })
    //       history.push('/login');
    //     });
    //   } else {
    //     dispatch({
    //       type: "LOGOUT"
    //     })
    //     history.push('/login');
    //   }
    // })();
    (async () => {
      if(authState.isAuthenticated && !isExpired) {
        console.log('authenticated')
      } else {
        dispatch({
          type: "LOGOUT"
        })
        history.push('/login');
      }
    })();
  }, [authState.isAuthenticated, isExpired]);

  return (
    <Route {...rest} render={(props) => (
      authState.isAuthenticated
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />
  )
}

const App = () => {
  return (
    <AuthProvider>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/forgot-password" component={Forgot} />
        <Route path="/reset-password" component={Reset} />
        <PrivateRoute path='/' component={Dashboard} />
        <Route path="*" component={() => "404 NOT FOUND"} />
      </Switch>
    </AuthProvider>
  );
}

export default App;