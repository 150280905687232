import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Modal, Backdrop, Zoom, Button, TextField, FormControl, Fade, Select, MenuItem, OutlinedInput } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useHistory } from "react-router-dom";
import { DAPPX_API_BASE_URL } from '../../../constant/config';
import { AuthContext } from '../../../context/AuthContext';
import { DropzoneArea } from 'material-ui-dropzone';
import { ReactComponent as Add } from '../../../assets/icons/add.svg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Loader from '../../../components/loader/Loader';
import FadedContainer from '../../../components/faded-container/FadedContainer';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import ethereum from '../../../assets/images/currency/ethereum.png'
import '../Packages.scss';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const AppsMenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center"
  },
  getContentAnchorEl: null,
  className: 'select-dd'
};
const CurrMenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  getContentAnchorEl: null,
  className: 'select-dd currency-dd'
};

const ModalPackage = ({
  open,
  onClose,
  title,
  data,
  mode,
  refetch
}) => {
  const { authState, dispatch } = useContext(AuthContext);
  let isEdit = mode === 'edit';
  let history = useHistory();

  // LOADER
  const [loading, setLoading] = useState(false);

  // ERROR
  const [err_message, set_err_message] = useState('');
  const [outline_error, set_outline_error] = useState([]);

  const [values, setValues] = useState({
    name: '',
    package: '',
  })

  // SELECT APPLICATION
  const [application_selected, set_application_selected] = useState();
  const [application_data, set_application_data] = useState([]);
  const fetchApplication = () => {
    let getPackage_ApiUrl = `${DAPPX_API_BASE_URL}/project/list`;

    axios.get(getPackage_ApiUrl, { headers: { 'auth-token': authState.token } })
      .then(result => {
        let api_data = result.data.results.data.project;
        set_application_data(api_data)
      })
      .catch(error => {
        console.log(error);
        if (error.response.data.error === "Unauthorized Request") {
          dispatch({
            type: "LOGOUT"
          });
          history.push('/login');
        }
      });
  }

  // SELECT CURRENCY
  const DEFUALT_CURRENCY = [{
    currency: "ETH",
    amount: ""
  }]
  const [currency_data, set_currency_data] = useState(DEFUALT_CURRENCY);

  // IMAGE
  const [imageVal, setImageVal] = useState([]);
  const [initial_image, set_initial_file] = useState([]);
  const [image_remove, set_image_remove] = useState(false);
  const [old_path, set_old_path] = useState();
  const [image_loaded, set_image_loaded] = useState(false);

  const on_image_load = () => {
    setTimeout(() => {
      set_image_loaded(true);
    }, 200)
  }

  // TEXT EDITOR
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const isEditor_hasContent = () => {
    let description_data = convertToRaw(editorState.getCurrentContent()).blocks;
    let desc_arr = []
    description_data.map((i) => {
      let text = i.text;
      if(text.length !== 0){
        desc_arr.push(true)
      } else{
        desc_arr.push(false)
      }
      return null;
    })
    
    let is_all_false = desc_arr.every(f => f === false);

    return is_all_false;
  }
  
  // FUNCTIONS
  const remove_errors = (target) => {
    set_err_message('')
    let update_outline_error = outline_error.filter(id => id !== target)
    set_outline_error(update_outline_error)
  }

  const onChangeVal = (e) => {
    remove_errors(e.target.id)
    setValues({ ...values, [e.target.id]: e.target.value })
  }

  const onChangeImage = (file) => {
    remove_errors('avatar')
    setImageVal(file)
  }

  const onChangeApplication = (event, d) => {
    const { target: { value }, } = event;
    let slected_data = d.props;

    remove_errors('name')
    set_application_selected(slected_data)
    setValues({ ...values, name: value })
  }

  const onChangeAmount = (event, index, obj_key) => {
    const { target: { value }, } = event;
    remove_errors('amount')

    const decimals = value.split('.')[1] || '';

    let items = [...currency_data];
    let item = {...items[index]};
    if (decimals.length <= 6) {
      item[obj_key] = value;
      items[index] = item;
      set_currency_data(items)
    }
  }

  const onEditorStateChange = (e) => {
    let no_content = isEditor_hasContent();
    if(!no_content) remove_errors('description')
    setEditorState(e)
  }

  const closeModal = () => {
    onClose();
    setTimeout(() => {
      setValues({
        name: '',
        package: '',
      })
      setImageVal([])
      set_err_message('')
      set_outline_error([])
      set_initial_file([])
      set_old_path()
      set_image_loaded(false)
      set_image_remove(false)
      set_application_data([])
      set_application_selected()
      setEditorState(EditorState.createEmpty())
      set_currency_data(DEFUALT_CURRENCY)
    }, 400)
  }

  const onSubmit = (e) => {
    let is_name_existing = application_data.find(x => x.name === values.name);
    let is_amount_empty = currency_data.filter(i => i.amount === '');
    let no_content = isEditor_hasContent();

    let required_fields = values.name && values.package && is_name_existing && is_amount_empty.length === 0 && !no_content;

    if (required_fields) {
      if (isEdit) {
        executeEdit()
      } else {
        executeAdd()
      }
    } else {
      let err_arr = [];
      if (!values.name || application_selected === undefined) err_arr.push('name') // application
      if (!values.package) err_arr.push('package') // package
      if (is_amount_empty.length > 0) err_arr.push('amount') // currency
      if (no_content) err_arr.push('description') // description

      set_outline_error(err_arr)
      set_err_message('Please provide the required info!')
    }
  }

  const executeAdd = (e) => {
    setLoading(true)
    let formData = new FormData();
    formData.append('isPublished', false)
    formData.append('name', values.name)
    formData.append('package', values.package)
    formData.append('amount', JSON.stringify(currency_data))
    formData.append('description', draftToHtml(convertToRaw(editorState.getCurrentContent())))
    formData.append('project_id', application_selected.id)
    if (imageVal.length > 0 && imageVal[0].path) {
      formData.append('file', imageVal[0])
    }
    
    axios({
      method: 'post',
      url: `${DAPPX_API_BASE_URL}/package/create`,
      data: formData,
      headers: {
        'auth-token': authState.token,
        'Content-Type': 'multipart/form-data'
      },
    }).then(result => {
      console.log(result)
      setLoading(false)
      if (result.data.error) {
        console.log(result.data)
        console.log(result.data.message)
        if (result.data.message) {
          set_err_message(result.data.message)
        } else {
          set_err_message(`Something went wrong.`)
        }
      } else {
        refetch()
        setTimeout(() => {
          closeModal()
        }, 1000)
      }
    }).catch(error => {
      setLoading(false)
      set_err_message(`Something went wrong.`)
      console.log(error)
      console.log(error.response)
    });
  }

  const executeEdit = (e) => {
    setLoading(true)
    console.log(imageVal[0])
    let formData = new FormData();
    let fileValue = imageVal.length > 0 && imageVal[0].path ? imageVal[0] : undefined;
    formData.append('isPublished', false)
    formData.append('name', values.name)
    formData.append('package', values.package)
    formData.append('amount', JSON.stringify(currency_data))
    formData.append('description', draftToHtml(convertToRaw(editorState.getCurrentContent())))
    formData.append('project_id', application_selected.id)
    formData.append('id', data._id)
    formData.append('file', fileValue)
    formData.append('oldImageRemove', image_remove)
    formData.append('oldPath', old_path)

    console.log('file value: ', fileValue)

    axios({
      method: 'PUT',
      url: `${DAPPX_API_BASE_URL}/package/update`,
      data: formData,
      headers: {
        'auth-token': authState.token,
        'Content-Type': 'multipart/form-data'
      },
    }).then(result => {
      console.log(result)
      setLoading(false)
      if (result.data.error) {
        console.log(result.data.message)
        if (result.data.message) {
          set_err_message(result.data.message)
        } else {
          set_err_message(`Something went wrong.`)
        }
      } else {
        refetch()
        setTimeout(() => {
          closeModal()
        }, 1000)
      }
    }).catch(error => {
      setLoading(false)
      set_err_message(`Something went wrong.`)
      console.log(error)
      console.log(error.response)
    });
  }

  useEffect(() => {
    if(open){
      fetchApplication();

      if (data && isEdit) {
        setValues({
          name: data.name ? data.name : '',
          package: data.package ? data.package : '',
        })

        // currency
        set_currency_data(data.amount)

        // description
        const blocksFromHtml = htmlToDraft(data.description);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const desc_content = EditorState.createWithContent(contentState);
        setEditorState(desc_content);
  
        // image
        if(data.image.length > 0){
          let image_path = data.image[0].path;
          let image_url = `${DAPPX_API_BASE_URL}/${image_path}`;
          set_old_path(image_path)
          set_initial_file([image_url])
        }
      }

      if (isEdit && data.image.length > 0) {
        set_image_loaded(false)
      } else {
        set_image_loaded(true)
      }
    }
  }, [open, data])

  useEffect(() => {
    if(application_data.length > 0 && isEdit){
      let get_selected = application_data.find(x => x.name === data.name);

      if(get_selected !== undefined){
        let get_selected_data = {
          id: get_selected._id,
          value: get_selected.name,
          children: get_selected.name
        }
        set_application_selected(get_selected_data)
      } 
    }
  }, [application_data])

  return (
    <div>
      <Modal
        aria-labelledby={`${mode}-package--title`}
        aria-describedby={`${mode}-package--desc`}
        className={`${mode}-package package-modal custom-scroll`}
        open={open}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        disableBackdropClick
        disableEscapeKeyDown
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Zoom in={open}>
          <div className="paper-wrapper">
            <div className="paper">

              <div className="header">
                <h2 className="title">{title}</h2>
              </div>
              <div className="body">
                <form>

                  <FormControl className="form-control">
                    <label htmlFor="name">Application</label>
                    <Select
                      className="select-apps"
                      fullWidth
                      displayEmpty
                      defaultValue=""
                      IconComponent={ExpandMoreOutlinedIcon}
                      id="name"
                      value={values.name}
                      onChange={onChangeApplication}
                      input={<OutlinedInput error={outline_error.includes("name")} />}
                      MenuProps={AppsMenuProps}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <i className="empty-select">Choose Application</i>;
                        }
                        return selected
                      }}
                    >
                      {application_data.map((i) => (
                        <MenuItem
                          key={i._id}
                          id={i._id}
                          value={i.name}
                        >
                          {i.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl className="form-control">
                    <label htmlFor="package">Package</label>
                    <TextField
                      className="text-field"
                      error={outline_error.includes("package")}
                      value={values.package}
                      onChange={onChangeVal}
                      variant="outlined"
                      id="package"
                      placeholder="Enter Package"
                    />
                  </FormControl>

                  <FormControl className="form-control currency-form">
                    <label htmlFor="amount">Currency</label>

                    {currency_data.length > 0 && currency_data.map((item, index) => {
                      let key = item.currency+(index+1);
                      return(
                        <div className={`block ${index+1}`} key={key}>
                          <Select
                            className="select-currency"
                            IconComponent={ExpandMoreOutlinedIcon}
                            value={currency_data[index].currency}
                            onChange={(e) => onChangeAmount(e, index, 'currency')}
                            MenuProps={CurrMenuProps}
                          >
                            <MenuItem value="ETH">
                              <img src={ethereum} alt="ETH"/> ETH
                            </MenuItem>
                          </Select>
                          <TextField
                            type="number"
                            className="number-field"
                            error={outline_error.includes("amount")}
                            value={currency_data[index].amount}
                            onChange={(e) => onChangeAmount(e, index, 'amount')}
                            variant="outlined"
                            id="amount"
                            placeholder="Enter Amount"
                            onKeyDown={e => (e.keyCode === 69 || e.keyCode === 189 || e.keyCode === 187) && e.preventDefault()}
                          />
                        </div>
                      )})
                    }
                  </FormControl>

                  <FormControl className="form-control description-form">
                    <label>Description</label>
                    <div className="text-editor">
                      <Editor
                        wrapperClassName={`editor-wrapper ${outline_error.includes("description") ? 'error' : ''}`}
                        editorClassName="editor"
                        editorState={editorState}
                        onEditorStateChange={onEditorStateChange}
                        placeholder="Up to 50 characters"
                        toolbar={{
                          options: ['inline', 'list'],
                          inline: {
                            options: ['bold', 'italic', 'underline'],
                          },
                          list: {
                            options: ['unordered', 'ordered'],
                          }
                        }}
                      />
                      {/* FOR CHECKING */}
                      {/* <textarea
                        disabled
                        value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                      /> */}
                    </div>
                  </FormControl>

                  <FormControl className="form-control upload-form">
                    <label>Image</label>
                    <img src={initial_image} className="d-none" onLoad={on_image_load} alt="no-preview" />
                    <FadedContainer
                      loading={!image_loaded}
                      duration={.2}
                      delay={.2}
                      loader={
                        <Loader appear={true} width="30" thick />
                      }>
                      <div className="dropzone-wrapper">
                        <DropzoneArea
                          // maxFileSize={104857600} // bytes in binary 100
                          // maxFileSize={103809024} // bytes in binary 99
                          maxFileSize={100000000}
                          initialFiles={initial_image}
                          dropzoneClass={`drop-image ${outline_error.includes("avatar") ? 'error' : ''}`}
                          previewGridClasses={{
                            container: "priview-container",
                            item: "priview-item",
                          }}
                          Icon={Add}
                          filesLimit={1}
                          acceptedFiles={['.jpg, .jpeg, .png, .bmp']}
                          dropzoneText={`Package Banner`}
                          onChange={(file) => onChangeImage(file)}
                          getFileAddedMessage={() => `Image successfully attached.`}
                          getDropRejectMessage={(file_data, accepted_file, max_file) => {
                            console.log(file_data, accepted_file, max_file)
                            if(file_data.size > max_file){
                              return 'File was rejected. File is too big. Size limit is 100 megabytes.'
                            } else {
                              return 'File was rejected. File type not supported.'
                            }
                          }}
                          getFileRemovedMessage={() => {
                            set_image_remove(true)
                            return `Image removed.`
                          }}
                        />
                        <p className="additional-text">JPEG, PNG, or BMP only. Max 100mb</p>
                      </div>
                    </FadedContainer>
                  </FormControl>

                </form>
              </div>
              <div className="footer">
                <Button
                  disableElevation
                  color="primary"
                  variant="contained"
                  className="submit-btn"
                  type="submit"
                  onClick={onSubmit}
                  disabled={loading ? true : false}>
                  <span className="text">Save</span>
                  <Loader
                    appear={loading}
                    timeout={1000}
                    thick
                    width="22"
                    coloredBg
                    svgProps={{
                      absolute: true,
                      direction: "right",
                      negative: true,
                    }}
                  />
                </Button>
                <Button
                  disableElevation
                  variant="contained"
                  className="exit-btn"
                  onClick={closeModal}
                  disabled={loading ? true : false}>
                  Exit
                </Button>

                {err_message &&
                  <Fade className="error-message" in={err_message ? true : false} timeout={500} >
                    <Alert severity="error" variant="outlined">{err_message}</Alert>
                  </Fade>
                }
              </div>
            </div>
          </div>
        </Zoom>
      </Modal>
    </div>
  );
}

export default ModalPackage;