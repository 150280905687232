import React, {useState, useEffect} from 'react';
import {Button, Modal, FormControl, TextField, makeStyles, Fade} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Loader from '../../loader/Loader';
import { executeConnect } from '../../../api/web3'
import { DAPPX_API_BASE_URL } from '../../../constant/config'
import axios from 'axios'
import '../Settings.scss'

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '400px',
    bgcolor: 'background.paper',
    border: '1px solid grey',
    borderRadius: '20px',
    boxShadow: 24,
    p: 4,
    backgroundColor: 'white',
    [`& .paper`]: {
      minHeight: 'unset'
    },
    [`& .paper-wrapper`]: {
      minHeight: 'unset'
    },
    [`& .table-wrapper`]: {
      marginTop: '0',
      [`& .table-container`]: {
        paddingTop: '0'
      },
      [`& .header`]: {
        marginBottom: 0,
        padding: '16px',
        justifyContent: 'space-between'
      },
    },
  },
  textFieldContainer: {
    position: 'relative',
    display: 'inline-block'
  },
  textFieldImg: {
    position: 'absolute',
    right: 10,
    top: 17,
    width: 20,
    height: 20
  },
  TextFieldBorder: {
    [`& fieldset`]: {
      borderRadius: 20,
      width: 690,
      height: 40,
      paddingBottom: 7,
      marginTop: '7px',
      display: 'flex',
      alignItems: 'flex-start',
    },
  width: '700px',
  }
});

const AddWalletModal= (props) =>{
  const classes = useStyles()
  const token = localStorage.getItem('token')

  const [wallets, setWallets] = useState({
    wallet_1: {
      value: '',
      label: 'Wallet Address #1'
    }
  })

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  const handleWalletFieldChange = (e) => {
    setWallets({ 
      ...wallets,
      ...{[e.target.id]: {...wallets[e.target.id], ...{value: e.target.value}}}
    })
  }

  const onClose = () => {
    setWallets({
      wallet_1: {
        value: '',
        label: 'Wallet Address #1'
      }
    })
    setSuccess(false);
    setError(false);
    setLoading(false)
    props.onClose()
  }

  const executeAdd = async(walletAddresses, account) => {
    try {
      const addwallet = props.withdrawAce || props.withdrawDappx?
       await props.deployedContract.methods.addWallets(walletAddresses).send({from:account})
       :
       await props.deployedContract.methods.addVaultAddress(walletAddresses).send({from:account})
     if(addwallet) {
        const data ={
          action: 'add',
          wallet: walletAddresses
        }
        await axios.post(`${DAPPX_API_BASE_URL}/ace-withdrawal/log-hot-wallet-action`, data, {headers: { 'auth-token': token}})
        .then(() => {
          setLoading(false)
          setSuccess('Success!')
          setError('')
          props.refetch()
        })

      setLoading(false)
      setSuccess('Success!')
      setError('')
      props.refetch()
     }
    } catch (error) {
      console.log('Error', error)
      if(error.code == 4001) {
        setLoading(false)
        setError('User denied transaction')
      } else {
        setLoading(false)
        setError('Error!')
        console.log('error', error)
      }
    }
  }

  const submitModal = async(e) => {
    e.preventDefault();

    setLoading(true);
    executeConnect() 
    const walletAddresses = Object.values(wallets).map((item) => (
      item.value
    ))

    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });

    if(!accounts) {
      setLoading(false)
      setError('Please connect your wallet!')
    } else if( walletAddresses.length == 0) {
      setLoading(false)
      setError('Please Add Wallets')
    } else {
      executeAdd(walletAddresses, accounts[0])
    }
  }

  const handleAddField = () => {
    let fieldCount = Object.keys(wallets).length;

    setWallets({ 
      ...wallets, 
      ...{
        [`wallet_${fieldCount + 1}`]: {
          value: '',
          label: `Wallet Address #${fieldCount + 1}`
        }
      }
    });
  }

  return (
    <>
      <Modal
        open={props.modal}
        className={'add-wallet-modal'}
      >
          <div className={classes.root}>
            <div className="paper-wrapper">
              <div className="paper">
                <div className="header">
                  <h2 className="title">Add New Hot Wallet</h2>
                </div>
                <form>
                  <div className="textfield-item-container">
                      {Object.keys(wallets).map( field => (
                        <FormControl className="add-wallet-form-control">
                          <label className="form-label" htmlFor="package">{wallets[field].label}</label>

                          <div className={classes.textFieldContainer}>
                            <TextField variant="outlined" className={classes.TextFieldBorder} value={wallets[field].value} id={field} onChange={handleWalletFieldChange} placeholder="Enter Hot Wallet Address"/>
                          </div>
                        </FormControl>
                      ))}
                  </div>

                  <Button
                    disableElevation
                    color="primary"
                    variant="contained"
                    className="submit-btn"
                    onClick={handleAddField}
                    >
                    <span className="text">+</span>
                  </Button>
                </form>
                <div className="footer">
                  <div className="button-container">
                    <Button
                      disableElevation
                      color="primary"
                      variant="contained"
                      className="submit-btn"
                      type="submit"
                      onClick={submitModal}
                      disabled={loading ? true : false}>
                      <span className="text">Save</span>
                      <Loader
                        appear={loading}
                        timeout={1000}
                        thick
                        width="22"
                        coloredBg
                        svgProps={{
                          absolute: true,
                          direction: "right",
                          negative: true,
                        }}
                      />
                    </Button>
                    <Button
                      disableElevation
                      variant="contained"
                      className="exit-btn"
                      onClick={onClose}
                      >
                      Cancel
                    </Button>
                  </div>

                  {error &&
                    <Fade className="error-message" in={error ? true : false} timeout={500} >
                      <Alert severity="error" variant="outlined">{error}</Alert>
                    </Fade>
                  }
                  {success &&
                    <Fade className="success-message" in={success ? true : false} timeout={500} >
                      <Alert severity="success" variant="outlined">{success}</Alert>
                    </Fade>
                  }
                </div>
              </div>
            </div>
          </div>
      </Modal>
    </>
  );
}

export default AddWalletModal