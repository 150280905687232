import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import Sidebar from '../../components/sidebar/Sidebar';
import MainSlider from './main-slider/MainSlider';
import LouiesPick from './main-louies-pick/LouiesPick';

const Banners = ({ history, match }) => {

  const menu = [
    {
      id: "project-management",
      label: "Project Management",
      icon: '',
      nested: [
        {
          id: `${match.url}--project-management-main-slider`,
          label: "Main | Rolling Banner",
          href: `${match.url}/project-management-main-slider`,
          icon: '',
        },
        {
          id: `${match.url}--project-management-main-louies-pick`,
          label: "Main | Louie's Pick",
          href: `${match.url}/project-management-main-louies-pick`,
          icon: '',
        },
        {
          id: `${match.url}--project-management-hot-dApp`,
          label: "What's Hot dApp",
          href: `${match.url}/project-management-hot-dApp`,
          icon: '',
          disabled: true
        },
        {
          id: `${match.url}--project-management-hot-dApp-long`,
          label: "What's Hot dApp (Long)",
          href: `${match.url}/project-management-hot-dApp-long`,
          icon: '',
          disabled: true
        },
        {
          id: `${match.url}--project-management-game-slider`,
          label: "Game | Rolling",
          href: `${match.url}/project-management-game-slider`,
          icon: '',
          disabled: true
        },
        {
          id: `${match.url}--project-management-game-banner-a`,
          label: "Game | Banner A",
          href: `${match.url}/project-management-game-banner-a`,
          icon: '',
          disabled: true
        },
        {
          id: `${match.url}--project-management-game-banner-b`,
          label: "Game | Banner B",
          href: `${match.url}/project-management-game-banner-b`,
          icon: '',
          disabled: true
        },
        {
          id: `${match.url}--project-management-game-banner-c`,
          label: "Game | Banner C",
          href: `${match.url}/project-management-game-banner-c`,
          icon: '',
          disabled: true
        }
      ]
    },
    {
      id: "ads-management",
      label: "Ads Management",
      icon: '',
      nested: [
        {
          id: `${match.url}--ads-management-main-slider`,
          label: "Main | Rolling Banner",
          href: `${match.url}/ads-management-main-slider`,
          icon: '',
        },
        {
          id: `${match.url}--ads-management-main-louies-pick`,
          label: "Main | Louie's Pick ",
          href: `${match.url}/ads-management-main-louies-pick`,
          icon: '',
        },
        {
          id: `${match.url}--ads-management-hot-dApp`,
          label: "What's Hot dApp",
          href: `${match.url}/ads-management-hot-dApp`,
          icon: '',
          disabled: true
        },
        {
          id: `${match.url}--ads-management-hot-dApp-long`,
          label: "What's Hot dApp (Long)",
          href: `${match.url}/ads-management-hot-dApp-long`,
          icon: '',
          disabled: true
        },
        {
          id: `${match.url}--ads-management-game-slider`,
          label: "Game | Rolling",
          href: `${match.url}/ads-management-game-slider`,
          icon: '',
          disabled: true
        },
        {
          id: `${match.url}--ads-management-game-banner-a`,
          label: "Game | Banner A",
          href: `${match.url}/ads-management-game-banner-a`,
          icon: '',
          disabled: true
        },
        {
          id: `${match.url}--ads-management-game-banner-b`,
          label: "Game | Banner B",
          href: `${match.url}/ads-management-game-banner-b`,
          icon: '',
          disabled: true
        },
        {
          id: `${match.url}--ads-management-game-banner-c`,
          label: "Game | Banner C",
          href: `${match.url}/ads-management-game-banner-c`,
          icon: '',
          disabled: true
        },
      ]
    },
  ]

	useEffect(() => {
		history.push('/banners/project-management-main-slider');
	}, []);

  return (
    <div className="page-wrapper banners-page">
      <Sidebar menu={menu} initialOpen={'project-management'}/>
      <div className="page-content custom-scroll">
        <Switch>
          <Route exact path={`${match.path}/project-management-main-slider`} component={MainSlider} />
          <Route exact path={`${match.path}/project-management-main-louies-pick`} component={LouiesPick} />
        </Switch>
      </div>
    </div>
  );
}
 
export default Banners;