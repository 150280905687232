import React, { useState } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../../constant/config';
import { useHistory } from 'react-router-dom';
import { InputLabel, Box, FormGroup, OutlinedInput, Button, Fade } from '@material-ui/core';
import { Alert } from '@material-ui/lab'
import { ReactComponent as Logo } from '../../assets/images/dappstore-logo.svg';
import Loader from '../../components/loader/Loader';
import { SuccessModal, ErrorModal } from '../../components/alerts/Alerts'
import './Login.scss';

const Reset = () => {
  let history = useHistory();
  let token = history.location.search.split('=')[1];

  
  // ALERTS START =================================

  // Loading
  const [loading_alert, set_loading_alert] = useState(false);
  const onClose_loading_alert = () => set_loading_alert(false);
  const onOpen_loading_alert = () => set_loading_alert(true);

  // Success
  const [success_alert, set_success_alert] = useState(false);
  const onClose_success_alert = () => {
    set_success_alert(false);
    setTimeout(() => {history.push('/')},500);
  }
  const onOpen_success_alert = () => set_success_alert(true);

  // Error modal
  const [error_alert, set_error_alert] = useState(false);
  const [expired_token, set_expired_token] = useState(false);
  const [error_message, set_error_message] = useState();
  const onClose_error_alert = () => {
    set_error_alert(false);
    setTimeout(() => {
      set_error_message();
    }, 2000)
  };
  const onOpen_error_alert = () => set_error_alert(true);

  // Error
  const [error_alert_pop, set_error_alert_pop] = useState();

  // ALERTS END =================================

  const [values, setValues] = useState({
    password: '',
    confirm_password: '',
  })

  const onChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    onOpen_loading_alert()
    if(values.password && values.confirm_password){
      if(values.password === values.confirm_password){
        executeSave()
      } else {
        setTimeout(()=>{
          onClose_loading_alert()
          onOpen_error_alert()
          set_error_message('Password does not match!')
        }, 1000)
      }
    } else {
      setTimeout(()=>{
        onClose_loading_alert()
        onOpen_error_alert()
        set_error_message('Password should not be empty.')
      }, 1000)
    }
  }

  const executeSave = () => {
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/reset-password?token=${token}`,
      data: {
        password: values.password
      }
    }).then(res => {
      let payload = res.data.results;
      console.log(res)
      if(res){
        setTimeout(()=>{
          console.log(payload)
          onOpen_success_alert()
        }, 1000)
      }
    }).catch(error => {
      console.log(error);
      console.log(error.response);
      let message = error.response.data.message;
      setTimeout(() => {
        if(message){
          set_error_message(message)
          if(message.includes('Expired')){
            set_expired_token(true)
          }
        }
        onClose_loading_alert()
        onOpen_error_alert()
      }, 1000)
    });
  }

  const redirect_to_forgot = () => {
    setTimeout(() => {
      history.push('/forgot-password')
      set_expired_token(false)
    },500);

    onClose_error_alert()
  }


  let empty_fields = !values.password || !values.confirm_password;

  return (
    <div className="page-wrapper reset-password-page">
      <Box className="form-box">
        <Logo className="dappstore-logo mb-32"/>
        {/* <Fade in={error_alert_pop ? true : false} timeout={500} ><Alert severity="error">{error_alert_pop}</Alert></Fade> */}
        <form onSubmit={onSubmit}>
          <FormGroup className="mb-34">
            <InputLabel htmlFor="password-field">New Password</InputLabel>
            <OutlinedInput
              className="form-input mb-6"
              id="password-field"
              type="password"
              placeholder="Password"
              value={values.password}
              onChange={onChange('password')}
              labelWidth={0}
            />
            <OutlinedInput
              className="form-input"
              id="password-field"
              type="password"
              placeholder="Confirm Password"
              value={values.confirm_password}
              onChange={onChange('confirm_password')}
              labelWidth={0}
            />
          </FormGroup>
          <Button
            className={`btn-submit ${empty_fields ? 'empty-fields' : ''}`}
            variant="contained"
            color="primary"
            type="submit"
            disableElevation
            disabled={loading_alert || empty_fields ? true : false}>
            <span className="text">Save</span>
            <Loader 
              appear={loading_alert} 
              timeout={250} 
              thick 
              width="22" 
              coloredBg 
              svgProps={{
                absolute: true,
                direction: "right",
                negative: true,
              }} 
            />
          </Button>
        </form>
      </Box>

      <SuccessModal 
        description={<>
          Your password is now changed! Please proceed to login.
        </>}
        open={success_alert}
        onClose={onClose_success_alert}
      />

      <ErrorModal
        description={`${error_message ? error_message : 'Something went wrong.'}`}
        open={error_alert}
        btnLabel={error_message ? error_message.includes('match') ? 'Go Back' : 'OK' : 'OK'}
        onClose={expired_token ? redirect_to_forgot : onClose_error_alert}
      />
    </div>
  );
}
 
export default Reset;