import axios from "axios";

const IS_UAT = window.location.href.includes('-uat');
const IS_SAT = window.location.href.includes('localhost') || window.location.href.includes('-sat');
const token = localStorage.getItem('token')

const DAPPX_ADMIN_API = `${IS_SAT ? "https://dappx-adminapi-sat.dappstore.me/" : (IS_UAT ? "https://dappx-adminapi-uat.dappstore.me/" : "https://dappx-adminapi.dappstore.me/")}`;

export const getEdaoSettings = () => {
  return async (dispatch) => {
    dispatch({ type: "EDAO_LIST_REQUEST" });
    
    const res = await axios.get(`${DAPPX_ADMIN_API}edao-gov-pledging/settings`, { headers: { 'auth-token': localStorage.getItem('token') } })
    if (res.error) {
      dispatch({
        type: "EDAO_LIST_FAIL",
        payload: res.data,
      });
    } else {
      dispatch({
        type: "EDAO_LIST_SUCCESS",
        payload: res.data,
      });
    }
  };
};

export const updateEdaoSettings = (data) => {
  return async (dispatch) => {
    dispatch({ type: "EDAO_UPDATE_REQUEST" });

    const res = await axios.post(`${DAPPX_ADMIN_API}edao-gov-pledging/settings`, data, { headers: { 'auth-token': token } })
    if (res.error) {
      dispatch({
        type: "EDAO_UPDATE_FAIL",
        payload: res.data,
      });
    } else {
      dispatch({
        type: "EDAO_UPDATE_SUCCESS",
        payload: res.data,
      });
    }
  };
};
