import React from 'react';
import { Modal, Backdrop, Zoom, Button } from '@material-ui/core';
import { ReactComponent as ErrorIcon } from '../../assets/icons/alert-warning.svg'

import './Error.scss'

const Error = ({
  className,
  title,
  description,
  open,
  onClose,
  btnLabel
}) => {

  return (
    <div>
      <Modal
        aria-labelledby="error-title"
        aria-describedby="error-desc"
        className={`error-modal custom-scroll alert-modals ${className ? className : ''}`}
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        disableBackdropClick
        disableEscapeKeyDown
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Zoom in={open}>
          <div className="paper-wrapper">

            <div className="paper">
              <ErrorIcon className="icon" />
              <h2 id="error-title" className="title">
                {title ? title : 'Oops...'}
              </h2>
              {description &&
                <p id="error-desc" className="desc">
                  {description}
                </p>
              }
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={onClose}>
                {btnLabel ? btnLabel : 'OK'}
              </Button>
            </div>

          </div>
        </Zoom>
      </Modal>
    </div>
  );
}

export default Error;