import React, {useState, useEffect} from 'react';
import Web3 from 'web3'
import { useDispatch, useSelector } from 'react-redux';
import {Button, Modal, FormControl, TextField, makeStyles} from '@material-ui/core';
import dApxImg from '../../../assets/images/currency/dappx.png'
import { postPledgesMining } from '../../../actions/PledgeMining'
import '../Settings.scss'

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '400px',
    minHeight: '400px',
    bgcolor: 'background.paper',
    border: '1px solid grey',
    borderRadius: '20px',
    boxShadow: 24,
    p: 4,
    backgroundColor: 'white'
  },
  textFieldContainer: {
    position: 'relative',
    display: 'inline-block'
  },
  textFieldImg: {
    position: 'absolute',
    right: -390,
    top: 17,
    width: 20,
    height: 20
  },
  TextFieldBorder: {
    [`& fieldset`]: {
      borderRadius: 20,
      width: 690,
      height: 40,
      paddingBottom: 7,
      marginTop: '7px',
      display: 'flex',
      alignItems: 'flex-start',
    },
  width: '300px',
  }
});

const SettingsModal= (props) =>{
  const dispatch = useDispatch()
  const classes = useStyles()

  const getMinings = useSelector( state => state.getMinings)
  const { minings } = getMinings

  const [id, setId] = useState('')
  const [startRange, setStartRange] = useState('')
  const [endRange, setEndRange] = useState('')
  const [miningRate, setMiningRate] = useState('')

  useEffect(() => {
    setId(props.data?.id)
    setStartRange(props.data?.startRange)
    setEndRange(props.data?.endRange)
    setMiningRate(props.data?.miningRate)

  },[props.data])

  const startRangeChange = (e) => {
    setStartRange(e.target.value)
  }

  const endRangeChange = (e) => {
    setEndRange(e.target.value)
  }

  const miningChange = (e) => {
    setMiningRate(e.target.value)
  }

  const submitModal = (e) => {
    e.preventDefault();

    let newMinings = [...minings]
    const data = newMinings.filter(item => item.id !== id)

    data.push({
      id:id,
      start_range: startRange,
      end_range: endRange,
      mining_rate: miningRate
    })


    if(props.modalTitle.includes('Add')) {
              newMinings.push({
          start_range: startRange,
          end_range: endRange,
          mining_rate: miningRate
        })
        dispatch(postPledgesMining(newMinings))
    } else {
      dispatch(postPledgesMining(data))
    }
    props.onSubmit()
  }

  return (
    <>
      <Modal
        open={props.openEditModal}
      >
          <div className={classes.root}>
          <div className="paper-wrapper">
            <div className="paper">

              <div className="header">
                <h2 className="title">{props.modalTitle}</h2>
              </div>
                <form>
              <div className="textfield-item-container">
                  <FormControl className="edit-form-control">
                    <label className="form-label" htmlFor="package">Start Range</label>

                    <div className={classes.textFieldContainer}>
                      <img src={dApxImg} alt="" className={classes.textFieldImg}/>
                      <TextField variant="outlined" className={classes.TextFieldBorder} value={startRange} onChange={startRangeChange}/>
                    </div>
                  </FormControl>

                  <FormControl className="edit-form-control">
                    <label className="form-label" htmlFor="package">End Range</label>
                    <div className={classes.textFieldContainer}>
                      <img src={dApxImg} alt="" className={classes.textFieldImg}/>
                      <TextField variant="outlined" className={classes.TextFieldBorder} value={endRange} onChange={endRangeChange}/>
                    </div>
                  </FormControl>
                  <FormControl className="edit-form-control">
                    <label className="form-label" htmlFor="package">Mining Rate</label>
                    <div className={classes.textFieldContainer}>
                      <img src={dApxImg} alt="" className={classes.textFieldImg}/>
                      <TextField variant="outlined" className={classes.TextFieldBorder} value={miningRate} onChange={miningChange}/>
                    </div>
                  </FormControl>
              </div>
                </form>
              <div className="footer">
                <Button
                  disableElevation
                  color="primary"
                  variant="contained"
                  className="submit-btn"
                  type="submit"
                  onClick={submitModal}
                  >
                  <span className="text">Save</span>
                </Button>
                <Button
                  disableElevation
                  variant="contained"
                  className="exit-btn"
                  onClick={props.onClose}
                  >
                  Exit
                </Button>
              </div>
            </div>
          </div>
          </div>
      </Modal>
    </>
  );
}

export default SettingsModal