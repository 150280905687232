export const typeListRedcuer = (state = { types: [] }, action) => {
  switch (action.type) {
    case "TYPE_LIST_REQUEST":
      return { loading: true, types: [] };
    case "TYPE_LIST_SUCCESS":
      return { loading: false, typeSuccess: true, types: action.payload };
    case "TYPE_LIST_FAIL":
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const typeUpdateRedcuer = (state={}, action) => {
  switch (action.type) {
    case "TYPE_UPDATE_REQUEST":
      return { updateLoading: true, tickets: [] };
    case "TYPE_UPDATE_SUCCESS":
      return { updateLoading: false, updateSuccess: true, typetInfo: action.payload };
    case "TYPE_UPDATE_FAIL":
      return { updateLoading: false, updateError: action.payload };
    default:
      return state;
  }
};

export const typeDeleteReducer = (state = { Type: {} }, action) => {
  switch (action.type) {
    case "TYPE_DELETE_REQUEST":
      return { DeleteLoading: true, type: {} };
    case "TYPE_DELETE_SUCCESS":
      return { DeleteLoading: false, deleteSuccess: true, typeInfo: action.payload };
    case "TYPE_DELETE_FAIL":
      return { DeleteLoading: false, deleteError: action.payload };
    default:
      return state;
  }
};



export const typeCreateReducer = (state=[], action) => {
  switch (action.type) {
      case 'TYPE_CREATE_REQUEST': 
      return {
        ...state,
        createLoading: true,
        createSuccess: false
      };
      case "TYPE_CREATE_SUCCESS":
        return { 
          ...state,
          createLoading: false,
          createSuccess: true,
          typeInfo: action.payload };
      case "TYPE_CREATE_FAIL":
        return { 
          ...state,
          createLoading: false,
          createError: action.payload
        };
      default: 
          return state
  }
}