export const formatDate = (dateString) => {
  const dateObj = new Date(dateString)

  const month = dateObj.toLocaleString('default', { month: 'short' })
  const date = dateObj.getDate()
  const year = dateObj.getFullYear()

  return `${month} ${date}, ${year}`
}

export const formatDateYearly = (dateString) => {
  const dateObj = new Date(dateString)

  const month = dateObj.toLocaleString('default', { month: 'short' })
  const date = dateObj.getDate()
  const year = dateObj.getFullYear()

  return `${year}-${month}-${date}, `
}