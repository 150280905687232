import React, { useState } from 'react';
import { Link as RouterLink, useHistory } from "react-router-dom";
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Collapse } from "@material-ui/core";
// import ExpandLess from '@material-ui/icons/ExpandLess';
// import ExpandMore from '@material-ui/icons/ExpandMore';
import './Sidebar.scss'

const Sidebar = ({ menu, initialOpen }) => {
  let history = useHistory();
  let pathname = history.location.pathname ? history.location.pathname : '/404';

  const [open, setOpen] = useState(initialOpen);

  const onToggleOpen = (id) => {
    if(id === open){
      setOpen()
    } else{
      setOpen(id)
    }
  };

  const IconLabel = ({ icon, label }) => (
    <>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      {label && <ListItemText primary={label} />}
    </>
  )

  const drawer = (
    <List className="menu-list">
      {menu.map((val) => (
        val.href
          ? <li key={val.id.replace(/\//g, "")} className={`list-item ${pathname === val.href ? 'active' : '' }`}>
              <ListItem
                button
                component={RouterLink}
                to={val.href}
                disabled={val.disabled}>
                <IconLabel icon={val.icon} label={val.label}/>
              </ListItem>
            </li>
          : <li key={val.id} className={`list-item collapsible-item ${open === val.id ? 'active' : '' }`}>
              <ListItem
                button
                onClick={() => onToggleOpen(val.id)}>
                <IconLabel icon={val.icon} label={val.label}/>
                {/* {open === val.id ? <ExpandLess /> : <ExpandMore />} */}
              </ListItem>
              <Collapse in={open === val.id} timeout="auto" unmountOnExit>
                <List component="div" disablePadding className="nested-list">
                  {val.nested.map((nestedVal) => (
                    <ListItem
                      button
                      key={nestedVal.id.replace(/\//g, "")}
                      component={RouterLink}
                      to={nestedVal.href}
                      className={`${pathname === nestedVal.href ? 'active-nested' : '' }`}
                      disabled={nestedVal.disabled}>
                      <IconLabel icon={nestedVal.icon} label={nestedVal.label}/>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </li>
      ))}
    </List>
  );

  return (
    <nav className="page-sidebar">
      <Drawer
        classes={{
          paper: "drawer-paper custom-scroll",
        }}
        variant="permanent"
        open
      >
        {drawer}
      </Drawer>
    </nav>
  );
}

export default Sidebar;