import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  ticketListRedcuer,
  rewardStatusReducer,
  rewardsUpdateRedcuer,
  ticketUpdateRedcuer,
  ticketDeleteReducer,
  ticketCreateReducer
} from "./reducers/TicketsReducer";
import {
  setuptListRedcuer,
  setupUpdateRedcuer,
  setupDeleteReducer,
  setupCreateReducer,
} from "./reducers/TicketsSetupReducers";
import {
  typeListRedcuer,
  typeUpdateRedcuer,
  typeDeleteReducer,
  typeCreateReducer
} from "./reducers/TypeReducers"
import {
  getMiningssReducer,
  updateMiningssReducer
} from "./reducers/PledgeMiningReducers"
import {
  getPledgesReducer,
  updatePledgesReducer,
} from "./reducers/PledgesReducers"
import {
  getSeasonsReducer,
  addSeasonReducer,
  updateSeasonReducer,
  deleteSeasonReducer,
} from "./reducers/PledgeSeasonReducer"
import {
  getGovernanceReducer,
  updateGovernanceReducer
} from './reducers/GovernanceSettingsReducer'
import {
  getGovernanceMiningReducer,
  addGovernanceMiningReducer,
  updateGovernanceMiningReducer,
  deleteGovernanceMiningReducer
} from './reducers/GovernanceMiningReducer'
import {
  getEdaoReducer,
  updateEdaoReducer
} from './reducers/EdaoSettingsReducer'
import {
  getEdaoMiningReducer,
  addEdaoMiningReducer,
  updateEdaoMiningReducer,
  deleteEdaoMiningReducer
} from './reducers/EdaoMiningReducer'

const reducer = combineReducers({
  ticketList: ticketListRedcuer,
  rewardsStatus: rewardStatusReducer,
  rewardsUpdate: rewardsUpdateRedcuer,
  ticketUpdate: ticketUpdateRedcuer,
  ticketDelete: ticketDeleteReducer,
  ticketCreate: ticketCreateReducer,
  setupList: setuptListRedcuer,
  setupUpdate: setupUpdateRedcuer,
  setupDelete: setupDeleteReducer,
  setupCreate: setupCreateReducer,
  ticketType: typeListRedcuer,
  typeUpdate: typeUpdateRedcuer,
  typeDelete: typeDeleteReducer,
  typeCreate: typeCreateReducer,
  getMinings: getMiningssReducer,
  getSeasons: getSeasonsReducer,
  addSeason: addSeasonReducer,
  updateSeason: updateSeasonReducer,
  updateMinings: updateMiningssReducer,
  pledgesList: getPledgesReducer,
  updatePledges: updatePledgesReducer,
  deleteSeason: deleteSeasonReducer,
  getGovernance: getGovernanceReducer,
  updateGovernance: updateGovernanceReducer,
  getGovernanceMining: getGovernanceMiningReducer,
  addGovernanceMining: addGovernanceMiningReducer,
  updateGovernanceMining: updateGovernanceMiningReducer,
  deleteGovernanceMining: deleteGovernanceMiningReducer,
  getEdao: getEdaoReducer,
  updateEdao: updateEdaoReducer,
  getEdaoMining: getEdaoMiningReducer,
  addEdaoMining: addEdaoMiningReducer,
  updateEdaoMining: updateEdaoMiningReducer,
  deleteEdaoMining: deleteEdaoMiningReducer
});

const userInfoFromStorage = localStorage.getItem("token");

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
  isAuthenticated: localStorage.getItem("token") ? true : false,
  token: localStorage.getItem("token"),
  expires_at: localStorage.getItem("expires_at"),
  user: {
    email: localStorage.getItem("email"),
    role: localStorage.getItem("role"),
  },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
