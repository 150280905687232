import React, { useState, useEffect } from "react";
import { Button, makeStyles } from "@material-ui/core";
import DataTable from "react-data-table-component";
import Loader from "../../../loader/Loader";
import { ConditionalModal } from "../../../alerts/Alerts";
import { useDispatch, useSelector } from "react-redux";
import { deleteTicket } from "../../../../actions/TicketsActions";
import { listTicketsSetup } from "../../../../actions/TicketsSeupActions";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    "& .myIcon": {
      paddingLeft: "8px",
      order: 999,
    },
  },
  textFieldContainer: {
    position: "relative",
    display: "inline-block",
  },
  textFieldImg: {
    position: "absolute",
    right: 10,
    top: 10,
    width: 20,
    height: 20,
  },
  primaryGrid: {
    margin: "8px",
  },
  TextFieldBorder: {
    [`& fieldset`]: {
      borderRadius: 20,
    },
    width: "300px",
  },
  midSection: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
  },
  aTag: {
    textDecoration: "none",
    color: "blue",
    cursor: "pointer",
  },
  buttonsHolder: {
    marginTop: "50px",
    display: "flex",
    justifyContent: "center",
  },
  ticketSetup: {
    marginBottom: "50px",
  },
});

const Tickets = (props) => {
  const classes = useStyles();
  const disptach = useDispatch();
  // const [loading, setLoading] = useState(true);
  const [delete_alert, set_delete_alert] = useState(false);
  const onClose_delete_alert = () => set_delete_alert(false);
  const [disabledButton, setDisabledButton] = useState(false)
  const [delete_details, set_delete_details] = useState({
    id: "",
    name: "",
  });

  const ticketList = useSelector((state) => state.ticketList);
  const setupList = useSelector((state) => state.setupList);

  const { loading, error, tickets } = ticketList;
  const { success, ticketsSetup } = setupList;

  useEffect(() => {
    disptach(listTicketsSetup())

    if(ticketsSetup?.tickets[0]._id === tickets[0]?.rule.ticket_id) {
      setDisabledButton(true)
    }


  },[])

  // const stop_loading = () => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 1500);
  // };

  // useEffect(() => {
  // 	if (data.length >= 0) {
  // 		stop_loading()
  // 	}
  // }, []);

  //Edit and Delete Buttons
  const format_action = (row) => {
    return (
      <div className="action-cell">
        <Button
          variant="contained"
          disableElevation
          color="primary"
          className="edit-btn"
          onClick={() => props.onEdit(row)}
          disabled={!disabledButton}
        >
          Edit
        </Button>
        <Button
          variant="contained"
          disableElevation
          color="secondary"
          className="delete-btn"
          onClick={() => props.onDelete(row)}
          onClick={() => onOpen_delete_alert(row)}
          disabled={disabledButton}
        >
          Delete
        </Button>
      </div>
    );
  };

  const onOpen_delete_alert = (row) => {
    set_delete_alert(true);
    set_delete_details({
      // id: row._id,
      name: row.TicketName,
    });
  };
  const onAccept_delete = () => {
    set_delete_alert(false);
    disptach(deleteTicket(tickets[0].rule._id));
  };

  const onDenied_delete = () => {
    set_delete_alert(false);
    set_delete_details({
      id: "",
      title: "",
    });
  };

  const columns = [
    {
      name: "Pledged Amount",
      selector: (row) => row.pledgedAmount,
    },
    {
      name: "Duration (days)",
      selector: (row) => row.duration,
    },
    {
      name: "Ticket Rewards",
      selector: (row) => row.ticketReward,
    },
    {
      name: "Ticket Name",
      selector: (row) => row.TicketName,
    },
    {
      width: "202px",
      cell: (row) => format_action(row),
      button: true,
    },
  ];

  const data = [
    {
      id: tickets[0] ? tickets[0].rule._id : null,
      pledgedAmount: tickets[0] ? tickets[0].rule.amount : null,
      duration: tickets[0] ? tickets[0].rule.duration : null,
      ticketReward: tickets[0]?tickets[0].rule.num_tickets : null,
      TicketName: ticketsSetup?.tickets[0].name
    },
  ];

  return (
    <>
      <div className="content">
        <div className={classes.midSection}>
          <p> Criteria</p>
          {!props.dataLimit ? (
            <a className={classes.aTag} onClick={() => props.onAdd()}>
              <b>Add Entry</b>
            </a>
          ) : (
            ""
          )}
        </div>
        {/* Table Section */}
        { !loading && success ?
      <DataTable
        columns={columns}
        data={tickets[0] ? data : []}
        sortServer
        progressComponent={<Loader appear={loading} width="100" />}
        persistTableHead
      />
      :
      <Loader appear width={50}/>
      }

        <ConditionalModal
          title={delete_details.name}
          description="Are you sure you want to delete this Ticket?"
          open={delete_alert}
          onClose={onClose_delete_alert}
          onAccept={onAccept_delete}
          onDenied={onDenied_delete}
          acceptBtn_label="Delete"
        />
      </div>
    </>
  );
};

export default Tickets;
