import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TableWrapper from '../../table-elements/TableWrapper'
import PageTitle from '../../page-title/PageTitle';
import { Grid } from '@material-ui/core';
import Pledging from './Pledging'
import { getPledgesConfig } from '../../../actions/PledgesActions'
import SettingsModal from './SettingsModal';
import Loader from '../../loader/Loader';
import { getPledgeMining } from '../../../actions/PledgeMining'

const PledgingPage = () => {
  const dispatch = useDispatch()
  const [ openModal, setOpenModal] = useState(false)
  const [modalData, setModalData] = useState({})
  const [title, setTitle] = useState('')
  const [addModal, setAddModal] = useState(false)

  const getMinings = useSelector( state => state.getMinings)
  const updateMinings = useSelector(state => state.updateMinings)
  const pledgesList = useSelector(state => state.pledgesList)
  const updatePledges = useSelector(state => state.updatePledges)

  const { loading } = getMinings
  const { updateLoading } = updateMinings
  const { getPledgesSuccess, pledges} = pledgesList
  const { updatePledgesSuccess} = updatePledges

  useEffect(() => {
    dispatch(getPledgeMining())
    dispatch(getPledgesConfig())
  },[updateLoading, updatePledgesSuccess])

  
  const deleteModal = (row) => {
    console.log('Delete Button',row)
  }

  const editModal = (row) => {
    setOpenModal(true)
    setModalData(row)
    setTitle('Edit Mining Rate Range')
  }

  const closeModal =() => {
    setOpenModal(false)
  }

  const addPledge = () => {
    setOpenModal(true)
    setTitle('Add New Mining Rate Range')
    setAddModal(!addModal)
  }

  const onModalSubmit = () => {
    setOpenModal(false)
  }

  return (
    <>
      <PageTitle title="Pledging" hideHistoryBtn hideAddBtn />
      <div className="content">
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<TableWrapper
          body={
            loading || !getPledgesSuccess ?
            <Loader appear width={50}/>
            :
            <Pledging
             onDelete={deleteModal}
             onEdit={editModal}
             onAdd={addPledge}
             />
          }
						/>
					</Grid>
				</Grid>
			</div>
      <SettingsModal
      openEditModal={openModal}
      onClose={closeModal}
      modalTitle={title}
      onSubmit={onModalSubmit}
      data={modalData}
      addModal={addModal}
      />
    </>
  )
}

export default PledgingPage
