import React from 'react';
import { Modal, Backdrop, Zoom, IconButton } from '@material-ui/core';
import { DAPPX_API_BASE_URL } from '../../../constant/config';
import CloseIcon from '@material-ui/icons/Close';
import '../Packages.scss'

const ModalPreviewImage = ({
  open,
  onClose,
  data,
}) => {
  let image_src = data 
  ? data.image.length > 0 
    ? `${DAPPX_API_BASE_URL}/${data.image[0].path}` 
    : ''
  : '';

  let title_src = data ? data.package : '';

  const closeModal = () => {
    onClose()
  }

  return (
    <div>
      <Modal
        aria-labelledby={`preview-package--title`}
        aria-describedby={`preview-package--desc`}
        className={`preview-package package-modal custom-scroll`}
        open={open}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Zoom in={open}>
          <div className="paper-wrapper">
            <div className="paper">
              <div className="header">
                <h2 className="title">{title_src}</h2>
                <IconButton onClick={closeModal}><CloseIcon /></IconButton>
              </div>
              <div className="body">
                <img className="img-fluid" src={image_src} alt={title_src} />
              </div>
            </div>
          </div>
        </Zoom>
      </Modal>
    </div>
  );
}

export default ModalPreviewImage;