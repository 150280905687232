import React, {useState, useEffect} from 'react'
import Web3 from "web3";
import TableWrapper from '../../table-elements/TableWrapper'
import PageTitle from '../../page-title/PageTitle';
import { Grid, Paper } from '@material-ui/core';
import AddressesTable from './AddressesTable';
// import TxHistory from './TxHistory';
import HotWalletHistory from './HotWalletHistory';
import Loader from '../../loader/Loader';
import { ConditionalModal } from '../../alerts/Alerts';
import { ReactComponent as MultipleUsers } from '../../../assets/icons/multiple-users.svg';
import { executeConnect } from '../../../api/web3'
import { EXCHANGE_CONTRACT_ADDRESS } from '../../../constant/config'
import axios from 'axios'
import TxHistory from './TxHistory';
import SuccessModal from '../../alerts/Success'
import ErrorModal from '../../alerts/Error'
import './BuyAce.scss'

const PledgingPage = () => {
  let web3 = new Web3(window.ethereum);

  const [hotWalletModal, setHotWalletModal] = useState(false)
  const [title, setTitle] = useState('')
  const [deleteModal, setDeleteModal] = useState(false)
  const [id, setId] = useState('')
  const [aceEthConversion, setAceEthConversion] = useState(0)
  const [totalEthPayment, setTotalEthPayment] = useState(0)
  const [totalAceSold, setTotalAceSold] = useState(0)
  const [deployedContract, setDeployedContract] = useState()
  const [deleteWalletAddress, setDeleteWalletAddress] = useState('')
  const [success, setSuccess] = useState(false)
  const [txError, setTxError] = useState(false)

  // DATA
  const [walletData, setWalletData] = useState({});

  const IS_UAT = window.location.href.includes('-uat');
  const IS_SAT = window.location.href.includes('localhost') || window.location.href.includes('-sat');
  const token = localStorage.getItem('token')
  const DAPPX_ADMIN_API = `${IS_SAT ? "https://dappx-adminapi-sat.dappstore.me/" : (IS_UAT ? "https://dappx-adminapi-uat.dappstore.me/" : "https://dappx-adminapi.dappstore.me/")}`;

  useEffect(() => {
    axios.get(`${DAPPX_ADMIN_API}buy-ace-with-eth/rate`, {headers: { 'auth-token': token}})
    .then((res) => {
      setAceEthConversion(res.data.ace_eth);
    })
    .catch((err) => {
      console.log(err)
    })
  },[])

  useEffect(() => {
    executeConnect()
  },[])

  useEffect(() => {
    axios.get(`${DAPPX_ADMIN_API}buy-ace-with-eth/stats`, {headers: { 'auth-token': token}})
    .then((res) => {
      setTotalEthPayment(res.data.total_eth)
      setTotalAceSold(res.data.total_ace)
    })
    .catch((err) => {
      console.log(err)
    })
  },[])

  useEffect(() => {
    const fetchData = async () => {
      await axios.get('https://dappx-api-sat.dappstore.me/dappstore-contract.json')
      
      .then((responseContract) => {
        setDeployedContract(new web3.eth.Contract(responseContract.data.abi, EXCHANGE_CONTRACT_ADDRESS));
        console.log('REZs', responseContract.data.abi)
        }).catch((error) => {
        console.log('contract error: ', error)
        })
    }
   fetchData()
  },[])

  const confirmSuccess = () => {
    setSuccess(false)
    setDeleteModal(false)
    window.location.reload()
  }

  const confirmError = () => {
    setTxError(false)
    setDeleteModal(false)
  }
  
  const openDeleteModal = (row) => {
    // console.log('WEB@', row)
    setDeleteModal(true)
    setDeleteWalletAddress(row)
    setId(row.id)
  }

  const deleteWallet = async() => {
    executeConnect()

    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });

    try {
      const deleteWalletFunc = await deployedContract?.methods.removeVaultAddress(deleteWalletAddress).send({from: accounts[0]})
      if(deleteWalletFunc) {
        console.log('SUCCESS', deleteWalletFunc)
        setSuccess(true)
      }
    } catch (error) {
      console.log('ERROR', error)
    }
  }

  return (
    <>
      <PageTitle title="Convert - ETH to ACE" hideHistoryBtn hideAddBtn />
      <div className="content buy">
        <div className='widget-container'>
        <Grid container spacing={1}>
					<Grid item lg={11} md={6} sm={12}>
						<Paper elevation={0} className="card-paper">

							<div className="title">
								<span className="icon"><MultipleUsers /></span>
								Conversion (ETH to ACE)
							</div>
							<div className="widget-numbers">
                {aceEthConversion.toFixed(6).replace(/\.?0+$/, '')} ETH/ACE
							</div>

						</Paper>
					</Grid>
				</Grid>

        <Grid container spacing={1}>
					<Grid item lg={11} md={6} sm={12}>
						<Paper elevation={0} className="card-paper">

							<div className="title">
								<span className="icon"><MultipleUsers /></span>
								Total ETH Payment
							</div>
							<div className="widget-numbers">
                {totalEthPayment.toFixed(6).replace(/\.?0+$/, '')} ETH
							</div>

						</Paper>
					</Grid>
				</Grid>

        <Grid container spacing={1}>
					<Grid item lg={11} md={6} sm={12}>
						<Paper elevation={0} className="card-paper">

							<div className="title">
								<span className="icon"><MultipleUsers /></span>
								Total ACE Sold
							</div>
							<div className="widget-numbers">
                {totalAceSold.toFixed(6).replace(/\.?0+$/, '')} ACE
							</div>

						</Paper>
					</Grid>
				</Grid>
        </div>
      
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<TableWrapper
            className={'wallet-address-list'}
          body={
            <AddressesTable
             onDelete={openDeleteModal}
             data={walletData}
             showWalletHistory={() => setHotWalletModal(true)}
             />
          }
						/>
					</Grid>
				</Grid>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<TableWrapper
            className={'wallet-address-list'}
          body={
            <TxHistory
             onDelete={openDeleteModal}
             showWalletHistory={() => setHotWalletModal(true)}
             />
          }
						/>
					</Grid>
				</Grid>
			</div>
      <HotWalletHistory
        modal={hotWalletModal}
        onClose={() => setHotWalletModal(false)}
      />
      <ConditionalModal
        title={title}
        description="Are you sure you want to delete this Hot Wallet?"
        open={deleteModal}
        onAccept={deleteWallet}
        onDenied={() => setDeleteModal(false)}
        acceptBtn_label="Delete"
      />
      <SuccessModal 
      open={success}
      onClose={confirmSuccess}
      />
      <ErrorModal 
      open={txError}
      onClose={confirmError}
      />
    </>
  )
}

export default PledgingPage
