/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import { API_BASE_URL, DAPPX_API_BASE_URL } from "../constant/config";

let headers;

// handle errors
const handleErrors = async (error) => {
  let result = {};
  const data = error && error.response && error.response.data;
  const status = error && error.response && error.response.status;

  result = {
    status: status,
    data: data,
    error: true,
  };
  return result;
};
// handle response
const handleResponse = (res) => {
  let result = {};
  const data = res && res.data;
  const status = res && res.status;

  result = {
    status: status,
    data: data,
    error: false,
  };
  return result;
};
// create axios api
const createApi = (baseUrl, media) => {
  const authToken = localStorage.getItem("token");
  if (authToken) {
    headers = {
      Accept: "*",
      "Content-Type": media ? 'multipart/form-data' : "application/json",
      "auth-token": authToken,
    };
  } else {
    headers = {
      Accept: "*",
      "Content-Type": "application/json",
    };
  }

  const api = axios.create({
    baseURL: baseUrl,
    responseType: "json",
    headers,
  });

  api.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      // const { status } = error.response
      console.log(error, "response");
      return Promise.reject(error);
    }
  );
  return api;
};
// handle requests
const requests = {
  get: (baseUrl, endPoint, data, externalUrl) =>
    createApi(baseUrl)
      .get(`${!externalUrl ? `${baseUrl}${endPoint}` : externalUrl}`, data)
      .then(handleResponse)
      .catch(handleErrors),
  post: (baseUrl, endPoint, data, externalUrl = false) =>
    createApi(baseUrl)
      .post(`${!externalUrl ? `${baseUrl}${endPoint}` : externalUrl}`, data)
      .then(handleResponse)
      .catch(handleErrors),
  put: (baseUrl, endPoint, data, externalUrl, media) =>
    createApi(baseUrl, media)
      .put(`${!externalUrl ? `${baseUrl}${endPoint}` : externalUrl}`, data)
      .then(handleResponse)
      .catch(handleErrors),
  delete: (baseUrl, endPoint) =>
    createApi(baseUrl)
      .delete(`${baseUrl}${endPoint}`)
      .then(handleResponse)
      .catch(handleErrors),
};

const Tickets = {
  getTickets: (data) =>
    requests.get(DAPPX_API_BASE_URL, "/tickets/reward-rules", data),

  getRewardsStatus: (data) =>
    requests.get(DAPPX_API_BASE_URL, "/tickets/reward-status", data),

  updateRewardsStatus: (data, id) =>
    requests.post(DAPPX_API_BASE_URL, `/tickets/reward-status`, data),

  updateTicket: (data, id) =>
    requests.put(DAPPX_API_BASE_URL, `/tickets/reward-rules/${id}`, data),

  deleteTicket: (data) =>
    requests.delete(DAPPX_API_BASE_URL, `/tickets/reward-rules/${data}`),

  createTicket: (data) =>
    requests.post(DAPPX_API_BASE_URL, `/tickets/reward-rules/`, data),
};

const ticketsSetup = {
  getTicketsSetup: (data) => requests.get(DAPPX_API_BASE_URL, "/tickets", data),

  updateTicketSetup: (data,id) =>
    requests.put(DAPPX_API_BASE_URL, `/tickets/${id}`, data, null, true),

  deleteTicketSetup: (data) =>
    requests.delete(DAPPX_API_BASE_URL, `/tickets/${data}`),

  createTicketSetup: (data) =>
    requests.post(DAPPX_API_BASE_URL, `/tickets`, data),
};

const typeStup = {
  getTypeSetup: (data) => requests.get(DAPPX_API_BASE_URL, "/tickets/types", data),

  updateTypeSetup: (data, id) =>
    requests.put(DAPPX_API_BASE_URL, `/tickets/types/${id}`, data),

  deleteTypeSetup: (data) =>
    requests.delete(DAPPX_API_BASE_URL, `/tickets/types/${data}`),

  createTypeSetup: (data) =>
    requests.post(DAPPX_API_BASE_URL, `/tickets/types`, data),
}

const Pledges = {
  getPledgesConfig: (data) => 
    requests.get(DAPPX_API_BASE_URL, "/users/updatePledgingConfig", data),

  postPledgesConfig: (data) =>
    requests.put(DAPPX_API_BASE_URL, `/users/updatePledgingConfig`, data),

}

export default {
  Tickets,
  ticketsSetup,
  typeStup,
  Pledges
};
