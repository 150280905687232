import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TableWrapper from '../../table-elements/TableWrapper'
import PageTitle from '../../page-title/PageTitle';
import { Grid, Paper } from '@material-ui/core';
import GovPledging from './Gov-Pledging'
import SettingsModal from './SettingsModal';
import Loader from '../../loader/Loader';
import { fetchGovernanceMining, deleteGovernanceMiningRate } from '../../../actions/GovernanceMining'
import { getGovenanceSettings } from '../../../actions/GovernanceSettings'
import { ConditionalModal } from '../../alerts/Alerts';
import { ReactComponent as MultipleUsers } from '../../../assets/icons/multiple-users.svg';
import axios from 'axios'
import './Widget.scss'

const PledgingPage = () => {
  const dispatch = useDispatch()
  const [ openModal, setOpenModal] = useState(false)
  const [modalData, setModalData] = useState({})
  const [title, setTitle] = useState('')
  const [addModal, setAddModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [id, setId] = useState('')
  const [totalCore, setTotalCore] = useState(0)
  const [totalNitro, setTotalNitro] = useState(0)
  const [totalEdao, setTotalEdao] = useState(0)
  const [totalUbi, setTotalUbi] = useState(0)


  const getGovernance = useSelector(state => state.getGovernance)
  const updateGovernance = useSelector(state => state.updateGovernance)
  const getGovernanceMining = useSelector(state => state.getGovernanceMining)
  const addGovernanceMining = useSelector(state => state.addGovernanceMining)
  const updateGovernanceMining = useSelector(state => state.updateGovernanceMining)
  const deleteGovernanceMining = useSelector(state => state.deleteGovernanceMining)

  const { loading } = getGovernance
  const { updateLoading } = updateGovernance
  const { getMiningRateSuccess } = getGovernanceMining
  const { addMiningRateSuccess} = addGovernanceMining
  const { updateMiningRateSuccess } = updateGovernanceMining
  const { deleteSuccess } = deleteGovernanceMining

  useEffect(() => {
    dispatch(getGovenanceSettings())
    dispatch(fetchGovernanceMining())
  },[updateLoading, addMiningRateSuccess, updateMiningRateSuccess, deleteSuccess])

  useEffect(() => {
  const IS_UAT = window.location.href.includes('-uat');
  const IS_SAT = window.location.href.includes('localhost') || window.location.href.includes('-sat');
  const token = localStorage.getItem('token')
  const DAPPX_ADMIN_API = `${IS_SAT ? "https://dappx-adminapi-sat.dappstore.me/" : (IS_UAT ? "https://dappx-adminapi-uat.dappstore.me/" : "https://dappx-adminapi.dappstore.me/")}`;

    axios.get(`${DAPPX_ADMIN_API}ace-gov-pledging/stats`, {headers: { 'auth-token': token}})
    .then((res) => {
      setTotalCore(res.data.total_core)
      setTotalNitro(res.data.total_nitro)
      setTotalEdao(res.data.total_edao)
      setTotalUbi(res.data.total_ubi)
    })
    .catch((err) => {
      console.log(err)
    })
  },[])
  
  const openDeleteModal = (row) => {
    setDeleteModal(true)
    setTitle(row.startRange)
    setId(row.id)
  }

  const deletePledge = () => {
    dispatch(deleteGovernanceMiningRate(id))
    setId('')
    setDeleteModal(false)
    setTitle('')
  }

  const editModal = (row) => {
    setOpenModal(true)
    setModalData(row)
    setTitle('Edit Mining Rate Range')
  }

  const closeModal =() => {
    setOpenModal(false)
  }

  const addPledge = () => {
    setOpenModal(true)
    setTitle('Add New Mining Rate Range')
    setAddModal(!addModal)
  }

  const onModalSubmit = () => {
    setOpenModal(false)
  }

  return (
    <>
      <PageTitle title="Ace Governance Pledging" hideHistoryBtn hideAddBtn />
      <div className="content">
        <div className='widget-container'>
        <Grid container spacing={1}>
					<Grid item lg={11} md={6} sm={12}>
						<Paper elevation={0} className="card-paper">

							<div className="title">
								<span className="icon"><MultipleUsers /></span>
								Total Collected Nitro
							</div>
							<div className="widget-numbers">
                {totalNitro.toFixed(6).replace(/\.?0+$/, '')}
							</div>

						</Paper>
					</Grid>
				</Grid>

        <Grid container spacing={1}>
					<Grid item lg={11} md={6} sm={12}>
						<Paper elevation={0} className="card-paper">

							<div className="title">
								<span className="icon"><MultipleUsers /></span>
								Total Collected UBI Protocol
							</div>
							<div className="widget-numbers">
                {totalUbi.toFixed(6).replace(/\.?0+$/, '')}
							</div>

						</Paper>
					</Grid>
				</Grid>

        <Grid container spacing={1}>
					<Grid item lg={11} md={6} sm={12}>
						<Paper elevation={0} className="card-paper">

							<div className="title">
								<span className="icon"><MultipleUsers /></span>
								Total Collected Core Ecosystem Dev
							</div>
							<div className="widget-numbers">
                {totalCore.toFixed(6).replace(/\.?0+$/, '')}
							</div>

						</Paper>
					</Grid>
				</Grid>

        <Grid container spacing={1}>
					<Grid item lg={11} md={6} sm={12}>
						<Paper elevation={0} className="card-paper">

							<div className="title">
								<span className="icon"><MultipleUsers /></span>
								Total Accumulated EDAO
							</div>
							<div className="widget-numbers">
                {totalEdao.toFixed(6).replace(/\.?0+$/, '')}
							</div>

						</Paper>
					</Grid>
				</Grid>
        </div>
      
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<TableWrapper
          body={
            loading || !getMiningRateSuccess ?
            <Loader appear width={50}/>
            :
            <GovPledging
             onDelete={openDeleteModal}
             onEdit={editModal}
             onAdd={addPledge}
             />
          }
						/>
					</Grid>
				</Grid>
			</div>
      <SettingsModal
      openEditModal={openModal}
      onClose={closeModal}
      modalTitle={title}
      onSubmit={onModalSubmit}
      data={modalData}
      addModal={addModal}
      />
      <ConditionalModal
        title={title}
        description="Are you sure you want to delete this Pledge Option?"
        open={deleteModal}
        onAccept={deletePledge}
        onDenied={() => setDeleteModal(false)}
        acceptBtn_label="Delete"
      />
    </>
  )
}

export default PledgingPage
