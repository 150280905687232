import React from 'react';
import { Modal, Backdrop, Zoom, Button } from '@material-ui/core';
import { ReactComponent as SuccessIcon } from '../../assets/icons/alert-success.svg'

import './Success.scss'

const Success = ({
  className,
  title,
  description,
  open,
  onClose,
  btnLabel
}) => {

  return (
    <div>
      <Modal
        aria-labelledby="success-title"
        aria-describedby="success-desc"
        className={`success-modal custom-scroll alert-modals ${className ? className : ''}`}
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        disableBackdropClick
        disableEscapeKeyDown
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Zoom in={open}>
          <div className="paper-wrapper">

            <div className="paper">
              <SuccessIcon className="icon" />
              <h2 id="success-title" className="title">
                {title ? title : 'Successful'}
              </h2>
              {description &&
                <p id="success-desc" className="desc">
                  {description}
                </p>
              }
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={onClose}>
                {btnLabel ? btnLabel : 'Close'}
              </Button>
            </div>

          </div>
        </Zoom>
      </Modal>
    </div>
  );
}

export default Success;