export const getGovernanceMiningReducer = (state = { miningRates: {} }, action) => {
  switch (action.type) {
    case "GOVERNANCE_PLEDGE_MINING_LIST_REQUEST":
      return { loading: true, seasons: {} };
    case "GOVERNANCE_PLEDGE_MINING_SUCCESS":
      return { loading: false, getMiningRateSuccess: true, miningRates: action.payload };
    case "GOVERNANCE_PLEDGE_MINING_FAIL":
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const addGovernanceMiningReducer = (state = {}, action) => {
  switch (action.type) {
    case "GOVERNANCE_PLEDGE_MINING_ADD_REQUEST":
      return { addLoading: true, miningRate: {} };
    case "GOVERNANCE_PLEDGE_MINING_ADD_SUCCESS":
      return { addLoading: false, addMiningRateSuccess: true, miningRates: action.payload };
    case "GOVERNANCE_PLEDGE_MINING_ADD_FAIL":
      return { addLoading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateGovernanceMiningReducer = (state = {}, action) => {
  switch (action.type) {
    case "GOVERNANCE_PLEDGE_MINING_UPDATE_REQUEST":
      return { updateLoading: true, season: {} };
    case "GOVERNANCE_PLEDGE_MINING_UPDATE_SUCCESS":
      return { updateMiningLoading: false, updateMiningRateSuccess: true, miningRate: action.payload };
    case "GOVERNANCE_PLEDGE_MINING_UPDATE_FAIL":
      return { updateLoading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteGovernanceMiningReducer = (state = {}, action) => {
  switch (action.type) {
    case "GOVERNANCE_PLEDGE_MINING_DELETE_REQUEST":
      return { deleteLoading: true };
    case "GOVERNANCE_PLEDGE_MINING_DELETE_SUCCESS":
      return { deleteLoading: false, deleteSuccess: true };
    case "GOVERNANCE_PLEDGE_MINING_DELETE_FAIL":
      return { deleteLoading: false, error: action.payload };
    default:
      return state;
  }
};