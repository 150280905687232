export default (state, action) => {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("expires_at", action.payload.expires_at);
      localStorage.setItem("user", JSON.stringify({
        // localStorage.setItem("full_name", action.payload.data.full_name);
        email: action.payload.data.email,
        role: action.payload.data.role
      }));
      
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
        remember_me: action.payload.remember_me,
        createdAt: action.payload.data.createdAt,
        user: {
          // full_name: action.payload.user.full_name,
          email: action.payload.data.email,
          role: action.payload.data.role
        }
      };
    case "LOGOUT":
      localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
      };
    default: return state;
  }
}