import React, {useState, useEffect} from 'react'
import Web3 from 'web3'
import { Button, makeStyles, Grid, Typography, TextField } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import Loader from '../../loader/Loader';
import AddWalletModal from './AddWalletModal';
import { WITHDRAW_ACE_CONTRACT_ADDRESS, ACENT_TOKEN_CONTRACT_ADDRESS, DAPPX_TOKEN_CONTRACT_ADDRESS} from '../../../constant/config'
import { DAPPX_API_BASE_URL } from '../../../constant/config'
import AceIcon from '../../../assets/images/currency/acent-logo.svg'
import DappxIcon from '../../../assets/images/currency/dappx.png'
import axios from 'axios'

const useStyles = makeStyles({
  root: {
      display: "flex",
      alignItems: "center",
      "& .myIcon": {
        paddingLeft: "8px",
        order: 999
    }
  },
  textFieldContainer: {
    position: 'relative',
    display: 'inline-block'
  },
  textFieldImg: {
    position: 'absolute',
    right: 10,
    top: 17,
    width: 20,
    height: 20
  },
  primaryGrid: {
    margin: '8px',
    display: 'flex',
    alignItems: 'center',
  },
  TextFieldBorder: {
    [`& fieldset`]: {
      borderRadius: 20,
  },
  width: '300px',
  },
  midSection: {
    width: '100%',
    display: 'flex',
    justifyContent:'space-between',
  },
  aTag: {
    textDecoration: 'none',
    color: 'blue',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
  },
  buttonsHolder : {
    marginTop: '50px',
    display: 'flex',
    justifyContent: 'center'
  },
  nestedTextFieldsContainer:{
    marginLeft: '155px',
    marginTop: '10px'
  },
  pStyle: {
    marginTop: '10px',
    fontWeight: 500
  }
});

const AddressesTable = (props) => {
  const classes = useStyles();
  let web3 = new Web3(window.ethereum);
  const token = localStorage.getItem('token')
  const getAddress = localStorage.getItem('wallet_address')
  const removeDoubleQuote = getAddress?.replace(/['"]+/g, '')

const IS_UAT = window.location.href.includes('-uat');
const IS_SAT = window.location.href.includes('localhost') || window.location.href.includes('-sat');

const ACE_CONTRACT_API = `${IS_SAT ? "https://dappx-api-sat.dappstore.me" : (IS_UAT ? "https://dappx-api-uat.dappstore.me" : "https://dappx-api.dappstore.me")}`;

  const [loading, setLoading] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [deployedContract, setDeployedContract] = useState()
  const [tokenContract, setTokenContract] = useState()
  const [adminAddresses, setAdminAddresses] = useState([])
  const [balances, setBalances] = useState([])
  const [showAdminAddress, setShowAdminAddress] = useState('')
  const [threshold, setThreshold] = useState(0)
  const [initThreshold, setInitThreshold] = useState(0)
  const [showButtons, setShowButtons] = useState(false)
  const [adminData, setAdminData]= useState([])
  const [adminWallet, setAdminWallet] = useState('')
  const [wallets, setWallets] = useState([

  ]);
  useEffect(() => {
    let frontChars = Math.ceil(12/2)
    let backChars = Math.floor(8/2)
    const final = adminWallet ? adminWallet.substr(0, frontChars) + '...' + adminWallet.substr(adminWallet.length - backChars) : '';
    setShowAdminAddress(`ETH Hot Wallet (${final ? final : ''})`)
  },[showAdminAddress, props.deployedContract, adminWallet])

  //Edit and Delete Buttons
  const format_action = (row) => {
		return (
			<div className="action-cell">
        {row?.wallet?.toLowerCase() == removeDoubleQuote?.toLowerCase()  ?
        <Button 
        variant="contained" 
        disableElevation 
        color="primary" 
        className="edit-btn" 
        onClick={() => props.onApprove()}
      >
        Approve
      </Button>
      : 
      ''
      }
				<Button 
					variant="contained" 
					disableElevation color="secondary" 
					className="delete-btn" 
          onClick={() => props.onDelete(row)}
          >
						Delete
				</Button>
			</div>
		)
	}

  const showAllowance = () => {
    tokenContract.methods.allowance(removeDoubleQuote , ACENT_TOKEN_CONTRACT_ADDRESS).call()
    .then((res) => {
      console.log(web3.utils.fromWei(res))
    })
    .catch((err) => {
      console.log(err)
    })
  }

  const columns = [
    {
        name: 'Wallet Address',
        selector: row => row.wallet,
    },
    {
        name: props.withdrawAce ? 'ACE Balance' : props.withdrawDappx ? 'DAPPX Balance' : 'ETH Balance',
        selector: row => row.balance,
    },
    {
			width: '202px',
			cell: row => format_action(row),
			button: true
		},
  ];

  const refetch = (newData) => {
    // const IS_UAT = window.location.href.includes('-uat');
    // const IS_SAT = window.location.href.includes('localhost') || window.location.href.includes('-sat');
    // const token = localStorage.getItem('token')
    // const DAPPX_ADMIN_API = `${IS_SAT ? "https://dappx-adminapi-sat.dappstore.me/" : (IS_UAT ? "https://dappx-adminapi-uat.dappstore.me/" : "https://dappx-adminapi.dappstore.me/")}`;

    // axios.get(`${DAPPX_ADMIN_API}buy-ace-with-eth/wallets`, {headers: { 'auth-token': token}})
    // .then((res) => {
    //   console.log(res.data.ace_eth);
    //   setAceEthConversion(res.data.ace_eth);
    // })
    // .catch((err) => {
    //   console.log(err)
    // })

    setWallets([...wallets, ...newData])
  }

  const fetchTokenContract = async () => {
    const apiParams = props.withdrawAce ? 'acentToken.json' : 'dappx.json'
    const contractAddress = props.withdrawAce ? ACENT_TOKEN_CONTRACT_ADDRESS : DAPPX_TOKEN_CONTRACT_ADDRESS
    await axios.get(`${ACE_CONTRACT_API}/${apiParams}`)
    
    .then((responseContract) => {
      setTokenContract( new web3.eth.Contract(responseContract.data.abi, contractAddress))
      setLoading(false)
      })
    .catch((error) => {
      console.log('contract error: ', error)
      })
  }

  const fetchBuyAceContract = async () => {
    await axios.get(`${ACE_CONTRACT_API}/dappstore-contract.json`)
    
    .then((responseContract) => {
      console.log('here now',responseContract)
      setDeployedContract( new web3.eth.Contract(responseContract.data.abi, WITHDRAW_ACE_CONTRACT_ADDRESS))
      setLoading(false)
      })
    .catch((error) => {
      console.log('contract error: ', error)
      })
  }

  useEffect(() => {
    if (props.withdrawAce || props.withdrawDappx) {
      fetchTokenContract()
      fetchBlances()
    } else {
      fetchBuyAceContract()
    }
  },[adminAddresses])

  useEffect(() => {
    setAdminData(wallets)
  },[wallets])

  const fetchBlances = async() => {
    let data = []
    for (let i=0; i < adminAddresses.length; i++) {
      const walletsBalance = await tokenContract.methods.balanceOf(adminAddresses[i]).call()
      data.push(web3.utils.fromWei(walletsBalance))
      setBalances(data)
      setWallets(wallets => ({
        ...wallets,
        balance: data
    }));
    }
  }

  const fetchAddresses = async () => {
    if(props.deployedContract) {
      if(props.withdrawAce || props.withdrawDappx) {
        const addresses = await props.deployedContract.methods.getWallets().call()
        const admin = await props.deployedContract.methods.admin().call()
        setAdminWallet(admin)
        setAdminAddresses(addresses)
          setWallets(wallets => ({
            ...wallets,
            wallet: addresses
         }));
        setLoading(false)
      } else {
        const addresses = await deployedContract.methods.getVaults().call()
        setAdminAddresses(addresses)
        setLoading(false)
      }
    }
  }

  const fetchThreshold = async () => {
    const apiParams = props.withdrawAce ? '/ace-withdrawal' : '/dappx-withdrawal'
    await axios.get(`${DAPPX_API_BASE_URL + apiParams}/settings/fund-threshold`, {headers: { 'auth-token': token}})
      
    .then((res) => {
      setThreshold(res.data.fund_threshold)
      setInitThreshold(res.data.fund_threshold)
      })
    .catch((error) => {
      console.log('contract error: ', error)
      })
  }

  useEffect(() => {
    fetchAddresses()
    fetchThreshold()
  },[props.deployedContract, deployedContract, props.refresh])

  const thresholdChange = (e) => {
    setThreshold(e.target.value) 
    setShowButtons(true)
  }

  const cancelThreshold = () => {
    setShowButtons(false)
    setThreshold(initThreshold)
  }

  const submitThreshold = async (e) => {
    const apiParams = props.withdrawAce ? '/ace-withdrawal' : '/dappx-withdrawal'
    e.preventDefault()
    await axios.post(`${DAPPX_API_BASE_URL + apiParams}/settings/fund-threshold`, {fund_threshold: threshold}, {headers: { 'auth-token': token}})
      
    .then((res) => {
      setShowButtons(false)
      setThreshold(threshold) 
      })
    .catch((error) => {
      console.log('contract error: ', error)
      })
  }

  const approveWallet = async (row) => {
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    tokenContract.methods.approve(WITHDRAW_ACE_CONTRACT_ADDRESS, web3.utils.toWei(String(threshold))).send({from: accounts[0]})
    .then((res) => {
      console.log(res)
      props.onSuccess()
    })
    .catch((err) => {
      console.log('ERROR', err)
      props.onError()
    })
  }

  let data =[]

  adminAddresses.map((item, i) => {
    data.push({wallet: item, balance: balances[i]})
  })

  return (
    <>
    <div className="content">
    <form>
      <div className={classes.midSection}>
        <p onClick={fetchAddresses}> {showAdminAddress}</p>
        <a className={classes.aTag} onClick={() => props.showWalletHistory()}><b>Hot Wallet History</b></a>
      </div>

      {/* Table Section */}
      <DataTable
				columns={columns}
				// data={adminAddresses}
				data={data}
				sortServer
				progressPending={loading}
				progressComponent={
					<Loader
						appear={loading}
						width="100"
					/>
				}
				persistTableHead
			/>
      <div className="footer">
        <Button
          disableElevation
          color="primary"
          variant="contained"
          className="submit-btn"
          style={{marginTop: '20px', marginBottom: '20px'}}
          onClick={() => setAddModal(true)}
          >
          <span className="text">Add Wallet</span>
        </Button>
      </div>
      {props.withdrawAce || props.withdrawDappx ?
      <div>
      <Grid container spacing={12} className={classes.primaryGrid}>
        <Grid item xs={3} lg={2}>
          <Typography><b>Balance Threshold</b></Typography>
        </Grid>
          <div className={classes.textFieldContainer}>
            <img src={props.withdrawAce ? AceIcon :DappxIcon} alt="" className={classes.textFieldImg}/>
            <TextField
              variant="outlined"
              className={classes.TextFieldBorder}
              value={threshold}
              // placeholder="0.0"
              onChange={(e) => thresholdChange(e)}
            />
          </div>
          {
            showButtons ?
            <div className="footer">
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className="submit-btn"
                type="submit"
                onClick={(e) => submitThreshold(e)}
                >
                <span className="text">Save</span>
              </Button>
              <Button
                disableElevation
                variant="contained"
                className="exit-btn"
                onClick={cancelThreshold}
                >
                Cancel
              </Button>
            </div>
            : ''
          }
        </Grid>
    </div>
    : ''}
      </form>

      <AddWalletModal
        modal={addModal}
        deployedContract={props.deployedContract}
        withdrawAce={props.withdrawAce}
        withdrawDappx={props.withdrawDappx}
        refetch={fetchAddresses}
        onClose={() => setAddModal(false)}
        />
    </div>
    </>
  )
}

export default AddressesTable
