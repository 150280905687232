import React, { useState, useEffect, useContext } from "react";
import { v4 as uuidv4 } from 'uuid';
import { AppBar, Toolbar, Button, IconButton, Drawer, Menu, MenuItem, Link, Box } from "@material-ui/core";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { AuthContext } from '../../context/AuthContext';
import MenuIcon from "@material-ui/icons/Menu";
import { ReactComponent as Logo } from "../../assets/images/dappstore-logo--white.svg";
import { ReactComponent as User } from "../../assets/icons/user.svg";
import "./Topnav.scss"

const Topnav = () => {
  let history = useHistory();
  const { dispatch } = useContext(AuthContext)

  let pathname = history.location.pathname ? history.location.pathname : '/404';
	let first_value = pathname.split('/')[1];

  let user_details = JSON.parse(localStorage.getItem('user'));
  let user_email = user_details.email;

  const menuData = [
    // {
    //   id: uuidv4(),
    //   label: "Banners",
    //   href: "/banners",
    // },
    // {
    //   id: uuidv4(),
    //   label: "dApps",
    //   href: "/dApps",
    //   disabled: true
    // },
    {
      id: uuidv4(),
      label: "Users",
      href: "/users"
    },
    {
      id: uuidv4(),
      label: "Packages",
      href: "/packages"
    },
    {
      id: uuidv4(),
      label: 'Settings',
      href: '/settings'
    },
    // {
    //   id: uuidv4(),
    //   label: "NFTs",
    //   href: "/nfts",
    //   disabled: true
    // },
    // {
    //   id: uuidv4(),
    //   label: "Media",
    //   href: "/media",
    //   disabled: true
    // },
    // {
    //   id: uuidv4(),
    //   label: "Settings",
    //   href: "/settings",
    //   disabled: true
    // },
    // {
    //   id: uuidv4(),
    //   label: "Logout",
    //   href: "/login",
    //   pc_hidden: true,
    // },
  ];

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 960
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const getDrawerChoices = () => {
    return menuData.map(({ id, label, href, disabled }) => {
      let label_first_value = href.split('/')[1];
      return (
        <Link
          {...{
            component: RouterLink,
            to: href,
            key: id,
            className: `menuButton-mobile ${first_value === label_first_value ? 'active' : ''}`,
            disabled: disabled
          }}
          underline="none"
        >
          {label}
        </Link>
      );
    });
  };

  const getMenuButtons = () => {
    return menuData.map(({ id, label, href, disabled, pc_hidden }) => {
      let label_first_value = href.split('/')[1];
      return (
        <Button
          {...{
            key: id,
            to: href,
            component: RouterLink,
            className: `menuButton ${first_value === label_first_value ? 'active' : ''} ${pc_hidden && !mobileView ? 'd-none' : ''}`,
          }}
          disabled={disabled}
        >
          {label}
        </Button>
      );
    });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const onOpenUser = (event) => setAnchorEl(event.currentTarget);
  const onCloseUser = () => setAnchorEl(null);

  const onLogout = () => {
    dispatch({
			type: "LOGOUT"
		});
    onCloseUser();
    history.push('/login');
  }

  const displayDesktop = () => {
    return (
      <Toolbar>
        <RouterLink to="/users/user-overview" className="logo"><Logo /></RouterLink>
        <div className="left">{getMenuButtons()}</div>
        <div className="right">
          <Box className="user-dropdown">
            <span className="user-email">{user_email}</span>
            <IconButton 
              aria-controls="user-menu" 
              aria-haspopup="true" 
              onClick={onOpenUser} 
              className="user-dropdown-btn">
              <User className="user-icon" />
            </IconButton>
            <Menu
              className="user-menu"
              id="user-menu"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={onCloseUser}
            >
              <MenuItem onClick={onLogout}>Logout</MenuItem>
            </Menu>
          </Box>
        </div>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () => setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () => setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar>
        <RouterLink to="/users/user-overview" className="logo"><Logo /></RouterLink>

        <IconButton
          {...{
            edge: "end",
            anchor: "right",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
          className="hamburger"
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
          className="menu-drawer"
        >
          <div className="drawerContainer">{getDrawerChoices()}</div>
        </Drawer>

      </Toolbar>
    );
  };

  return (
    <AppBar className="top-nav">
      {mobileView ? displayMobile() : displayDesktop()}
    </AppBar>
  );
}

export default Topnav;  