import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  FormControl,
  TextField,
  makeStyles,
  MenuItem
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import "../../Settings.scss";
import { updateTicket, createTicket } from "../../../../actions/TicketsActions";
import { listTicketsSetup } from "../../../../actions/TicketsSeupActions"

const useStyles = makeStyles({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "400px",
    minHeight: "400px",
    bgcolor: "background.paper",
    border: "1px solid grey",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    backgroundColor: "white",
  },
  textFieldContainer: {
    position: "relative",
    display: "inline-block",
  },
  TextFieldBorder: {
    [`& fieldset`]: {
      borderRadius: 20,
      width: 690,
      height: 40,
      paddingBottom: 7,
      marginTop: "7px",
      display: "flex",
      alignItems: "flex-start",
    },
    width: "300px",
  },
  dropDownStyle : {
    [`& fieldset`]: {
      borderRadius: 20,
      width: 640,
      height: 40,
      paddingBottom: 7,
      paddingRight: '60px',
      marginTop: '7px',
      display: 'flex',
      alignItems: 'flex-start',
    },
  },
  optionText: {
    borderRadius: '20px',
    height:20,
    backgroundColor: 'white',
    width: '650px',
    display: 'flex',
    justifyContent: 'space-between'
  }
});

const TicketsModal = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [pledgedAmount, setPledgedAmount] = useState("");
  const [duration, setDuration] = useState("");
  const [ticketReward, setTicketReward] = useState("");
  const [ticketName, setTicketName] = useState('Common')

  const ticketUpdate = useSelector((state) => state.ticketUpdate);
  const setupList = useSelector((state) => state.setupList);
  
  const { loading, error } = ticketUpdate;
  const { ticketsSetup, success } = setupList;

  const ticketTypes = [
    {
      id: 1,
      type: ticketsSetup ? ticketsSetup.tickets[0].name : ''
    },
  ]

  useEffect(() => {
    dispatch(listTicketsSetup())
    if(success) {
      setTicketName(ticketsSetup.tickets[0].name)
    }
  },[])

  const handlePledgeChange = (e) => {
    setPledgedAmount(e.target.value);
  };

  const handleDurationChange = (e) => {
    setDuration(e.target.value);
  };

  const handleRewardChange = (e) => {
    setTicketReward(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append('amount', pledgedAmount)
    formData.append('duration', duration)
    formData.append('num_tickets', ticketReward)
    formData.append('ticket_id', ticketsSetup.tickets[0]._id)

    let id = props.ticketData.id;

    if(props.addModal) {
      dispatch(createTicket(formData))
    } else {
      dispatch(
        updateTicket(formData, id)
      );
    }

    props.onSubmit();
  };

  return (
    <>
      <Modal open={props.openEditModal}>
        <div className={classes.root}>
          <div className="paper-wrapper">
            <div className="paper">
              <div className="header">
                <h2 className="title">{props.modalTitle}</h2>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="textfield-item-container">
                  <FormControl className="edit-form-control">
                    <label className="form-label" htmlFor="package">
                      Pledged Amount
                    </label>

                    <div className={classes.textFieldContainer}>
                      <TextField
                        variant="outlined"
                        className={classes.TextFieldBorder}
                        value={pledgedAmount}
                        onChange={(e) => handlePledgeChange(e)}
                      />
                    </div>
                  </FormControl>

                  <FormControl className="edit-form-control">
                    <label className="form-label" htmlFor="package">
                      Duration (days)
                    </label>
                    <div className={classes.textFieldContainer}>
                      <TextField
                        variant="outlined"
                        className={classes.TextFieldBorder}
                        value={duration}
                        onChange={(e) => handleDurationChange(e)}
                      />
                    </div>
                  </FormControl>
                  <FormControl className="edit-form-control">
                    <label className="form-label" htmlFor="package">
                      Ticket Rewards
                    </label>
                    <div className={classes.textFieldContainer}>
                      <TextField
                        variant="outlined"
                        className={classes.TextFieldBorder}
                        value={ticketReward}
                        onChange={(e) => handleRewardChange(e)}
                      />
                    </div>
                  </FormControl>
                  <FormControl className="edit-form-control">
                  <label className="form-label" htmlFor="package">Ticket Type</label>
                  <div className={classes.textFieldContainer}>
                  <TextField 
                  fullWidth
                  select
                  variant="outlined"
                  className={classes.dropDownStyle}
                  value={ticketName}
                  onClick={(e) => setTicketName(e.target.value)}
                  InputProps={{
                    classes: {
                      input: classes.optionText,
                    },
                  }}
                  >
                    {ticketTypes.map((item) => (
                        <MenuItem
                          key={item.id}
                          id={item.id}
                          value={item.type}
                        >
                          {item.type}
                        </MenuItem>
                      ))}
                  </TextField>
                  </div>
                </FormControl>
                </div>
                <div className="footer">
                  <Button
                    disableElevation
                    color="primary"
                    variant="contained"
                    className="submit-btn"
                    type="submit"
                    // onClick={props.onSubmit}
                    // disabled={loading ? true : false}
                  >
                    <span className="text">Save</span>
                    {/* <Loader
                    appear={loading}
                    timeout={1000}
                    thick
                    width="22"
                    coloredBg
                    svgProps={{
                      absolute: true,
                      direction: "right",
                      negative: true,
                    }}
                  /> */}
                  </Button>
                  <Button
                    disableElevation
                    variant="contained"
                    className="exit-btn"
                    onClick={props.onClose}
                    // disabled={loading ? true : false}
                  >
                    Exit
                  </Button>

                  {/* {err_message &&
                  <Fade className="error-message" in={err_message ? true : false} timeout={500} >
                    <Alert severity="error" variant="outlined">{err_message}</Alert>
                  </Fade>
                } */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TicketsModal;
