import React, {useState, useRef, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, TextField, FormControl, Select, MenuItem, makeStyles} from '@material-ui/core';
import { Editor } from "react-draft-wysiwyg";
import { updateTicketSetup, createTicketSetup } from "../../../../actions/TicketsSeupActions";
import { listTypes } from '../../../../actions/TypeActions'
import DeleteIcon from '@material-ui/icons/Delete';
import Loader from '../../../loader/Loader';
import './Setup.scss'

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '400px',
    minHeight: '400px',
    bgcolor: 'background.paper',
    border: '1px solid grey',
    borderRadius: '20px',
    boxShadow: 24,
    p: 4,
    backgroundColor: 'white'
  },
  textFieldContainer: {
    position: 'relative',
    display: 'inline-block'
  },
  TextFieldBorder: {
    [`& fieldset`]: {
      borderRadius: 20,
      width: 690,
      height: 40,
      paddingBottom: 7,
      marginTop: '7px',
      display: 'flex',
      alignItems: 'flex-start',
    },
  width: '300px',
  },
  dropDownStyle : {
    [`& fieldset`]: {
      borderRadius: 20,
      width: 640,
      height: 40,
      paddingBottom: 7,
      paddingRight: '60px',
      marginTop: '7px',
      display: 'flex',
      alignItems: 'flex-start',
    },
  },
  optionText: {
    borderRadius: '20px',
    height:20,
    backgroundColor: 'white',
    width: '650px',
    display: 'flex',
    justifyContent: 'space-between'
  }
});

const SetupModal= (props) =>{
  const dispatch = useDispatch()
  const classes = useStyles()

  const [message, setMessage] = useState('')
  const [warning, setWarning] = useState(false)
  const [fileLink, setFileLink] = useState(null)
  const [ticketName, setTicketName] = useState('')
  const [ticketTypes, setTicketTypes] = useState([
    {
      id: '',
      type: ''
    }
  ])
  const [ticketTypeData, setTicketTypeData] = useState('Common')
  const [description, setDesctiption] = useState('')
  const [ imageVal, setImageVal] = useState([])
  const inputFile = useRef(null)

  const setupUpdate = useSelector((state) => state.setupUpdate);
  const ticketType = useSelector( state => state.ticketType)

  const { typeSuccess, types } = ticketType
  const { loading } = setupUpdate;

  useEffect(() => {
    dispatch(listTypes())

    if(typeSuccess) {
      setTicketTypes([{
        id: types[0]._id,
        type: types[0].name
      }])
    }
  },[])

  const supportedFileTypes = ['jpeg', 'png']
  // const ticketTypes = [
  //   {
  //     id: 1,
  //     type: 'Common'
  //   },
  //   {
  //     id: 2,
  //     type: 'Rare'
  //   },
  //   {
  //     id: 3,
  //     type: 'Legendary'
  //   }
  // ]

  const onButtonClick = () => {
    inputFile.current.click();
   };

   const getFileUrl = (e) => {
      const fileDetails = e.target.files;
      const fileType = fileDetails[0].type
      const fileExtension = fileType.split('/')
      const fileSize = fileDetails[0].size
      const url = URL.createObjectURL(fileDetails[0])
      const result = supportedFileTypes.filter(type => type ===fileExtension[1])

       if (result.length < 1) {
         setMessage('Invalid file format. Please Try again.')
         setWarning(true)
       } else if (fileSize > 67108864) {
        setMessage('File size must not exceed 64mb')
        setWarning(true)
       } else {
         uploadFile(url)
         setWarning(false)
         setMessage('')
         setImageVal(fileDetails)
        }
   }

   const uploadFile = (url) => {
    setFileLink(url)
   }

   const handleNameChange = (e) => {
     setTicketName(e.target.value)
   }

   const handleDescriptionChange = (e) => {
    setDesctiption(e.blocks[0].text)
   }

   const removeFile = (e) => {
    e.preventDefault()
     setFileLink(null)
   }

   const handleSubmit = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append('name', ticketName)
    formData.append('description', description)
    formData.append('type', ticketTypeData)
    formData.append('image', imageVal[0])

    let id = props.ticketData.id;

    if(props.addModal) {
      dispatch(createTicketSetup(formData))
    } else {
      dispatch(
        updateTicketSetup(formData, id)
      );
    }

    props.onSubmit();
  };

  return (
    <>
    <Modal
      open={props.openEditModal}
    >
        <div className={classes.root}>
        <div className="paper-wrapper">
          <div className="paper">

            <div className="header">
              <h2 className="title">{props.modalTitle}</h2>
            </div>
            <form onSubmit={handleSubmit}>
            <div className="textfield-item-container">
                <FormControl className="edit-form-control">
                  <label className="form-label" htmlFor="package">Ticket Name</label>
                  <div className={classes.textFieldContainer}>
                    <TextField variant="outlined" className={classes.TextFieldBorder} value={ticketName} onChange={(e) => handleNameChange(e)}/>
                  </div>
                </FormControl>
                <FormControl className="edit-form-control">
                  <label className="form-label" htmlFor="package">Ticket Type</label>
                  <div className={classes.textFieldContainer}>
                  <TextField 
                  fullWidth
                  select
                  variant="outlined"
                  className={classes.dropDownStyle}
                  value={ticketTypeData}
                  onClick={(e) => setTicketTypeData(e.target.value)}
                  InputProps={{
                    classes: {
                      input: classes.optionText,
                    },
                  }}
                  >
                    {ticketTypes.map((item) => (
                        <MenuItem
                          key={item.id}
                          id={item.id}
                          value={item}
                          // disabled={item.type === 'Common' ? false : true}
                        >
                          {item.type}
                        </MenuItem>
                      ))}
                  </TextField>
                  </div>
                </FormControl>
                <FormControl className="edit-form-control">
                  <label className="form-label" htmlFor="package">Description</label>
                <div style={{width: '696px', height: '181px', marginTop: '10px'}}>
                <Editor
                    style={{border: '1px solid grey', overflow :'scroll'}}
                    wrapperClassName={`editor-wrapper`}
                    editorClassName="editor"
                    // editorState={editorState}
                    // onEditorStateChange={onEditorStateChange}
                    placeholder="Up to 50 characters"
                    onChange={(e) => handleDescriptionChange(e)}
                    toolbar={{
                      options: ['inline', 'list'],
                      inline: {
                        options: ['bold', 'italic', 'underline'],
                      },
                      list: {
                        options: ['unordered', 'ordered'],
                      }
                    }}
                  />
                </div>
                </FormControl>
                <label className="upload-style__label">Upload File</label>
                <div className={warning ? "upload-style-error" : "upload-style"}>
                {fileLink ?
                <div className="after-upload-style">
                 <img src={fileLink} className="uploaded-image-style"/>
                 {/* <button onClick={(e) => removeFile(e)}>Delete</button> */}
                 <DeleteIcon color="secondary" onClick={(e) => removeFile(e)} className="delete-icon-style"/>
                </div>
                 : (
                  <>   
                    <p className="upload-label">JPEG, PNG or GIF. Max 64mb</p>  
                    <input type='file' id='file' ref={inputFile} style={{display: 'none'}} onChange={(e) => getFileUrl(e)}/>
                    <button for="upload" type="button" className="upload-button" onClick={onButtonClick}>Select File</button>
                  </>
                  )}
                </div>
            </div>
            <div className="footer">
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className="submit-btn"
                type="submit"
                disabled={loading ? true : false}
                >
                <span className="text">Save</span>
                <Loader
                  appear={loading}
                  timeout={1000}
                  thick
                  width="22"
                  coloredBg
                  svgProps={{
                    absolute: true,
                    direction: "right",
                    negative: true,
                  }}
                />
              </Button>
              <Button
                disableElevation
                variant="contained"
                className="exit-btn"
                onClick={props.onClose}
                disabled={loading ? true : false}
                >
                Exit
              </Button>

              {/* {err_message &&
                <Fade className="error-message" in={err_message ? true : false} timeout={500} >
                  <Alert severity="error" variant="outlined">{err_message}</Alert>
                </Fade>
              } */}
            </div>
              </form>
          </div>
        </div>
        </div>
    </Modal>
  </>
  );
}

export default SetupModal