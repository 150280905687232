import React, {useEffect, useState} from 'react'
import TableWrapper from '../../table-elements/TableWrapper'
import PageTitle from '../../page-title/PageTitle';
import { Grid } from '@material-ui/core';
import DappxTable from './DappxTable'
import SettingsModal from './SettingsModal';
import Loader from '../../loader/Loader';
import { ConditionalModal } from '../../alerts/Alerts';
import ModalPreviewImage from '../../../domain/packages/package-settings/ModalPreviewImage';
import axios from 'axios'

const DappxPaypal = () => {
  const [ openModal, setOpenModal] = useState(false)
  const [modalData, setModalData] = useState({})
  const [id, setId] = useState('')
  const [title, setTitle] = useState('')
  const [deleteTitle, setDeleteTitle] = useState('')
  const [addModal, setAddModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [payPalPackages, setPaypalPackages] = useState([])
  const [loader, setLoader] = useState(true)
  const [preview_data, set_preview_data] = useState(null);
	const [open_preview, set_open_preview] = useState(false);


  const IS_UAT = window.location.href.includes('-uat');
  const IS_SAT = window.location.href.includes('localhost') || window.location.href.includes('-sat');

  const BASEURL = `${IS_SAT ? "https://dappx-adminapi-sat.dappstore.me" : (IS_UAT ? "https://dappx-adminapi-uat.dappstore.me" : "https://dappx-adminapi.dappstore.me")}`;
  const adminToken = localStorage.getItem("token");

  const fetchPackages = () => {
    axios.get(`${BASEURL}/token-packages`,{ headers: {
      'auth-token': adminToken,
    }})
    .then((res) => {
     setPaypalPackages(res.data.token_packages)
     setLoader(false)
     console.log('PAYPAL',res.data.token_packages)
    })
    .catch((err) => {
      console.log('ERROR',err)
    })
  }

  useEffect(() => {
    fetchPackages()
  },[])

  const openDeleteModal = (row) => {
    setDeleteModal(true)
    setId(row.id)
    setDeleteTitle(row.token_count)
  }

  const onAcceptDelete = async () => {
    await axios.delete(`${BASEURL}/token-packages/${id}`,{ headers: {
      'auth-token': adminToken,
    }})
    .then((res) => {
      setDeleteModal(false)
      fetchPackages()
    })
    .catch((err) => {
      console.log('ERROR',err)
    })
  }

  const editModal = (row) => {
    setOpenModal(true)
    setModalData(row)
    setTitle('Edit Product')
  }

  const closeModal =() => {
    setOpenModal(false)
    setDeleteModal(false)
    setId('')
    setTitle('')
    setDeleteTitle('')
  }

  const addPackage = () => {
    setOpenModal(true)
    setTitle('Add New Product')
    setAddModal(!addModal)
  }

  const onModalSubmit = () => {
    fetchPackages()
    setOpenModal(false)
  }

  const on_open_preview = (row) => {
		set_open_preview(true)
		set_preview_data(row)
	}
	const on_close_preview = () => {
		set_open_preview(false);
		setTimeout(() => {
			set_preview_data(null)
		}, 400)
	}

  return (
    <>
      <PageTitle title="DAPPX" hideHistoryBtn hideAddBtn />
      <div className="content">
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<TableWrapper
          body={
            <DappxTable
             onDelete={openDeleteModal}
             onEdit={editModal}
             onPreview={on_open_preview}
             onAdd={addPackage}
             payPalPackages={payPalPackages}
             loader={loader}
             />
          }
						/>
					</Grid>
				</Grid>
			</div>

      <SettingsModal
        openEditModal={openModal}
        onClose={closeModal}
        modalTitle={title}
        onSubmit={onModalSubmit}
        data={modalData}
        addModal={addModal}
        fetchPackages={fetchPackages}
      />
      <ConditionalModal
        title={deleteTitle}
        description="Are you sure you want to delete this Product?"
        open={deleteModal}
        onClose={closeModal}
        onAccept={onAcceptDelete}
        onDenied={closeModal}
        acceptBtn_label="Delete"
      />
      <ModalPreviewImage
				open={open_preview}
				onClose={on_close_preview}
				data={preview_data}
			/>
    </>
  )
}

export default DappxPaypal
