import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import Sidebar from '../../components/sidebar/Sidebar';
import PackageSettings from './package-settings/PackageSettings';
import './Packages.scss'

const Packages = ({ history, match }) => {
	const menu = [
		{
			id: 'package-management',
			label: 'Package Management',
			icon: '',
			nested: [{
				id: `${match.url}--package-settings`,
				label: 'Package Settings',
				href: `${match.url}/package-settings`,
				icon: '',
			}]
		},
	]

	useEffect(() => {
		history.push('/packages/package-settings');
	}, []);

	return (
		<div className="page-wrapper packages-page">
			<Sidebar menu={menu} initialOpen={'package-management'} />
			<div className="page-content custom-scroll">
				<Switch>
					<Route exact path={`${match.path}/package-settings`} component={PackageSettings} />
				</Switch>
			</div>
		</div>
	);
}

export default Packages;